import React, { useEffect, useState } from "react";
import { getAllAdherent } from "../../../../services/adherentServices"; // Service pour récupérer les adhérents
import { getGroupsHome } from "../../../../services/groupServices"; // Service pour récupérer les groupes
import { toast } from "react-toastify";
import judokaAvatar from "./judoka_avatar.png";
import judokateAvatar from "./judokate_avatar.png";

// Fonction pour obtenir la classe de couleur en fonction du groupe
const getGroupColor = (groupColors, groupe) => {
  const color_groupe = groupColors[groupe] || "bg-gray-400"; // Classe par défaut si le groupe n'a pas de couleur définie
  return color_groupe;
};

const RecentAdherents = () => {
  const [adherents, setAdherents] = useState([]);
  const [groupColors, setGroupColors] = useState({}); // Stocker les couleurs des groupes

  // Récupérer les groupes avec leurs couleurs
  useEffect(() => {
    const fetchGroups = async () => {
      try {
        const groupsData = await getGroupsHome(); // Récupérer les groupes
        const groupColorMapping = {};
        groupsData.forEach((group) => {
          groupColorMapping[group.nom] = group.color_groupe;
        });
        setGroupColors(groupColorMapping);
      } catch (error) {
        console.error("Erreur lors de la récupération des groupes :", error);
        toast.error("Erreur lors de la récupération des groupes.");
      }
    };

    fetchGroups();
  }, []);

  // Récupérer les 10 derniers adhérents
  useEffect(() => {
    const fetchRecentAdherents = async () => {
      try {
        const data = await getAllAdherent();
        setAdherents(data.slice(0, 8)); // Afficher les 8 derniers adhérents
      } catch (error) {
        console.error("Erreur lors de la récupération des adhérents :", error);
        toast.error("Erreur lors de la récupération des adhérents.");
      }
    };

    fetchRecentAdherents();
  }, []);

  return (
    <div className="bg-gray-100 dark:bg-gray-800 p-4 rounded-md shadow-md dark:shadow-xl">
      <h3 className="text-xl font-semibold mb-4 text-gray-900 dark:text-white">Derniers Adhérents Inscrits</h3>
      <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
        {adherents.map((adherent) => (
          <div
            key={adherent.id}
            className="relative bg-white dark:bg-gray-700 p-4 rounded-lg shadow-md dark:shadow-lg flex flex-col items-center text-center"
          >
            {/* Avatar */}
            <div className="relative w-24 h-24 mb-4">
              <img
                src={adherent.genre === "M" ? judokaAvatar : judokateAvatar}
                alt="Avatar"
                className="w-full h-full rounded-full object-cover"
              />
              {/* Tag pour le groupe */}
              <span
                className={`absolute -top-2 -right-2 px-2 py-1 rounded-full text-xs text-white ${getGroupColor(
                  groupColors,
                  adherent.adherent_groupe
                )}`}
              >
                {adherent.adherent_groupe}
              </span>
            </div>
            {/* Nom et Prénom */}
            <p className="font-semibold text-sm text-gray-900 dark:text-white">
              {adherent.nom} {adherent.prenom}
            </p>
            {/* Date d'inscription */}
            <p className="text-xs text-gray-500 dark:text-gray-300 mt-1">
              Inscrit le {new Date(adherent.createdAt).toLocaleDateString("fr-FR")}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default RecentAdherents;
