import React from "react";
import { FaTimes } from "react-icons/fa";
import Button from "../../../../components/Button/Button"; // Bouton personnalisé

const InscriptionDetailsModal = ({ adherent, onClose }) => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-30 flex justify-center items-center z-50">
      <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg w-full max-w-2xl max-h-[90vh] overflow-y-auto">
        {/* Titre et bouton de fermeture */}
        <div className="bg-primary text-white p-4 rounded-t-lg flex justify-between items-center">
          <h2 className="text-xl font-semibold">Détails de l'Adhérent</h2>
          <button
            onClick={onClose}
            className="text-white hover:text-gray-300 focus:outline-none"
          >
            <FaTimes size={24} />
          </button>
        </div>

        {/* Contenu de la modale */}
        <div className="p-6">
          <div className="grid grid-cols-2 gap-4">
            <div><strong>Nom :</strong> {adherent.nom}</div>
            <div><strong>Prénom :</strong> {adherent.prenom}</div>
            <div><strong>Groupe :</strong> {adherent.adherent_groupe}</div>
            <div><strong>Email :</strong> {adherent.email}</div>
            <div><strong>Email secondaire :</strong> {adherent.email_secondaire}</div>
            <div><strong>Téléphone :</strong> {adherent.tel}</div>
            <div><strong>Téléphone secondaire :</strong> {adherent.tel_secondaire}</div>
            <div><strong>Adresse :</strong> {adherent.adresse}</div>
            <div><strong>Code Postal :</strong> {adherent.cp}</div>
            <div><strong>Commune :</strong> {adherent.commune}</div>
            <div><strong>Sexe :</strong> {adherent.genre}</div>
            <div><strong>Date de Naissance :</strong> {new Date(adherent.date_naissance).toLocaleDateString()}</div>
            <div><strong>Poids :</strong> {adherent.poids} kg</div>
            <div><strong>N° de Licence :</strong> {adherent.licence_number || "N/A"}</div>
            <div><strong>Autorisation Photo :</strong> {adherent.autorisation_photo ? "Oui" : "Non"}</div>
            <div><strong>Notes :</strong> {adherent.notes_adherent || "Aucune"}</div>
            <div><strong>Créé le :</strong> {new Date(adherent.createdAt).toLocaleDateString()}</div>
          </div>

          {/* Bouton de fermeture */}
          <div className="mt-6 text-center">
            <Button
              text="Fermer"
              onClick={onClose}
              className="w-full bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default InscriptionDetailsModal;
