import React, { useEffect, useState } from "react";
import {
  getAllUsers,
  createUser,
  updateUser,
  deleteUser,
} from "../../../../services/userServices"; // Import des services pour gérer la team
import Button from "../../../../components/Button/Button"; // Composant pour créer un nouveau membre
import ButtonEdit from "../../../../components/Button/ButtonEdit"; // Composant pour éditer
import ButtonDelete from "../../../../components/Button/ButtonDelete"; // Composant pour supprimer
import CreateUserModal from "./CreateUserModal"; // Modale de création
import EditUserModal from "./EditUserModal"; // Modale d'édition
import { toast } from "react-toastify";
import { FaPlus } from "react-icons/fa";

const UsersManager = () => {
  const [users, setUsers] = useState([]);
  const [isCreateModalOpen, setCreateModalOpen] = useState(false);
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [isDemoMode, setIsDemoMode] = useState(false); // État pour vérifier si l'utilisateur est en mode démo

  // Fonction pour vérifier le role_id dans localStorage
  const checkUserRole = () => {
    const user = JSON.parse(localStorage.getItem("user")); // Récupérer l'utilisateur depuis localStorage
    if (user && user.role_id === 4) {
      setIsDemoMode(true); // Activer le mode démo si role_id est 4
    }
  };

  useEffect(() => {
    fetchUsers();
    checkUserRole(); // Vérifier le rôle de l'utilisateur au chargement du composant
  }, []);

  // Afficher un message si en mode démo
  const handleDemoClick = () => {
    if (isDemoMode) {
      toast.info("Fonction désactivée en mode Démonstration");
      return true; // Indique que l'action est désactivée en mode démo
    }
    return false; // Permet l'action si pas en mode démo
  };

  // Récupérer les membres de la Team depuis l'API
  const fetchUsers = async () => {
    try {
      const data = await getAllUsers();
      setUsers(data);
    } catch (error) {
      console.error("Erreur lors de la récupération des utilisateurs :", error);
      toast.error("Erreur lors de la récupération des utilisateurs.");
    }
  };

  // Gérer la création d'un utilisateur
  const handleCreate = async (newUser) => {
    if (handleDemoClick()) return; // Bloquer l'action en mode démo
    try {
      await createUser(newUser);
      toast.success("Utilisateur ajouté avec succès !");
      fetchUsers(); // Rafraîchir la liste des utilisateurs après création
    } catch (error) {
      console.error("Erreur lors de l'ajout de l'utilisateur :", error);
      toast.error("Erreur lors de l'ajout de l'utilisateur.");
    }
  };

  // Gérer l'édition d'un utilisateur
  const handleEdit = (user) => {
    if (handleDemoClick()) return; // Bloquer l'action en mode démo
    setCurrentUser(user);
    setEditModalOpen(true);
  };

  const handleSaveEdit = async (user) => {
    try {
      await updateUser(user.id, user);
      toast.success("Utilisateur mis à jour avec succès !");
      fetchUsers(); // Rafraîchir la liste après modification
    } catch (error) {
      console.error("Erreur lors de la modification de l'utilisateur :", error);
      toast.error("Erreur lors de la modification de l'utilisateur.");
    }
  };

  // Fonction pour gérer la suppression d'un utilisateur
  const handleDelete = async (id) => {
    if (handleDemoClick()) return; // Bloquer l'action en mode démo
    if (window.confirm("Êtes-vous sûr de vouloir supprimer cet utilisateur?")) {
      try {
        await deleteUser(id);
        toast.success("Utilisateur supprimé avec succès.");
        fetchUsers(); // Rafraîchir la liste après suppression
      } catch (error) {
        console.error("Erreur lors de la suppression de l'utilisateur :", error);
        toast.error("Erreur lors de la suppression de l'utilisateur.");
      }
    }
  };

  const getRoleLabel = (role_id) => {
    switch (role_id) {
      case 1:
        return "Administrateur";
      case 2:
        return "Bureau";
      case 3:
        return "Enseignant";
      case 4:
        return "Démonstration";
      default:
        return "Inconnu";
    }
  };

  const getRoleBadgeClass = (role_id) => {
    switch (role_id) {
      case 1:
        return "bg-red-500 text-white px-2 py-2 rounded-full text-sm"; // Badge rouge pour Administrateur
      case 2:
        return "bg-blue-500 text-white px-2 py-2 rounded-full text-sm"; // Badge bleu pour Bureau
      case 3:
        return "bg-green-500 text-white px-2 py-2 rounded-full text-sm"; // Badge vert pour Enseignant
      case 4:
        return "bg-yellow-500 text-white px-2 py-2 rounded-full text-sm"; // Badge jaune pour Démonstration
      default:
        return "bg-gray-500 text-white px-2 py-2 rounded-full text-sm"; // Badge gris pour les rôles inconnus
    }
  };

  const maskPassword = (password) => {
    return "*".repeat(password.length);
  };

  return (
    <div className="p-6 bg-white dark:bg-gray-800 dark:text-gray-200 rounded-md shadow-md">
      <h2 className="text-2xl font-semibold mb-6">
        Gestion des Utilisateurs de l'Application
      </h2>

      {/* Bouton pour créer un nouvel utilisateur */}
      <Button
        text={
          <>
            <FaPlus className="mr-2" /> Créer un nouvel utilisateur
          </>
        }
        onClick={() => {
          if (!handleDemoClick()) setCreateModalOpen(true);
        }} // Bloquer la création en mode démo
        className="mb-4"
      />

      {/* Tableau des utilisateurs */}
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-md">
          <thead>
            <tr className="bg-gray-100 dark:bg-gray-800 dark:text-gray-200">
              <th className="px-4 py-2 border dark:border-gray-600">Photo</th>
              <th className="px-4 py-2 border dark:border-gray-600">Nom</th>
              <th className="px-4 py-2 border dark:border-gray-600">Prénom</th>
              <th className="px-4 py-2 border dark:border-gray-600">Email</th>
              <th className="px-4 py-2 border dark:border-gray-600">Role</th>
              <th className="px-4 py-2 border dark:border-gray-600">Password</th>
              <th className="px-4 py-2 border dark:border-gray-600">Action</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 dark:divide-gray-600">
            {users.map((user) => (
              <tr key={user.id} className="hover:bg-gray-50 dark:hover:bg-gray-600">
                <td className="px-4 py-2 border text-center dark:border-gray-600">
                  <img
                    src={user.photoURL}
                    alt={user.nom}
                    className="h-16 w-16 object-cover rounded"
                  />
                </td>
                <td className="px-4 py-2 border dark:border-gray-600">{user.nom}</td>
                <td className="px-4 py-2 border dark:border-gray-600">{user.prenom}</td>
                <td className="px-4 py-2 border dark:border-gray-600">{user.email}</td>
                {/* Affichage du tag centré avec la classe de badge */}
                <td className="px-4 py-2 border text-center dark:border-gray-600">
                  <span className={getRoleBadgeClass(user.role_id)}>
                    {getRoleLabel(user.role_id)}
                  </span>
                </td>
                {/* Masquer le mot de passe avec maskPassword */}
                <td className="px-4 py-2 border text-center dark:border-gray-600">
                  {maskPassword(user.password)}
                </td>
                <td className="px-4 py-2 border-none dark:text-gray-200">
                  <div className="flex justify-center items-center w-full h-full gap-2">
                    <ButtonEdit onClick={() => handleEdit(user)} /> {/* Bloquer l'édition en mode démo */}
                    <ButtonDelete onClick={() => handleDelete(user.id)} /> {/* Bloquer la suppression en mode démo */}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Modale de création */}
      {isCreateModalOpen && (
        <CreateUserModal
          onClose={() => setCreateModalOpen(false)}
          onCreate={handleCreate}
        />
      )}

      {/* Modale d'édition */}
      {isEditModalOpen && currentUser && (
        <EditUserModal
          member={currentUser}
          onClose={() => setEditModalOpen(false)}
          onSave={handleSaveEdit}
        />
      )}
    </div>
  );
};

export default UsersManager;
