import React from "react";
import TeamClub from "./TeamClub"; // Composant pour gérer la Team du Club
import HistoriqueClub from "./HistoriqueClub"; // Composant pour gérer l'Historique du Club
import UsersManager from "./UsersManager"; // Composant pour gérer les utilisateurs
import { ToastContainer } from "react-toastify";

const LeClub = () => {
  return (
    <div className="space-y-4">
      <TeamClub /> {/* Affiche la gestion de la Team */}
      <UsersManager /> {/* Affiche la gestion des utilisateurs */}
      <HistoriqueClub /> {/* Affiche l'historique du club */}
      <ToastContainer /> {/* Conteneur pour les notifications */}
    </div>
  );
};

export default LeClub;
