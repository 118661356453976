import React, { useState, useEffect } from "react";
import { FaFacebookF, FaInstagram } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { getSettings } from "../../../services/settingsServices";

const Footer = () => {
  const navigate = useNavigate();
  const [settings, setSettings] = useState({
    nom_club: "",
    logo_club: "",
    adresse_club: "",
    tel_club: "",
    mail_club: "",
    facebook_club: "",
    instagram_club: "",
  });
  const [mapUrl, setMapUrl] = useState("");

  useEffect(() => {
    const fetchSettings = async () => {
      const data = await getSettings();
      if (data.length > 0) {
        setSettings(data[0]);
      }
    };

    fetchSettings();
  }, []);

  useEffect(() => {
    const encodedAddress = encodeURIComponent(settings.adresse_club);
    const newMapUrl = `https://www.google.com/maps?q=${encodedAddress}&output=embed`;
    setMapUrl(newMapUrl);
  }, [settings.adresse_club]);

  // Fonction pour gérer le défilement vers les ancres
  const handleNavigate = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    } else {
      navigate("/", { state: { scrollTo: id } });
    }
  };

  return (
    <div className="footer-wrapper">
      <footer className="bg-gray-800 p-6 text-white rounded-t border-t-2 flex flex-col lg:flex-row justify-between items-center">
        <div className="map-container flex-1 flex flex-col items-start lg:mb-0">
          <iframe
            title="Location Map"
            src={mapUrl}
            width="100%"
            height="200"
            style={{ border: 0 }}
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
          <p className="mt-4">Adresse : {settings.adresse_club}</p>
          <p>
            Mail :{" "}
            <a
              href={`mailto:${settings.mail_club}`}
              className="link link-hover text-white hover:text-primary"
            >
              {settings.mail_club}
            </a>
          </p>
          <p className="mb-4">Téléphone : {settings.tel_club}</p>
        </div>
        {/* Logo et Nom de l'application */}
        <div className="logo-container flex-1 flex flex-col items-center lg:mb-0">
          <img
            src={settings.logo_club}
            alt={`Logo ${settings.nom_club}`}
            style={{ width: "150px", height: "auto" }}
          />
          <h2 className="font-titre mt-2 text-2xl">{settings.nom_club}</h2>
          {/* Icônes Facebook et Instagram */}
          {(settings.facebook_club || settings.instagram_club) && (
            <div className="social-icons flex flex-row justify-center mt-4">
              {settings.facebook_club && (
                <a
                  href={settings.facebook_club}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-white mx-2 hover:text-primary"
                >
                  <FaFacebookF size={24} />
                </a>
              )}
              {settings.instagram_club && (
                <a
                  href={settings.instagram_club}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-white mx-2 hover:text-primary"
                >
                  <FaInstagram size={24} />
                </a>
              )}
            </div>
          )}
        </div>

        {/* Séparateur vertical en blanc sur mobile */}
        <div className="hidden lg:block h-full w-1 bg-white mx-6"></div>

        {/* Navigation vers les sections du site */}
        <div className="flex flex-row lg:flex-row justify-between w-full lg:w-auto">
          <div className="flex-1 flex flex-col items-center">
            <nav className="flex flex-col gap-1 text-center text-sm lg:text-base">
              <a
                href="#about"
                onClick={() => handleNavigate("about")}
                className="link link-hover text-white hover:text-primary"
              >
                À propos
              </a>
              <a
                href="#cours"
                onClick={() => handleNavigate("cours")}
                className="link link-hover text-white hover:text-primary"
              >
                Nos Disciplines
              </a>
              <a
                href="#news"
                onClick={() => handleNavigate("news")}
                className="link link-hover text-white hover:text-primary"
              >
                Actualités
              </a>
              <a
                href="#galerie"
                onClick={() => handleNavigate("galerie")}
                className="link link-hover text-white hover:text-primary"
              >
                Albums Photos
              </a>
              <a
                href="#partenaires"
                onClick={() => handleNavigate("partenaires")}
                className="link link-hover text-white hover:text-primary"
              >
                Partenaires
              </a>
              <a
                href="#contact"
                onClick={() => handleNavigate("contact")}
                className="link link-hover text-white hover:text-primary"
              >
                Contact
              </a>
            </nav>
          </div>

          {/* Séparateur fin vertical entre les deux blocs de liens */}
          <div className="h-auto w-[1px] bg-white mx-3 lg:hidden"></div>

          <div className="flex-1 flex flex-col items-center">
            <nav className="flex flex-col gap-1 text-center text-sm lg:text-base">
              <a
                href="/mentions-legales"
                className="link link-hover text-white hover:text-primary"
              >
                Mentions légales
              </a>
              <a
                href="/politique-confidentialite"
                className="link link-hover text-white hover:text-primary"
              >
                Politique de confidentialité
              </a>
              <a
                href="/conditions-utilisation"
                className="link link-hover text-white hover:text-primary"
              >
                Conditions d’utilisation
              </a>
            </nav>
          </div>
        </div>
      </footer>
      <aside className="w-full h-15 bg-black text-white p-2 text-center text-sm">
        <p>
          Copyright © {new Date().getFullYear()} - Tous droits réservés -{" "}
          <a
            href="https://www.monappliclub.com"
            target="_blank"
            rel="noopener noreferrer"
            className="link link-hover text-white hover:text-primary"
          >
            MonAppliClub
          </a>{" "}
          -{" "}
          <a
            href="https://www.laurent-victorin.com"
            target="_blank"
            rel="noopener noreferrer"
            className="link link-hover text-white hover:text-primary"
          >
            Laurent VICTORIN
          </a>
        </p>
      </aside>
    </div>
  );
};

export default Footer;
