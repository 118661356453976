import React, { useState, useEffect } from "react";
import DisciplineCard from "./DisciplineCard";
import { getAllDisciplines } from "../../../services/disciplinesServices";
import ComponentTitle from "./../../../components/ComponentTitle";

const Disciplines = () => {
  const [disciplines, setDisciplines] = useState([]);

  useEffect(() => {
    fetchDisciplines();
  }, []);

  const fetchDisciplines = async () => {
    try {
      const data = await getAllDisciplines();
      setDisciplines(data);
    } catch (error) {
      console.error("Erreur lors de la récupération des disciplines", error);
    }
  };

  return (
    <div className="py-8 bg-gray-200 dark:bg-gray-800 dark:text-gray-200">
      <div className="container mx-auto px-4">
        {/* Titre avec ComponentTitle */}
        <div className="mx-auto max-w-screen-md text-center mb-4 lg:mb-6">
          <ComponentTitle title="NOS" highlight=" DISCIPLINES" />
        </div>

        {/* Grid des cartes de disciplines */}
        <div className="flex flex-wrap -mx-4 justify-center">
          {disciplines.map((discipline, index) => (
            <div className="w-full sm:w-1/2 md:w-1/4 p-4" key={index}>
              <DisciplineCard
                title={discipline.nom_discipline}
                ageRange={discipline.description_discipline}
                imageSrc={discipline.image_discipline}
                textDiscipline={discipline.contenu_discipline}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Disciplines;
