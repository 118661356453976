import React, { useState, useEffect } from "react";
import { PieChart, Pie, Cell, Tooltip, Legend, ResponsiveContainer } from "recharts";
import { getAdherentsCountBySexe } from "../../../../services/adherentServices";

const GenderDistributionPieChart = () => {
  const [data, setData] = useState([]);

  // Couleurs pour le camembert
  const COLORS = ["#FF69B4", "#1E90FF"]; // Rose pour Femmes, Bleu pour Hommes

  useEffect(() => {
    const fetchGenderDistribution = async () => {
      try {
        const response = await getAdherentsCountBySexe();
        setData(response);
      } catch (error) {
        console.error("Erreur lors de la récupération des données de distribution par genre", error);
      }
    };

    fetchGenderDistribution();
  }, []);

  // Calcul du total des adhérents pour les pourcentages
  const totalAdherents = data.reduce((total, item) => total + item.count, 0);

  return (
    <div className="bg-gray-200 rounded-lg shadow-lg p-4 dark:bg-gray-800 dark:text-gray-200">
      <h3 className="text-lg font-bold text-gray-800 dark:text-gray-100 mb-4">Répartition des Adhérents par Genre</h3>
      <ResponsiveContainer width="100%" height={400}>
        <PieChart>
          <Pie
            data={data}
            dataKey="count"
            nameKey="genre"
            cx="50%"
            cy="50%"
            outerRadius={150}
            fill="#8884d8"
            label={({ genre, percent }) => `${genre === "M" ? "Masculin" : "Féminin"}: ${(percent * 100).toFixed(1)}%`}
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
          <Tooltip formatter={(value) => `${value} adhérents`} />
          <Legend
            formatter={(value) => (value === "M" ? "Masculin" : "Féminin")}
            iconType="circle"
          />
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
};

export default GenderDistributionPieChart;
