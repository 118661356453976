import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import { getAllPartenaires } from "../../../services/partenairesServices";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ComponentTitle from "./../../../components/ComponentTitle";
import "./PartenairesCarousel.css"; // Assurez-vous de créer ce fichier CSS pour les styles personnalisés

const PartenairesCarousel = () => {
  const [partenaires, setPartenaires] = useState([]);

  useEffect(() => {
    const fetchPartenaires = async () => {
      try {
        const allPartenaires = await getAllPartenaires();
        const activePartenaires = allPartenaires.filter(p => p.is_active);
        setPartenaires(activePartenaires);
      } catch (error) {
        console.error("Erreur lors de la récupération des partenaires", error);
      }
    };

    fetchPartenaires();
  }, []);

  let settings = {
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 3000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  // Ajuster les paramètres si un seul partenaire est présent
  if (partenaires.length === 1) {
    settings = {
      infinite: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: false,
    };
  }

  if (partenaires.length === 0) {
    return null; // Ne rien afficher si pas de partenaires actifs
  }

  return (
    <div className="py-8 bg-gray-200 dark:bg-gray-800 dark:text-gray-200">
      <div className="container mx-auto px-4">
        {/* Utilisation de ComponentTitle pour le titre */}
        <div className="mx-auto max-w-screen-md text-center mb-4 lg:mb-6">
          <ComponentTitle title="NOS" highlight=" PARTENAIRES" />
        </div>

        {/* Carousel des partenaires */}
        <Slider {...settings}>
          {partenaires.map((partenaire) => (
            <div key={partenaire.id} className="p-4">
              <a href={partenaire.link_url} target="_blank" rel="noopener noreferrer">
                <div className="bg-slate-100 dark:bg-slate-600 shadow-md rounded-lg p-4 flex flex-col items-center">
                  <img
                    src={partenaire.photo_url}
                    alt={partenaire.nom}
                    className="w-full h-32 object-contain mb-4"
                  />
                  <h3 className="text-normal font-semibold text-center">{partenaire.nom}</h3>
                </div>
              </a>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default PartenairesCarousel;
