import React, { useState, useEffect } from "react";
import { getAllGalleries } from "../../../services/gallerieServices"; // Assurez-vous que le chemin est correct
import ComponentTitle from "./../../../components/ComponentTitle"; // Importer ComponentTitle pour le titre

const LastGallerie = () => {
  const [galleries, setGalleries] = useState([]);
  const [selectedGallery, setSelectedGallery] = useState(null); // État pour gérer la galerie sélectionnée
  const [selectedImage, setSelectedImage] = useState(null); // État pour gérer l'image sélectionnée pour l'agrandissement

  useEffect(() => {
    const fetchGalleries = async () => {
      try {
        const allGalleries = await getAllGalleries(); // Fonction API pour obtenir les galeries
        const activeGalleries = allGalleries.filter(gallery => gallery.is_active); // Filtrer les galeries actives
        setGalleries(activeGalleries.slice(0, 3)); // Gardez seulement les trois premières galeries actives
      } catch (error) {
        console.error("Failed to fetch galleries", error);
      }
    };

    fetchGalleries();
  }, []);

  // Fonction pour ouvrir la modale de galerie avec les photos
  const openGalleryModal = (gallery) => {
    setSelectedGallery(gallery);
  };

  // Fonction pour fermer la modale
  const closeGalleryModal = () => {
    setSelectedGallery(null);
    setSelectedImage(null);
  };

  // Fonction pour ouvrir l'image sélectionnée
  const openImageModal = (imageUrl) => {
    setSelectedImage(imageUrl);
  };

  return (
    <div className="py-8 bg-gray-200 dark:bg-gray-800 dark:text-gray-200">
      <div className="container mx-auto px-4">
        {/* Titre avec ComponentTitle */}
        <div className="mx-auto max-w-screen-md text-center mb-4 lg:mb-6">
          <ComponentTitle title="DERNIERS" highlight=" ALBUMS PHOTOS" />
        </div>

        {/* Grid des galeries */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 p-4">
          {galleries.map((gallery) => (
            <div
              key={gallery.id}
              className="flex flex-col bg-slate-100 dark:bg-slate-600 shadow-md rounded-lg p-4 justify-between h-full cursor-pointer"
              onClick={() => openGalleryModal(gallery)} // Ouvre la modale au clic
            >
              <div>
                <img
                  src={
                    gallery.photos && gallery.photos.length > 0
                      ? gallery.photos[0].photo_url
                      : "/img/cours_judo.jpg"
                  }
                  alt={gallery.titre}
                  className="w-full h-64 object-cover rounded-t-lg mb-2"
                />
                <h3 className="text-xl font-bold mb-2">{gallery.titre}</h3>
                <p className="text-sm text-gray-600 dark:text-slate-300 mb-4">
                  {gallery.description}
                </p>
              </div>
            </div>
          ))}
        </div>

        {/* Modale pour afficher toutes les photos en vignettes */}
        {selectedGallery && (
          <div
            className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50"
            onClick={closeGalleryModal}
          >
            <div className="relative bg-white dark:bg-gray-800 p-4 rounded-lg max-w-4xl w-full">
              <button
                className="absolute top-2 right-2 text-black dark:text-white text-2xl"
                onClick={closeGalleryModal}
                aria-label="Fermer"
              >
                &times;
              </button>
              <h2 className="text-2xl font-bold text-center mb-4 dark:text-white">{selectedGallery.titre}</h2>
              <div className="grid grid-cols-3 gap-2">
                {selectedGallery.photos.map((photo) => (
                  <img
                    key={photo.id}
                    src={photo.photo_url}
                    alt={photo.titre}
                    className="w-full h-32 object-cover rounded-lg cursor-pointer"
                    onClick={(e) => {
                      e.stopPropagation(); // Empêche la fermeture de la modale quand on clique sur l'image
                      openImageModal(photo.photo_url);
                    }}
                  />
                ))}
              </div>
            </div>
          </div>
        )}

        {/* Modale pour afficher l'image sélectionnée en grand */}
        {selectedImage && (
          <div
            className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50"
            onClick={() => setSelectedImage(null)}
          >
            <div className="relative">
              <img
                src={selectedImage}
                alt="Agrandissement"
                className="max-w-full max-h-full rounded-lg"
              />
              <button
                className="absolute top-2 right-2 text-white text-2xl"
                onClick={() => setSelectedImage(null)}
                aria-label="Fermer"
              >
                &times;
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default LastGallerie;
