import React, { useEffect, useState } from "react";
import { getSettings, updateSettings } from "../../../../services/settingsServices"; // Import du service pour récupérer et mettre à jour les paramètres
import Button from "../../../../components/Button/Button"; // Importation du composant Button
import { toast } from "react-toastify";
import ReactQuill from "react-quill"; // Import du composant React-Quill
import "react-quill/dist/quill.snow.css"; // Import du CSS de React-Quill

const HistoriqueClub = () => {
  const [settings, setSettings] = useState({
    historique_photo_url: "",
    historique_photo2_url: "",
    historique_photo3_url: "",
    historique_title: "",
    historique_content: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isDemoMode, setIsDemoMode] = useState(false); // Pour vérifier si l'utilisateur est en mode démo
  const [currentImageIndex, setCurrentImageIndex] = useState(0); // Pour le carrousel

  // Fonction pour vérifier le role_id dans localStorage
  const checkUserRole = () => {
    const user = JSON.parse(localStorage.getItem("user")); // Récupérer l'utilisateur depuis localStorage
    if (user && user.role_id === 4) {
      setIsDemoMode(true); // Activer le mode démo si role_id est 4
    }
  };

  // Récupérer les paramètres depuis l'API
  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const data = await getSettings();
        if (data && data.length > 0) {
          setSettings(data[0]); // Prendre la première ligne de settings
        }
      } catch (error) {
        console.error("Erreur lors de la récupération de l'historique du club :", error);
        toast.error("Erreur lors de la récupération de l'historique du club.");
      }
    };

    fetchSettings();
    checkUserRole(); // Vérifier le rôle de l'utilisateur
  }, []);

  // Gérer les changements dans les inputs
  const handleChange = (e) => {
    const { name, value } = e.target;
    setSettings((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // Gérer les changements dans l'éditeur de texte enrichi (React-Quill)
  const handleQuillChange = (value) => {
    setSettings((prev) => ({
      ...prev,
      historique_content: value,
    }));
  };

  // Gérer la soumission du formulaire pour mettre à jour les paramètres
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isDemoMode) {
      toast.info("Fonction désactivée en mode Démonstration");
      return; // Bloquer la soumission en mode démo
    }
    setIsLoading(true);
    try {
      await updateSettings(settings); // Envoyer la mise à jour des paramètres
      toast.success("Historique mis à jour avec succès !");
    } catch (error) {
      console.error("Erreur lors de la mise à jour de l'historique :", error);
      toast.error("Erreur lors de la mise à jour de l'historique.");
    } finally {
      setIsLoading(false);
    }
  };

  // Carrousel des images
  const images = [
    settings.historique_photo_url,
    settings.historique_photo2_url,
    settings.historique_photo3_url,
  ].filter(Boolean); // Filtre les images qui existent

  const handleNextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const handlePrevImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  return (
    <div className="p-6 bg-white dark:bg-gray-800 dark:text-gray-200 rounded-md shadow-md mt-8">
      <h2 className="text-2xl font-semibold mb-6">Historique du Club</h2>

      <form onSubmit={handleSubmit} className="space-y-4">
        {/* Input pour l'URL de la première photo */}
        <div>
          <label className="block font-semibold mb-2 dark:text-gray-200">
            URL de la Première Photo de l'Historique
          </label>
          <input
            type="text"
            name="historique_photo_url"
            value={settings.historique_photo_url}
            onChange={handleChange}
            className="w-full p-2 border border-gray-300 dark:border-gray-600 rounded-md dark:bg-gray-700 dark:text-gray-200"
            placeholder="Entrez l'URL de la première photo"
          />
        </div>

        {/* Input pour l'URL de la deuxième photo */}
        <div>
          <label className="block font-semibold mb-2 dark:text-gray-200">
            URL de la Deuxième Photo de l'Historique
          </label>
          <input
            type="text"
            name="historique_photo2_url"
            value={settings.historique_photo2_url}
            onChange={handleChange}
            className="w-full p-2 border border-gray-300 dark:border-gray-600 rounded-md dark:bg-gray-700 dark:text-gray-200"
            placeholder="Entrez l'URL de la deuxième photo"
          />
        </div>

        {/* Input pour l'URL de la troisième photo */}
        <div>
          <label className="block font-semibold mb-2 dark:text-gray-200">
            URL de la Troisième Photo de l'Historique
          </label>
          <input
            type="text"
            name="historique_photo3_url"
            value={settings.historique_photo3_url}
            onChange={handleChange}
            className="w-full p-2 border border-gray-300 dark:border-gray-600 rounded-md dark:bg-gray-700 dark:text-gray-200"
            placeholder="Entrez l'URL de la troisième photo"
          />
        </div>

        {/* Carrousel des images */}
        {images.length > 0 && (
          <div className="relative mb-4">
            <img
              src={images[currentImageIndex]}
              alt={`Aperçu ${currentImageIndex + 1}`}
              className="max-w-full h-auto rounded-md"
            />
            <button
              type="button"
              onClick={handlePrevImage}
              className="absolute top-1/2 left-0 transform -translate-y-1/2 px-2 py-1 bg-gray-600 text-white rounded-full"
            >
              ‹
            </button>
            <button
              type="button"
              onClick={handleNextImage}
              className="absolute top-1/2 right-0 transform -translate-y-1/2 px-2 py-1 bg-gray-600 text-white rounded-full"
            >
              ›
            </button>
          </div>
        )}

        {/* Input pour le titre de l'historique */}
        <div>
          <label className="block font-semibold mb-2 dark:text-gray-200">
            Titre de l'Historique
          </label>
          <input
            type="text"
            name="historique_title"
            value={settings.historique_title}
            onChange={handleChange}
            className="w-full p-2 border border-gray-300 dark:border-gray-600 rounded-md dark:bg-gray-700 dark:text-gray-200"
            placeholder="Entrez le titre de l'historique"
          />
        </div>

        {/* Éditeur de texte enrichi pour le contenu de l'historique */}
        <div>
          <label className="block font-semibold mb-2 dark:text-gray-200">
            Contenu de l'Historique
          </label>
          <ReactQuill
            value={settings.historique_content}
            onChange={handleQuillChange}
            className="bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-md dark:text-gray-200"
          />
        </div>

        <div className="flex justify-end">
          <Button
            text="Enregistrer"
            type="submit"
            disabled={isLoading || isDemoMode} // Désactiver si en mode démo
            className="btn-primary"
          />
        </div>
      </form>
    </div>
  );
};

export default HistoriqueClub;
