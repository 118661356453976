import React, { useState, useEffect } from "react";
import { getNews } from "./../../../services/newsServices";
import ComponentTitle from "./../../../components/ComponentTitle"; // Import du ComponentTitle pour le titre

const LatestNews = () => {
  const [newsList, setNewsList] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null); // État pour gérer l'image sélectionnée
  const [currentPage, setCurrentPage] = useState(1); // État pour gérer la page courante
  const newsPerPage = 3; // Nombre d'actualités par page

  useEffect(() => {
    const fetchNews = async () => {
      try {
        const latestNews = await getNews(); // Fonction API pour obtenir les dernières actualités
        const activeNews = latestNews.filter((news) => news.is_active); // Filtrer pour garder seulement les actualités actives
        setNewsList(activeNews); // Stocker toutes les actualités actives
      } catch (error) {
        console.error("Failed to fetch news", error);
      }
    };

    fetchNews();
  }, []);

  // Calculer les actualités pour la page courante
  const indexOfLastNews = currentPage * newsPerPage;
  const indexOfFirstNews = indexOfLastNews - newsPerPage;
  const currentNews = newsList.slice(indexOfFirstNews, indexOfLastNews);

  // Fonction pour passer à la page suivante
  const nextPage = () => {
    if (currentPage < Math.ceil(newsList.length / newsPerPage)) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  // Fonction pour revenir à la page précédente
  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  // Fonction pour ouvrir la modale avec l'image sélectionnée
  const openModal = (imageUrl) => {
    setSelectedImage(imageUrl);
  };

  // Fonction pour fermer la modale
  const closeModal = () => {
    setSelectedImage(null);
  };

  return (
    <div className="py-8 bg-gray-200 dark:bg-gray-800 dark:text-gray-200">
      <div className="container mx-auto px-4">
        {/* Titre avec ComponentTitle */}
        <div className="mx-auto max-w-screen-md text-center mb-4 lg:mb-6">
          <ComponentTitle title="DERNIÈRES" highlight=" ACTUALITÉS" />
        </div>

        {/* Grid des actualités */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 p-4">
          {currentNews.map((news) => (
            <div
              key={news.id}
              className="flex flex-col bg-slate-100 dark:bg-slate-600 shadow-md rounded-lg p-4 justify-between h-full"
            >
              <div>
                <img
                  src={news.image_url}
                  alt={news.title}
                  className="w-full h-64 object-cover rounded-t-lg cursor-pointer mb-2"
                  onClick={() => openModal(news.image_url)} // Ouvre la modale au clic
                />
                <h3 className="text-xl font-bold mb-2">{news.title}</h3>

                <div
                  className="text-sm text-gray-600 dark:text-slate-300 mb-4"
                  dangerouslySetInnerHTML={{ __html: news.content }}
                />
              </div>
            </div>
          ))}
        </div>

        {/* Afficher la pagination seulement si le nombre total de news est supérieur à 3 */}
        {newsList.length > newsPerPage && (
          <div className="flex justify-between items-center p-4">
            <button
              onClick={prevPage}
              className={`${
                currentPage === 1
                  ? "opacity-50 cursor-not-allowed"
                  : "hover:bg-gray-200"
              } bg-primary text-white px-4 py-2 rounded-lg`}
              disabled={currentPage === 1}
            >
              Précédent
            </button>
            <span className="text-lg">Page {currentPage}</span>
            <button
              onClick={nextPage}
              className={`${
                currentPage === Math.ceil(newsList.length / newsPerPage)
                  ? "opacity-50 cursor-not-allowed"
                  : "hover:bg-gray-200"
              } bg-primary text-white px-4 py-2 rounded-lg`}
              disabled={currentPage === Math.ceil(newsList.length / newsPerPage)}
            >
              Suivant
            </button>
          </div>
        )}

        {/* Modale pour afficher l'image en grand */}
        {selectedImage && (
          <div
            className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50"
            onClick={closeModal}
          >
            <div className="relative">
              <img
                src={selectedImage}
                alt="Agrandissement"
                className="max-w-full max-h-full rounded-lg"
              />
              <button
                className="absolute top-2 right-2 text-white text-2xl"
                onClick={closeModal}
                aria-label="Fermer"
              >
                &times;
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default LatestNews;
