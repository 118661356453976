import React, { useEffect, useState } from 'react';
import {
  getAllMessagesContactForm,
  markMessageContactForm,
  deleteMessageContactForm,
} from '../../../../services/contactformServices';
import { toast } from 'react-toastify'; // Utilisation de Toast pour les notifications
import { useContactForm } from '../../../../contexts/ContactFormContext';
import ButtonDelete from '../../../../components/Button/ButtonDelete';
import ButtonEnvelopeToggle from '../../../../components/Button/ButtonEnvelopeToggle'; // Import du nouveau bouton d'enveloppe
import { FaEnvelope } from 'react-icons/fa'; // Import de l'icône d'enveloppe

const MessagesSection = () => {
  const [messages, setMessages] = useState([]);
  const { unreadCountContactForm, setUnreadCountContactForm } = useContactForm();
  const [isDemoMode, setIsDemoMode] = useState(false); // État pour vérifier si l'utilisateur est en mode démo

  // Fonction pour vérifier le role_id dans localStorage
  const checkUserRole = () => {
    const user = JSON.parse(localStorage.getItem('user')); // Récupérer l'utilisateur depuis localStorage
    if (user && user.role_id === 4) {
      setIsDemoMode(true); // Activer le mode démo si role_id est 4
    }
  };

  const loadMessages = async () => {
    try {
      const data = await getAllMessagesContactForm();
      setMessages(data);
    } catch (error) {
      console.error('Erreur lors de la récupération des messages :', error);
      toast.error('Erreur lors de la récupération des messages.');
    }
  };

  useEffect(() => {
    loadMessages();
    checkUserRole(); // Vérifier le rôle de l'utilisateur au chargement du composant
  }, []);

  // Afficher un message si en mode démo
  const handleDemoClick = () => {
    if (isDemoMode) {
      toast.info('Fonction désactivée en mode Démonstration');
      return true; // Indique que l'action est désactivée en mode démo
    }
    return false; // Permet l'action si pas en mode démo
  };

  const toggleReadStatus = async (messageId) => {
    if (handleDemoClick()) return; // Bloquer l'action en mode démo
    try {
      const message = messages.find((m) => m.id === messageId);
      if (!message) {
        console.error('Message non trouvé');
        return;
      }

      await markMessageContactForm(messageId);

      setMessages((prevMessages) =>
        prevMessages.map((m) =>
          m.id === messageId ? { ...m, read_message: !m.read_message } : m
        )
      );

      setUnreadCountContactForm((prevCount) =>
        message.read_message ? prevCount + 1 : prevCount - 1
      );
    } catch (error) {
      console.error("Erreur lors du basculement de l'état de lecture du message:", error);
    }
  };

  const handleDeleteMessage = async (messageId) => {
    if (handleDemoClick()) return; // Bloquer l'action en mode démo
    try {
      await deleteMessageContactForm(messageId);
      setMessages((prevMessages) =>
        prevMessages.filter((msg) => msg.id !== messageId)
      );
      toast.success('Message supprimé avec succès.');
    } catch (error) {
      console.error('Erreur lors de la suppression du message :', error);
      toast.error('Erreur lors de la suppression du message.');
    }
  };

  return (
    <div className="overflow-x-auto shadow-xl font-montserrat dark:bg-gray-800 dark:text-white">
      <div className="flex justify-between">
        <h3 className="card-title mb-2 ml-2 mt-4 text-gray-900 dark:text-white">
          <div className="relative">
            <FaEnvelope className="icon" title="Messages" />
            {unreadCountContactForm > 0 && (
              <span className="absolute -top-2 -right-3 bg-primary text-white text-xs rounded-full px-2 py-0.5">
                {unreadCountContactForm}
              </span>
            )}
          </div>
          <span className="ml-2">Messages du Formulaire de Contact</span>
        </h3>
      </div>
      {messages.length > 0 ? (
        <div className="overflow-x-auto">
          <table className="min-w-full bg-gray-50 dark:bg-gray-800 divide-y divide-gray-400 dark:divide-gray-600">
            <thead className="bg-gray-50 dark:bg-gray-700">
              <tr className="bg-slate-400 dark:bg-gray-900">
                <th className="px-2 py-2 text-left text-xs font-medium uppercase tracking-wider text-white">
                  Date
                </th>
                <th className="px-2 py-2 text-left text-xs font-medium uppercase tracking-wider text-white">
                  Message
                </th>
                <th className="px-2 py-2 text-left text-xs font-medium uppercase tracking-wider text-white">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-300 dark:divide-gray-600">
              {messages.map((message) => (
                <tr
                  key={message.id}
                  className={`hover:bg-gray-300 dark:hover:bg-gray-700 ${
                    message.read_message
                      ? 'bg-gray-200 dark:bg-gray-600'
                      : 'bg-gray-100 dark:bg-gray-800'
                  }`}
                >
                  <td className="px-2 py-2 whitespace-nowrap text-sm text-gray-900 dark:text-gray-100">
                    {new Date(message.created_at).toLocaleDateString('fr-FR', {
                      year: '2-digit',
                      month: '2-digit',
                      day: '2-digit',
                    })}
                  </td>
                  <td className="px-4 py-2 text-sm text-gray-500 dark:text-gray-300 max-w-xs overflow-hidden text-ellipsis">
                    <p className="font-bold">{message.name}</p>
                    <p>{message.message}</p>
                    <p className="text-xs text-gray-500 dark:text-gray-400">{message.email}</p>
                  </td>
                  <td className="px-4 py-2 whitespace-nowrap text-right text-sm font-medium gap-2">
                    <div className="flex justify-center items-center w-full h-full gap-2">
                      <ButtonEnvelopeToggle
                        isRead={message.read_message}
                        onClick={() => toggleReadStatus(message.id)}
                      />
                      <ButtonDelete
                        onClick={() => handleDeleteMessage(message.id)}
                      />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <p className="text-center text-gray-900 dark:text-gray-100">Pas encore de messages.</p>
      )}
    </div>
  );
};

export default MessagesSection;
