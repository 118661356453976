import React, { useState, useEffect } from "react";
import {
  getAllAdherent,
  addNewAdherent,
  updateAdherent,
  deleteAdherent,
} from "../../../../services/adherentServices"; // Import du service pour récupérer les adhérents
import { getGroupsHome } from "../../../../services/groupServices";
import Button from "../../../../components/Button/Button"; // Composant Button pour ajouter un nouvel adhérent
import ButtonEdit from "../../../../components/Button/ButtonEdit"; // Composant pour éditer
import ButtonDelete from "../../../../components/Button/ButtonDelete"; // Composant pour supprimer
import ButtonDetails from "../../../../components/Button/ButtonDetails"; // Composant pour afficher les détails
import { toast, ToastContainer } from "react-toastify";
import { FaSortAlphaDown, FaSortAlphaUp, FaPlus } from "react-icons/fa"; // Icônes pour trier
import InscriptionDetailsModal from "./InscriptionDetailsModal"; // Modale de détail
import CreateAdherentModal from "./CreateAdherentModal"; // Modale pour la création
import EditAdherentModal from "./EditAdherentModal"; // Modale pour l'édition

// Fonction pour obtenir la classe de couleur en fonction du groupe
const getGroupColor = (groupColors, groupe) => {
  return groupColors[groupe] || "bg-gray-400"; // Classe par défaut si le groupe n'a pas de couleur définie
};

const Inscriptions = () => {
  const [adherents, setAdherents] = useState([]);
  const [filteredAdherents, setFilteredAdherents] = useState([]);
  const [groups, setGroups] = useState([]);
  const [groupColors, setGroupColors] = useState({}); // Stocker les couleurs des groupes
  const [selectedGroup, setSelectedGroup] = useState(""); // État pour le filtre par groupe
  const [sortOrder, setSortOrder] = useState(""); // État pour l'ordre de tri
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false); // État pour la modale de détails
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false); // État pour la modale de création
  const [isEditModalOpen, setIsEditModalOpen] = useState(false); // État pour la modale d'édition
  const [currentAdherent, setCurrentAdherent] = useState(null); // L'adhérent actuel pour la modale
  const [isDemoMode, setIsDemoMode] = useState(false); // État pour vérifier si l'utilisateur est en mode démo

  // Fonction pour vérifier le role_id dans localStorage
  const checkUserRole = () => {
    const user = JSON.parse(localStorage.getItem('user')); // Récupérer l'utilisateur depuis localStorage
    if (user && user.role_id === 4) {
      setIsDemoMode(true); // Activer le mode démo si role_id est 4
    }
  };

  // Afficher un message si en mode démo
  const handleDemoClick = () => {
    if (isDemoMode) {
      toast.info('Fonction désactivée en mode Démonstration');
      return true; // Indique que l'action est désactivée en mode démo
    }
    return false; // Permet l'action si pas en mode démo
  };

  // Récupérer les adhérents et les groupes depuis l'API
  const fetchData = async () => {
    try {
      const adherentsData = await getAllAdherent();
      setAdherents(adherentsData);
      setFilteredAdherents(adherentsData);

      const groupsData = await getGroupsHome();
      setGroups(groupsData);

      // Créer un mapping des groupes avec leurs couleurs
      const groupColorMapping = {};
      groupsData.forEach((group) => {
        groupColorMapping[group.nom] = group.color_groupe;
      });
      setGroupColors(groupColorMapping);
    } catch (error) {
      console.error("Erreur lors de la récupération des données :", error);
      toast.error("Erreur lors de la récupération des données.");
    }
  };

  useEffect(() => {
    fetchData();
    checkUserRole(); // Vérifier le rôle de l'utilisateur au chargement du composant
  }, []);

  // Fonction pour gérer le filtre par groupe
  const handleGroupFilter = (e) => {
    const group = e.target.value;
    setSelectedGroup(group);

    if (group === "") {
      setFilteredAdherents(adherents);
    } else {
      const filtered = adherents.filter(
        (adherent) => adherent.adherent_groupe === group
      );
      setFilteredAdherents(filtered);
    }
  };

  // Fonction pour gérer le tri par nom
  const handleSortByName = () => {
    let sortedAdherents;

    if (sortOrder === "asc") {
      sortedAdherents = [...filteredAdherents].sort((a, b) =>
        a.nom.localeCompare(b.nom) > 0 ? -1 : 1
      );
      setSortOrder("desc");
    } else {
      sortedAdherents = [...filteredAdherents].sort((a, b) =>
        a.nom.localeCompare(b.nom) > 0 ? 1 : -1
      );
      setSortOrder("asc");
    }

    setFilteredAdherents(sortedAdherents);
  };

  // Fonction pour gérer l'édition d'un adhérent
  const handleEdit = (adherent) => {
    if (handleDemoClick()) return; // Bloquer l'action en mode démo
    setCurrentAdherent(adherent);
    setIsEditModalOpen(true);
  };

  // Fonction pour gérer la suppression d'un adhérent
  const handleDelete = async (id) => {
    if (handleDemoClick()) return; // Bloquer l'action en mode démo
    if (window.confirm("Êtes-vous sûr de vouloir supprimer cet adhérent ?")) {
      try {
        await deleteAdherent(id);
        toast.success("Adhérent supprimé avec succès.");
        fetchData(); // Rafraîchir la liste après suppression
      } catch (error) {
        console.error("Erreur lors de la suppression de l'adhérent :", error);
        toast.error("Erreur lors de la suppression de l'adhérent.");
      }
    }
  };

  // Fonction pour ajouter un nouvel adhérent
  const handleCreate = () => {
    if (handleDemoClick()) return; // Bloquer l'action en mode démo
    setIsCreateModalOpen(true);
  };

  // Fonction pour sauvegarder les modifications d'un adhérent
  const handleSave = async (updatedAdherent) => {
    try {
      await updateAdherent(updatedAdherent.id, updatedAdherent);
      fetchData(); // Rafraîchir les données après modification
      setIsEditModalOpen(false);
      toast.success("Adhérent modifié avec succès.");
    } catch (error) {
      toast.error("Erreur lors de la modification de l'adhérent.");
    }
  };

  // Fonction pour afficher les détails dans la modale
  const handleDetails = (adherent) => {
    setCurrentAdherent(adherent);
    setIsDetailsModalOpen(true);
  };

  return (
    <div className="p-6 bg-slate-200 rounded-md shadow-md dark:bg-gray-800 dark:text-gray-200">
      <h2 className="text-2xl font-semibold mb-6 dark:text-gray-100">Gestion des Inscriptions</h2>

      {/* Filtre par groupe */}
      <div className="flex items-center mb-4 gap-4">
        <Button
          text={(
            <>
              <FaPlus className="mr-2" /> Créer un Adhérent
            </>
          )}
          onClick={handleCreate} // Appeler la fonction handleCreate qui gère le mode démo
        />

        <select
          value={selectedGroup}
          onChange={handleGroupFilter}
          className="p-2 border rounded dark:bg-gray-700 dark:border-gray-600 dark:text-gray-200"
        >
          <option value="">Tous les Groupes</option>
          {groups.map((group) => (
            <option key={group.id} value={group.nom}>
              {group.nom}
            </option>
          ))}
        </select>
      </div>

      {/* Tableau des adhérents */}
      <div className="overflow-x-auto">
        <table className="min-w-full bg-slate-100 border border-gray-300 rounded-md dark:bg-gray-800 dark:border-gray-700">
          <thead>
            <tr className="bg-gray-300 dark:bg-gray-900">
              <th className="px-4 py-2 border cursor-pointer dark:text-gray-200" onClick={handleSortByName}>
                Nom
                {sortOrder === "asc" ? (
                  <FaSortAlphaUp className="inline ml-1" />
                ) : sortOrder === "desc" ? (
                  <FaSortAlphaDown className="inline ml-1" />
                ) : null}
              </th>
              <th className="px-4 py-2 border dark:text-gray-200">Prénom</th>
              <th className="px-4 py-2 border dark:text-gray-200">Groupe</th>
              <th className="px-4 py-2 border dark:text-gray-200">Email</th>
              <th className="px-4 py-2 border dark:text-gray-200">Téléphone</th>
              <th className="px-4 py-2 border dark:text-gray-200">Actions</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 dark:divide-gray-700">
            {filteredAdherents.map((adherent) => (
              <tr key={adherent.id} className="hover:bg-gray-50 dark:hover:bg-gray-700">
                <td className="px-4 py-2 border dark:text-gray-200">{adherent.nom}</td>
                <td className="px-4 py-2 border dark:text-gray-200">{adherent.prenom}</td>
                {/* Groupe avec badge de couleur */}
                <td className="px-4 py-2 border text-center">
                  <span
                    className={`px-2 py-1 rounded-full text-xs whitespace-nowrap text-white ${getGroupColor(
                      groupColors,
                      adherent.adherent_groupe
                    )}`}
                  >
                    {adherent.adherent_groupe}
                  </span>
                </td>
                <td className="px-4 py-2 border dark:text-gray-200">{adherent.email}</td>
                <td className="px-4 py-2 border dark:text-gray-200">{adherent.tel}</td>
                <td className="px-4 py-2 border dark:text-gray-200">
                  <div className="flex justify-center items-center w-full h-full gap-2">
                    <ButtonEdit onClick={() => handleEdit(adherent)} /> {/* Bloquer l'édition en mode démo */}
                    <ButtonDetails onClick={() => handleDetails(adherent)} />
                    <ButtonDelete onClick={() => handleDelete(adherent.id)} /> {/* Bloquer la suppression en mode démo */}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Modale de détails */}
      {isDetailsModalOpen && currentAdherent && (
        <InscriptionDetailsModal
          adherent={currentAdherent}
          onClose={() => setIsDetailsModalOpen(false)}
        />
      )}

      {/* Modale de création */}
      {isCreateModalOpen && (
        <CreateAdherentModal
          onCreate={handleCreate}
          onClose={() => setIsCreateModalOpen(false)}
        />
      )}

      {/* Modale d'édition */}
      {isEditModalOpen && currentAdherent && (
        <EditAdherentModal
          adherent={currentAdherent}
          onSave={handleSave}
          onClose={() => setIsEditModalOpen(false)}
        />
      )}
      < ToastContainer />
    </div>
  );
};

export default Inscriptions;
