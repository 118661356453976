import React, { useState, useEffect } from "react";
import { PieChart, Pie, Cell, Tooltip, Legend, ResponsiveContainer } from "recharts";
import { getAdherentsCountByGroup } from "../../../../services/adherentServices";
import { getGroupsHome } from "../../../../services/groupServices";

const GroupDistributionPieChart = () => {
  const [data, setData] = useState([]);
  const [groupColors, setGroupColors] = useState({});

  // Mapping des couleurs possibles depuis Tailwind vers HEX
  const colorMapping = {
    "bg-green-400": "#34D399",
    "bg-red-400": "#F87171",
    "bg-blue-400": "#60A5FA",
    "bg-yellow-400": "#FBBF24",
    "bg-orange-400": "#FB923C",
    "bg-purple-400": "#C084FC",
    "bg-pink-400": "#F472B6",
    "bg-indigo-400": "#818CF8",
    "bg-gray-400": "#9CA3AF",
    "bg-black": "#000000",
    "bg-white": "#FFFFFF",
    "bg-transparent": "transparent",
  };

  useEffect(() => {
    // Récupérer la distribution des adhérents par groupe
    const fetchGroupDistribution = async () => {
      try {
        const response = await getAdherentsCountByGroup();
        setData(response);
      } catch (error) {
        console.error("Erreur lors de la récupération des données de distribution par groupe", error);
      }
    };

    // Récupérer les groupes avec leurs couleurs
    const fetchGroupColors = async () => {
      try {
        const groups = await getGroupsHome();
        const colors = groups.reduce((acc, group) => {
          acc[group.nom] = colorMapping[group.color_groupe] || "#8884d8"; // Utilise le mapping pour obtenir la couleur HEX
          return acc;
        }, {});
        setGroupColors(colors);
      } catch (error) {
        console.error("Erreur lors de la récupération des groupes", error);
      }
    };

    fetchGroupDistribution();
    fetchGroupColors();
  }, []);

  return (
    <div className="bg-gray-200 rounded-lg shadow-lg p-4 dark:bg-gray-800 dark:text-gray-200">
      <h3 className="text-lg font-bold text-gray-800 dark:text-gray-100 mb-4">
        Répartition des Adhérents par Groupe
      </h3>
      <ResponsiveContainer width="100%" height={400}>
        <PieChart>
          <Pie
            data={data}
            dataKey="count"
            nameKey="adherent_groupe"
            cx="50%"
            cy="50%"
            outerRadius={150}
            fill="#8884d8"
            label={({ count }) => `${count}`} // Affiche le nombre d'adhérents
          >
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={groupColors[entry.adherent_groupe] || "#8884d8"} // Applique la couleur HEX spécifique au groupe
              />
            ))}
          </Pie>
          <Tooltip
            formatter={(value) => `${value} adhérents`}
            // contentStyle={{ backgroundColor: "666", border: "none", color: "#fff" }} // Tooltip en dark mode
            cursor={{ stroke: "gray", strokeDasharray: "3 3" }}
          />
          <Legend
            formatter={(value) => `${value}`}
            // wrapperStyle={{ color: "#666", fill: "#666" }}
            className="dark:text-gray-200"
          />
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
};

export default GroupDistributionPieChart;
