import React, { useEffect, useState } from "react";
import {
  getAllPartenaires,
  addPartenaire,
  updatePartenaire,
  deletePartenaire,
} from "../../../../services/partenairesServices"; // Import des services pour les partenaires
import Button from "../../../../components/Button/Button"; // Composant Button pour créer un nouveau partenaire
import ButtonEdit from "../../../../components/Button/ButtonEdit"; // Composant pour éditer
import ButtonDelete from "../../../../components/Button/ButtonDelete"; // Composant pour supprimer
import EditPartenairesModale from "./EditPartenairesModale"; // Modale pour l'édition d'un partenaire
import CreatePartenairesModale from "./CreatePartenairesModale"; // Modale pour la création d'un partenaire
import { FaPlus } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";

const Partenaires = () => {
  const [partenaires, setPartenaires] = useState([]);
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [isCreateModalOpen, setCreateModalOpen] = useState(false);
  const [currentPartenaire, setCurrentPartenaire] = useState(null); // Partenaire en cours d'édition
  const [isDemoMode, setIsDemoMode] = useState(false); // État pour vérifier si l'utilisateur est en mode démo

  // Fonction pour vérifier le role_id dans localStorage
  const checkUserRole = () => {
    const user = JSON.parse(localStorage.getItem("user")); // Récupérer l'utilisateur depuis localStorage
    if (user && user.role_id === 4) {
      setIsDemoMode(true); // Activer le mode démo si role_id est 4
    }
  };

  useEffect(() => {
    fetchPartenaires();
    checkUserRole(); // Vérifier le rôle de l'utilisateur au chargement du composant
  }, []);

  // Afficher un message si en mode démo
  const handleDemoClick = () => {
    if (isDemoMode) {
      toast.info("Fonction désactivée en mode Démonstration");
      return true; // Indique que l'action est désactivée en mode démo
    }
    return false; // Permet l'action si pas en mode démo
  };

  // Fonction pour récupérer et rafraîchir la liste des partenaires
  const fetchPartenaires = async () => {
    try {
      const data = await getAllPartenaires();
      setPartenaires(data);
    } catch (error) {
      console.error("Erreur lors de la récupération des partenaires :", error);
      toast.error("Erreur lors de la récupération des partenaires.");
    }
  };

  // Fonction pour gérer la création d'un nouveau partenaire
  const handleCreate = async (newPartenaire) => {
    if (handleDemoClick()) return; // Bloquer l'action en mode démo
    try {
      await addPartenaire(newPartenaire);
      toast.success("Partenaire créé avec succès.");
      fetchPartenaires(); // Rafraîchir la liste après la création
    } catch (error) {
      console.error("Erreur lors de la création du partenaire :", error);
      toast.error("Erreur lors de la création du partenaire.");
    }
  };

  // Fonction pour gérer l'édition d'un partenaire
  const handleEdit = (partenaire) => {
    if (handleDemoClick()) return; // Bloquer l'action en mode démo
    setCurrentPartenaire(partenaire); // Stocker le partenaire sélectionné
    setEditModalOpen(true); // Ouvrir la modale d'édition
  };

  // Fonction pour sauvegarder les modifications
  const handleSave = async (updatedPartenaire) => {
    try {
      await updatePartenaire(updatedPartenaire);
      toast.success("Partenaire modifié avec succès.");
      fetchPartenaires(); // Rafraîchir la liste après l'édition
    } catch (error) {
      console.error("Erreur lors de la modification du partenaire :", error);
      toast.error("Erreur lors de la modification du partenaire.");
    }
    setEditModalOpen(false); // Fermer la modale d'édition
  };

  // Fonction pour gérer la suppression d'un partenaire
  const handleDelete = async (id) => {
    if (handleDemoClick()) return; // Bloquer l'action en mode démo
    if (window.confirm("Êtes-vous sûr de vouloir supprimer ce partenaire ?")) {
      try {
        await deletePartenaire(id);
        toast.success("Partenaire supprimé avec succès.");
        fetchPartenaires(); // Rafraîchir la liste après suppression
      } catch (error) {
        console.error("Erreur lors de la suppression du partenaire :", error);
        toast.error("Erreur lors de la suppression du partenaire.");
      }
    }
  };

  return (
    <div className="p-6 bg-white dark:bg-gray-800 rounded-md shadow-md">
      <h2 className="text-2xl font-semibold mb-6 dark:text-gray-100">Gestion des Partenaires</h2>

      {/* Bouton pour créer un nouveau partenaire */}
      <Button
        text={(
          <>
            <FaPlus className="mr-2" /> Créer un partenaire
          </>
        )}
        onClick={() => {
          if (!handleDemoClick()) setCreateModalOpen(true);
        }} // Bloquer la création en mode démo
        className="mb-4"
      />

      {/* Tableau des partenaires */}
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-md">
          <thead>
            <tr className="bg-gray-100 dark:bg-gray-900">
              <th className="px-4 py-2 border dark:text-gray-200">Logo</th>
              <th className="px-4 py-2 border dark:text-gray-200">Nom</th>
              <th className="px-4 py-2 border dark:text-gray-200">Lien</th>
              <th className="px-4 py-2 border dark:text-gray-200">Ordre</th>
              <th className="px-4 py-2 border dark:text-gray-200">Statut</th>
              <th className="px-4 py-2 border dark:text-gray-200">Actions</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 dark:divide-gray-600">
            {partenaires.map((partenaire) => (
              <tr
                key={partenaire.id}
                className={`${
                  partenaire.is_active ? "bg-white dark:bg-gray-800" : "bg-gray-200 dark:bg-gray-700"
                } hover:bg-gray-50 dark:hover:bg-gray-700`}
              >
                <td className="px-4 py-2 border text-center">
                  <img
                    src={partenaire.photo_url}
                    alt={partenaire.nom}
                    className="h-24 w-24 object-contain rounded"
                  />
                </td>
                <td className="px-4 py-2 border dark:text-gray-200">{partenaire.nom}</td>
                <td className="px-4 py-2 border dark:text-gray-200">
                  <a
                    href={partenaire.link_url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-600 hover:underline dark:text-blue-400"
                  >
                    {partenaire.link_url}
                  </a>
                </td>
                <td className="px-4 py-2 border text-center dark:text-gray-200">
                  {partenaire.ordre}
                </td>
                <td className="px-4 py-2 border text-center dark:text-gray-200">
                  {partenaire.is_active ? "Actif" : "Inactif"}
                </td>
                <td className="px-4 py-2 border dark:text-gray-200">
                  <div className="flex justify-center items-center w-full h-full gap-2">
                    <ButtonEdit onClick={() => handleEdit(partenaire)} /> {/* Bloquer l'édition en mode démo */}
                    <ButtonDelete onClick={() => handleDelete(partenaire.id)} /> {/* Bloquer la suppression en mode démo */}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Modal d'édition */}
      {isEditModalOpen && currentPartenaire && (
        <EditPartenairesModale
          partenaire={currentPartenaire}
          onClose={() => setEditModalOpen(false)}
          onSave={handleSave}
        />
      )}

      {/* Modal de création */}
      {isCreateModalOpen && (
        <CreatePartenairesModale
          onCreate={(newPartenaire) => {
            handleCreate(newPartenaire);
            setCreateModalOpen(false);
          }}
          onClose={() => setCreateModalOpen(false)}
        />
      )}

      <ToastContainer />
    </div>
  );
};

export default Partenaires;
