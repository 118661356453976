import React, { useEffect, useState } from "react";
import { getGroupsHome, deleteGroup } from "../../../../services/groupServices"; // Import des services pour les groupes
import Button from "../../../../components/Button/Button"; // Composant Button pour créer un nouveau groupe
import ButtonEdit from "../../../../components/Button/ButtonEdit"; // Composant pour éditer
import ButtonDelete from "../../../../components/Button/ButtonDelete"; // Composant pour supprimer
import CreateGroup from "./CreateGroup"; // Import de la modale de création
import EditGroup from "./EditGroup"; // Import de la modale d'édition
import { FaPlus } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";

const Groupes = () => {
  const [groupes, setGroupes] = useState([]);
  const [isCreateModalOpen, setCreateModalOpen] = useState(false);
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [currentGroup, setCurrentGroup] = useState(null); // Stocke le groupe sélectionné pour l'édition
  const [isDemoMode, setIsDemoMode] = useState(false); // État pour vérifier si l'utilisateur est en mode démo

  // Fonction pour vérifier le role_id dans localStorage
  const checkUserRole = () => {
    const user = JSON.parse(localStorage.getItem("user")); // Récupérer l'utilisateur depuis localStorage
    if (user && user.role_id === 4) {
      setIsDemoMode(true); // Activer le mode démo si role_id est 4
    }
  };

  useEffect(() => {
    fetchGroupes();
    checkUserRole(); // Vérifier le rôle de l'utilisateur au chargement du composant
  }, []);

  // Afficher un message si en mode démo
  const handleDemoClick = () => {
    if (isDemoMode) {
      toast.info("Fonction désactivée en mode Démonstration");
      return true; // Indique que l'action est désactivée en mode démo
    }
    return false; // Permet l'action si pas en mode démo
  };

  // Récupérer les groupes depuis l'API
  const fetchGroupes = async () => {
    try {
      const data = await getGroupsHome();
      setGroupes(data);
    } catch (error) {
      console.error("Erreur lors de la récupération des groupes :", error);
      toast.error("Erreur lors de la récupération des groupes.");
    }
  };

  // Fonction pour gérer l'édition d'un groupe
  const handleEdit = (groupe) => {
    if (handleDemoClick()) return; // Bloquer l'action en mode démo
    setCurrentGroup(groupe); // Stocker le groupe sélectionné
    setEditModalOpen(true); // Ouvrir la modale d'édition
  };

  // Fonction pour gérer la suppression d'un groupe
  const handleDelete = async (id) => {
    if (handleDemoClick()) return; // Bloquer l'action en mode démo
    if (window.confirm("Êtes-vous sûr de vouloir supprimer ce groupe ?")) {
      try {
        await deleteGroup(id);
        toast.success("Groupe supprimé avec succès.");
        fetchGroupes(); // Rafraîchir la liste après suppression
      } catch (error) {
        console.error("Erreur lors de la suppression du groupe :", error);
        toast.error("Erreur lors de la suppression du groupe.");
      }
    }
  };

  return (
    <div className="p-6 bg-white dark:bg-gray-800 rounded-md shadow-md dark:text-gray-200">
      <h2 className="text-2xl font-semibold mb-6 dark:text-gray-100">Gestion des Groupes</h2>

      {/* Bouton pour créer un nouveau groupe */}
      <Button
        text={(
          <>
            <FaPlus className="mr-2" /> Créer un groupe
          </>
        )}
        onClick={() => {
          if (!handleDemoClick()) setCreateModalOpen(true);
        }} // Bloquer la création en mode démo
        className="mb-4"
      />

      {/* Tableau des groupes */}
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-md">
          <thead>
            <tr className="bg-gray-100 dark:bg-gray-900">
              <th className="px-4 py-2 border dark:border-gray-600 dark:text-gray-200">Nom</th>
              <th className="px-4 py-2 border dark:border-gray-600 dark:text-gray-200">Horaires</th>
              <th className="px-4 py-2 border dark:border-gray-600 dark:text-gray-200">Années de Naissance</th>
              <th className="px-4 py-2 border dark:border-gray-600 dark:text-gray-200">Tarif Licence (€)</th>
              <th className="px-4 py-2 border dark:border-gray-600 dark:text-gray-200">Tarif Cotisation (€)</th>
              <th className="px-4 py-2 border dark:border-gray-600 dark:text-gray-200">Enseignants</th>
              <th className="px-4 py-2 border dark:border-gray-600 dark:text-gray-200">Statut</th>
              <th className="px-4 py-2 border dark:border-gray-600 dark:text-gray-200">Ordre</th>
              <th className="px-4 py-2 border dark:border-gray-600 dark:text-gray-200">Quota</th>
              <th className="px-4 py-2 border dark:border-gray-600 dark:text-gray-200">Actions</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 dark:divide-gray-600">
            {groupes.map((groupe) => (
              <tr
                key={groupe.id}
                className={`${
                  groupe.is_active ? "bg-white dark:bg-gray-700" : "bg-gray-200 dark:bg-gray-800"
                } hover:bg-gray-50 dark:hover:bg-gray-700`}
              >
                <td className={`px-4 py-2 border dark:border-gray-600 ${groupe.color_groupe} dark:text-gray-800`}>
                  {groupe.nom}
                </td>
                <td className="px-4 py-2 border dark:border-gray-600 dark:text-gray-200">
                  {groupe.horaire1} <br /> {groupe.horaire2}
                </td>
                <td className="px-4 py-2 border dark:border-gray-600 dark:text-gray-200">{groupe.annees_naissance}</td>
                <td className="px-4 py-2 border text-center dark:border-gray-600 dark:text-gray-200">
                  {groupe.tarif_licence}
                </td>
                <td className="px-4 py-2 border text-center dark:border-gray-600 dark:text-gray-200">
                  {groupe.tarif_cotisation}
                </td>
                <td className="px-4 py-2 border dark:border-gray-600 dark:text-gray-200">
                  {groupe.enseignant1}
                  {groupe.enseignant2 && `, ${groupe.enseignant2}`}
                </td>
                <td className="px-4 py-2 border text-center dark:border-gray-600 dark:text-gray-200">
                  {groupe.is_active ? "Actif" : "Inactif"}
                </td>
                <td className="px-4 py-2 border text-center dark:border-gray-600 dark:text-gray-200">
                  {groupe.ordre_groupe}
                </td>
                <td className="px-4 py-2 border text-center dark:border-gray-600 dark:text-gray-200">
                  {groupe.quota_groupe}
                </td>
                <td className="px-4 py-2 border-none dark:text-gray-200">
                  <div className="flex justify-center items-center w-full h-full gap-2">
                    <ButtonEdit onClick={() => handleEdit(groupe)} /> {/* Bloquer l'édition en mode démo */}
                    <ButtonDelete onClick={() => handleDelete(groupe.id)} /> {/* Bloquer la suppression en mode démo */}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Modale de création de groupe */}
      {isCreateModalOpen && (
        <CreateGroup
          isActive={isCreateModalOpen}
          onClose={() => setCreateModalOpen(false)} // Fermer la modale
          refreshGroups={fetchGroupes} // Rafraîchir les groupes après la création
        />
      )}

      {/* Modale d'édition de groupe */}
      {isEditModalOpen && currentGroup && (
        <EditGroup
          group={currentGroup} // Passer le groupe sélectionné à la modale
          onClose={() => setEditModalOpen(false)} // Fermer la modale après édition
          refreshGroups={fetchGroupes} // Rafraîchir les groupes après l'édition
        />
      )}

      <ToastContainer />
    </div>
  );
};

export default Groupes;
