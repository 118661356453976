import React from "react";
import MessagesSection from "./MessagesSection"; // Section pour les messages du formulaire de contact
import RecentAdherents from "./RecentAdherents"; // Section pour les 10 derniers adhérents inscrits
import VisitsChart from "./VisitsChart"; // Graphique des visites du site
import AdherentsPieChart from "./AdherentsPieChart.jsx"; // Graphique de répartition des adhérents
import GenderDistributionPieChart from "./GenderDistributionPieChart.jsx";
import NotificationsDisplay from "./NotificationsDisplay";
import { ToastContainer } from "react-toastify";

const Dashboard = () => {
  return (
    <div className="p-4 bg-white dark:bg-gray-700 rounded-md shadow-md grid grid-cols-1 lg:grid-cols-3 gap-6">
      {/* Colonne de droite : Messages et Notifications en mode desktop */}
      <div className="lg:col-span-1 space-y-6">
        {/* Messages du formulaire de contact */}
        <MessagesSection />
        {/* Notifications reçues */}
        <NotificationsDisplay />
      </div>

      {/* Colonne de gauche : RecentAdherents et graphiques en mode desktop */}
      <div className="lg:col-span-2 space-y-6">
        {/* Derniers adhérents inscrits */}
        <RecentAdherents />
        {/* Graphique des visites */}
        <VisitsChart />
        {/* Graphique de répartition des adhérents */}
        <AdherentsPieChart />
        {/* Graphique de répartition des adhérents par genre */}
        <GenderDistributionPieChart />
      </div>

      <ToastContainer />
    </div>
  );
};

export default Dashboard;
