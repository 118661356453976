import React, { useEffect, useState } from "react";
import {
  getAllDisciplines,
  addDiscipline,
  updateDiscipline,
  deleteDiscipline,
} from "../../../../services/disciplinesServices"; // Import des services pour les disciplines
import Button from "../../../../components/Button/Button"; // Composant Button pour créer une nouvelle discipline
import ButtonEdit from "../../../../components/Button/ButtonEdit"; // Composant pour éditer
import ButtonDelete from "../../../../components/Button/ButtonDelete"; // Composant pour supprimer
import EditDisciplineModale from "./EditDisciplineModale"; // Modale pour l'édition d'une discipline
import CreateDisciplineModale from "./CreateDisciplineModale"; // Modale pour la création d'une discipline
import { FaPlus } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";

const Disciplines = () => {
  const [disciplines, setDisciplines] = useState([]);
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [isCreateModalOpen, setCreateModalOpen] = useState(false);
  const [currentDiscipline, setCurrentDiscipline] = useState(null); // Discipline en cours d'édition
  const [isDemoMode, setIsDemoMode] = useState(false); // État pour vérifier si l'utilisateur est en mode démo

  // Fonction pour vérifier le role_id dans localStorage
  const checkUserRole = () => {
    const user = JSON.parse(localStorage.getItem("user")); // Récupérer l'utilisateur depuis localStorage
    if (user && user.role_id === 4) {
      setIsDemoMode(true); // Activer le mode démo si role_id est 4
    }
  };

  // Afficher un message si en mode démo
  const handleDemoClick = () => {
    if (isDemoMode) {
      toast.info("Fonction désactivée en mode Démonstration");
      return true; // Indique que l'action est désactivée en mode démo
    }
    return false; // Permet l'action si pas en mode démo
  };

  // Fonction pour récupérer et rafraîchir la liste des disciplines
  const fetchDisciplines = async () => {
    try {
      const data = await getAllDisciplines();
      setDisciplines(data);
    } catch (error) {
      console.error("Erreur lors de la récupération des disciplines :", error);
      toast.error("Erreur lors de la récupération des disciplines.");
    }
  };

  useEffect(() => {
    fetchDisciplines();
    checkUserRole(); // Vérifier le rôle de l'utilisateur au chargement du composant
  }, []);

  // Fonction pour gérer la création d'une nouvelle discipline
  const handleCreate = async (newDiscipline) => {
    if (handleDemoClick()) return; // Bloquer l'action en mode démo
    try {
      await addDiscipline(newDiscipline);
      toast.success("Discipline créée avec succès.");
      fetchDisciplines(); // Rafraîchir la liste après la création
    } catch (error) {
      console.error("Erreur lors de la création de la discipline :", error);
      toast.error("Erreur lors de la création de la discipline.");
    }
  };

  // Fonction pour gérer l'édition d'une discipline
  const handleEdit = (discipline) => {
    if (handleDemoClick()) return; // Bloquer l'action en mode démo
    setCurrentDiscipline(discipline); // Stocker la discipline sélectionnée
    setEditModalOpen(true); // Ouvrir la modale d'édition
  };

  // Fonction pour sauvegarder les modifications
  const handleSave = async (updatedDiscipline) => {
    try {
      await updateDiscipline(updatedDiscipline);
      toast.success("Discipline modifiée avec succès.");
      fetchDisciplines(); // Rafraîchir la liste après l'édition
    } catch (error) {
      console.error("Erreur lors de la modification de la discipline :", error);
      toast.error("Erreur lors de la modification de la discipline.");
    }
    setEditModalOpen(false); // Fermer la modale d'édition
  };

  // Fonction pour gérer la suppression d'une discipline
  const handleDelete = async (id) => {
    if (handleDemoClick()) return; // Bloquer l'action en mode démo
    if (
      window.confirm("Êtes-vous sûr de vouloir supprimer cette discipline ?")
    ) {
      try {
        await deleteDiscipline(id);
        toast.success("Discipline supprimée avec succès.");
        fetchDisciplines(); // Rafraîchir la liste après suppression
      } catch (error) {
        console.error(
          "Erreur lors de la suppression de la discipline :",
          error
        );
        toast.error("Erreur lors de la suppression de la discipline.");
      }
    }
  };

  return (
    <div className="p-6 bg-white dark:bg-gray-800 dark:text-gray-200 rounded-md shadow-md">
      <h2 className="text-2xl font-semibold mb-6 dark:text-gray-100">
        Gestion des Disciplines
      </h2>

      {/* Bouton pour créer une nouvelle discipline */}
      <Button
        text={
          <>
            <FaPlus className="mr-2" /> Créer une discipline
          </>
        }
        onClick={() => {
          if (!handleDemoClick()) setCreateModalOpen(true);
        }} // Bloquer la création en mode démo
        className="mb-4"
      />

      {/* Tableau des disciplines */}
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-md">
          <thead>
            <tr className="bg-gray-100 dark:bg-gray-900">
              <th className="px-4 py-2 border dark:text-gray-200">Image</th>
              <th className="px-4 py-2 border dark:text-gray-200">Nom</th>
              <th className="px-4 py-2 border dark:text-gray-200">
                Description
              </th>
              <th className="px-4 py-2 border dark:text-gray-200">Ordre</th>
              <th className="px-4 py-2 border dark:text-gray-200">Actions</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 dark:divide-gray-600">
            {disciplines.map((discipline) => (
              <tr
                key={discipline.id}
                className="bg-white dark:bg-gray-800 hover:bg-gray-50 dark:hover:bg-gray-700"
              >
                <td className="px-4 py-2 text-center border">
                  <img
                    src={discipline.image_discipline}
                    alt={discipline.nom_discipline}
                    className="h-24 w-24 object-cover rounded-md"
                  />
                </td>
                <td className="px-4 py-2 border dark:text-gray-200">
                  {discipline.nom_discipline}
                </td>
                <td className="px-4 py-2 border dark:text-gray-200">
                  {discipline.description_discipline}
                </td>
                <td className="px-4 py-2 border text-center dark:text-gray-200">
                  {discipline.ordre_discipline}
                </td>
                <td className="px-4 py-2 border dark:text-gray-200">
                  <div className="flex justify-center items-center w-full h-full gap-2">
                    <ButtonEdit onClick={() => handleEdit(discipline)} /> {/* Bloquer l'édition en mode démo */}
                    <ButtonDelete onClick={() => handleDelete(discipline.id)} /> {/* Bloquer la suppression en mode démo */}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Modal d'édition */}
      {isEditModalOpen && currentDiscipline && (
        <EditDisciplineModale
          discipline={currentDiscipline}
          onClose={() => setEditModalOpen(false)}
          onSave={handleSave}
        />
      )}

      {/* Modal de création */}
      {isCreateModalOpen && (
        <CreateDisciplineModale
          onCreate={(newDiscipline) => {
            handleCreate(newDiscipline);
            setCreateModalOpen(false);
          }}
          onClose={() => setCreateModalOpen(false)}
        />
      )}

      <ToastContainer />
    </div>
  );
};

export default Disciplines;
