import React, { useState, useEffect } from "react";
import Button from "../../../../components/Button/Button";
import { getGroupsHome } from "../../../../services/groupServices";
import { ToastContainer, toast } from "react-toastify";
import { FaSave, FaTimes } from "react-icons/fa"; // Icônes
import "react-toastify/dist/ReactToastify.css";

const CreateAdherentModal = ({ onCreate, onClose }) => {
  const [formData, setFormData] = useState({
    adherent_groupe: "",
    nom: "",
    prenom: "",
    email: "",
    email_secondaire: "",
    tel: "",
    tel_secondaire: "",
    adresse: "",
    cp: "",
    commune: "",
    genre: "M",
    date_naissance: "",
    poids: "",
    licence_number: "",
    autorisation_photo: true,
    notes_adherent: "",
  });
  const [groups, setGroups] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // Récupérer les groupes pour le champ adherent_groupe
  useEffect(() => {
    const fetchGroups = async () => {
      try {
        const groupsData = await getGroupsHome();
        setGroups(groupsData);
      } catch (error) {
        console.error("Erreur lors de la récupération des groupes", error);
      }
    };
    fetchGroups();
  }, []);

  // Gestion des changements dans le formulaire
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  // Fonction de validation des champs du formulaire
  const validateForm = () => {
    const {
      adherent_groupe,
      nom,
      prenom,
      email,
      tel,
      adresse,
      cp,
      commune,
      genre,
      date_naissance,
    } = formData;

    if (
      !adherent_groupe ||
      !nom ||
      !prenom ||
      !email ||
      !tel ||
      !adresse ||
      !cp ||
      !commune ||
      !genre ||
      !date_naissance
    ) {
      toast.error("Veuillez remplir tous les champs obligatoires.");
      return false;
    }

    // Vérification de l'email
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(email)) {
      toast.error("Veuillez entrer un e-mail valide.");
      return false;
    }

    // Vérification de la date de naissance
    const dateOfBirth = new Date(date_naissance);
    const today = new Date();
    const minDate = new Date(1900, 0, 1);
    if (dateOfBirth >= today || dateOfBirth < minDate || isNaN(dateOfBirth)) {
      toast.error("Veuillez entrer une date de naissance valide.");
      return false;
    }

    // Vérification du code postal
    const cpPattern = /^[0-9]{5}$/;
    if (!cpPattern.test(cp)) {
      toast.error("Veuillez entrer un code postal valide.");
      return false;
    }

    // Vérification du format du téléphone
    const telPattern = /^(\+?\d{1,3}[- ]?)?\d{10}$/;
    if (!telPattern.test(tel)) {
      toast.error("Veuillez entrer un numéro de téléphone valide.");
      return false;
    }

    return true;
  };

  // Fonction pour gérer la soumission du formulaire
  const handleSubmit = async (e) => {
    e.preventDefault(); // Empêche la soumission par défaut

    if (!validateForm()) return; // Validation du formulaire

    setIsLoading(true); // Activer l'état de chargement

    try {
      await onCreate(formData); // Appeler la fonction onCreate pour créer l'adhérent
      toast.success("Adhérent ajouté avec succès.");
      onClose(); // Fermer la modale après succès
    } catch (error) {
      console.error("Erreur lors de l'ajout de l'adhérent:", error);
      toast.error("Erreur lors de l'ajout de l'adhérent.");
    } finally {
      setIsLoading(false); // Désactiver l'état de chargement
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-30 flex justify-center items-center z-50">
      <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg w-full max-w-2xl max-h-[90vh] overflow-y-auto">
        <div className="bg-primary text-white p-4 rounded-t-lg">
          <h2 className="text-xl font-semibold">Créer un Adhérent</h2>
        </div>
        <div className="p-6 dark:bg-gray-900 dark:text-gray-200">
          <form onSubmit={handleSubmit}>
            {/* Groupe */}
            <div className="mb-4">
              <label className="block font-semibold">Groupe</label>
              <select
                name="adherent_groupe"
                value={formData.adherent_groupe}
                onChange={handleChange}
                className="w-full p-2 border rounded dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                required
              >
                <option value="">Sélectionnez un groupe</option>
                {groups.map((group) => (
                  <option key={group.id} value={group.nom}>
                    {group.nom}
                  </option>
                ))}
              </select>
            </div>

            {/* Nom */}
            <div className="mb-4">
              <label className="block font-semibold">Nom</label>
              <input
                type="text"
                name="nom"
                value={formData.nom}
                onChange={handleChange}
                className="w-full p-2 border rounded dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                required
              />
            </div>

            {/* Prénom */}
            <div className="mb-4">
              <label className="block font-semibold">Prénom</label>
              <input
                type="text"
                name="prenom"
                value={formData.prenom}
                onChange={handleChange}
                className="w-full p-2 border rounded dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                required
              />
            </div>

            {/* Email */}
            <div className="mb-4">
              <label className="block font-semibold">Email</label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className="w-full p-2 border rounded dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                required
              />
            </div>

            {/* Email secondaire */}
            <div className="mb-4">
              <label className="block font-semibold">Email secondaire</label>
              <input
                type="email"
                name="email_secondaire"
                value={formData.email_secondaire}
                onChange={handleChange}
                className="w-full p-2 border rounded dark:bg-gray-700 dark:border-gray-600 dark:text-white"
              />
            </div>

            {/* Téléphone */}
            <div className="mb-4">
              <label className="block font-semibold">Téléphone</label>
              <input
                type="tel"
                name="tel"
                value={formData.tel}
                onChange={handleChange}
                className="w-full p-2 border rounded dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                required
              />
            </div>

            {/* Téléphone secondaire */}
            <div className="mb-4">
              <label className="block font-semibold">
                Téléphone secondaire
              </label>
              <input
                type="tel"
                name="tel_secondaire"
                value={formData.tel_secondaire}
                onChange={handleChange}
                className="w-full p-2 border rounded dark:bg-gray-700 dark:border-gray-600 dark:text-white"
              />
            </div>

            {/* Adresse */}
            <div className="mb-4">
              <label className="block font-semibold">Adresse</label>
              <input
                type="text"
                name="adresse"
                value={formData.adresse}
                onChange={handleChange}
                className="w-full p-2 border rounded dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                required
              />
            </div>

            {/* Code postal */}
            <div className="mb-4">
              <label className="block font-semibold">Code postal</label>
              <input
                type="text"
                name="cp"
                value={formData.cp}
                onChange={handleChange}
                className="w-full p-2 border rounded dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                required
              />
            </div>

            {/* Commune */}
            <div className="mb-4">
              <label className="block font-semibold">Commune</label>
              <input
                type="text"
                name="commune"
                value={formData.commune}
                onChange={handleChange}
                className="w-full p-2 border rounded dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                required
              />
            </div>

            {/* Genre */}
            <div className="mb-4">
              <label className="block font-semibold">Genre</label>
              <div className="flex items-center gap-4">
                <label>
                  <input
                    type="radio"
                    name="genre"
                    value="M"
                    checked={formData.genre === "M"}
                    onChange={handleChange}
                    className="mr-2"
                  />
                  Masculin
                </label>
                <label>
                  <input
                    type="radio"
                    name="genre"
                    value="F"
                    checked={formData.genre === "F"}
                    onChange={handleChange}
                    className="mr-2"
                  />
                  Féminin
                </label>
              </div>
            </div>

            {/* Date de naissance */}
            <div className="mb-4">
              <label className="block font-semibold">Date de naissance</label>
              <input
                type="date"
                name="date_naissance"
                value={formData.date_naissance}
                onChange={handleChange}
                className="w-full p-2 border rounded dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                required
              />
            </div>

            {/* Poids */}
            <div className="mb-4">
              <label className="block font-semibold">Poids (kg)</label>
              <input
                type="number"
                name="poids"
                value={formData.poids}
                onChange={handleChange}
                className="w-full p-2 border rounded dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                step="0.1"
              />
            </div>

            {/* Numéro de Licence */}
            <div className="mb-4">
              <label className="block font-semibold">
                Numéro de Licence (laissez vide si débutant)
              </label>
              <input
                type="text"
                name="licence_number"
                value={formData.licence_number}
                onChange={handleChange}
                className="w-full p-2 border rounded dark:bg-gray-700 dark:border-gray-600 dark:text-white"
              />
            </div>

            {/* Autorisation Photo */}
            <div className="mb-4">
              <label className="block font-semibold">
                Autorisation prise de photo
              </label>
              <input
                type="checkbox"
                name="autorisation_photo"
                checked={formData.autorisation_photo}
                onChange={handleChange}
                className="mr-2"
              />
            </div>

            {/* Notes */}
            <div className="mb-4">
              <label className="block font-semibold">Notes</label>
              <textarea
                name="notes_adherent"
                value={formData.notes_adherent}
                onChange={handleChange}
                className="w-full p-2 border rounded dark:bg-gray-700 dark:border-gray-600 dark:text-white"
              />
            </div>

            {/* Boutons d'action */}
            <div className="flex justify-end gap-2">
              <Button
                text="Annuler"
                onClick={onClose}
                disabled={isLoading}
                icon={FaTimes}
                className="gap-2"
              />
              <Button
                text="Créer"
                type="submit"
                disabled={isLoading}
                icon={FaSave}
                className="gap-2"
              />
            </div>
          </form>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default CreateAdherentModal;
