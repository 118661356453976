import React, { useState } from "react";
import Button from "../../../../components/Button/Button";
import { FaSave, FaTimes } from "react-icons/fa";

const EditTeamMemberModal = ({ member, onSave, onClose }) => {
  const [formData, setFormData] = useState({ ...member });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSave(formData);
    onClose();
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg w-full max-w-2xl">
        <div className="bg-primary text-white p-4 rounded-t-lg">
          <h2 className="text-xl font-semibold dark:text-gray-100">Modifier le Membre du Club</h2>
        </div>
        <div className="p-6">
          <div className="mb-4">
            <label className="block font-semibold mb-2 dark:text-gray-200">Nom</label>
            <input
              type="text"
              name="nom"
              value={formData.nom}
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 dark:border-gray-600 rounded-md dark:bg-gray-700 dark:text-gray-200"
              placeholder="Nom"
            />
          </div>
          <div className="mb-4">
            <label className="block font-semibold mb-2 dark:text-gray-200">Prénom</label>
            <input
              type="text"
              name="prenom"
              value={formData.prenom}
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 dark:border-gray-600 rounded-md dark:bg-gray-700 dark:text-gray-200"
              placeholder="Prénom"
            />
          </div>
          <div className="mb-4">
            <label className="block font-semibold mb-2 dark:text-gray-200">Fonction</label>
            <input
              type="text"
              name="fonction"
              value={formData.fonction}
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 dark:border-gray-600 rounded-md dark:bg-gray-700 dark:text-gray-200"
              placeholder="Fonction"
            />
          </div>
          <div className="mb-4">
            <label className="block font-semibold mb-2 dark:text-gray-200">Catégorie</label>
            <select
              name="categorie"
              value={formData.categorie}
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 dark:border-gray-600 rounded-md dark:bg-gray-700 dark:text-gray-200"
            >
              <option value="">Choisissez une catégorie</option>
              <option value="Bureau">Bureau</option>
              <option value="Enseignant">Enseignant</option>
              <option value="Demo">Démonstration</option>
            </select>
          </div>
          <div className="mb-4">
            <label className="block font-semibold mb-2 dark:text-gray-200">URL de la photo</label>
            <input
              type="text"
              name="photoURL"
              value={formData.photoURL}
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 dark:border-gray-600 rounded-md dark:bg-gray-700 dark:text-gray-200"
              placeholder="URL de la photo"
            />
          </div>
          <div className="mb-4">
            <label className="block font-semibold mb-2 dark:text-gray-200">Position</label>
            <input
              type="number"
              name="position"
              value={formData.position}
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 dark:border-gray-600 rounded-md dark:bg-gray-700 dark:text-gray-200"
              placeholder="Position"
            />
          </div>
          <div className="flex justify-end gap-4">
            <Button text="Annuler" onClick={onClose} icon={FaTimes} className="gap-2" />
            <Button text="Enregistrer" icon={FaSave} type="submit" className="gap-2" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditTeamMemberModal;
