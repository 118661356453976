import React, { useEffect, useState } from "react";
import { getNews, deleteNews } from "../../../../services/newsServices"; // Import de deleteNews
import Button from "../../../../components/Button/Button";
import ButtonEdit from "../../../../components/Button/ButtonEdit";
import ButtonDelete from "../../../../components/Button/ButtonDelete";
import EditNewsModale from "./EditNewsModale"; // Import de la modale d'édition spécifique
import CreateNewsModale from "./CreateNewsModale"; // Import de la modale de création spécifique
import { FaPlus } from "react-icons/fa"; // Import de l'icône FaPlus pour le bouton
import { ToastContainer, toast } from "react-toastify";

const Actualites = () => {
  const [news, setNews] = useState([]);
  const [isEditModalOpen, setEditModalOpen] = useState(false); // État pour l'ouverture de la modale d'édition
  const [isCreateModalOpen, setCreateModalOpen] = useState(false); // État pour l'ouverture de la modale de création
  const [currentNews, setCurrentNews] = useState(null); // Actualité sélectionnée pour l'édition
  const [isDemoMode, setIsDemoMode] = useState(false); // État pour vérifier si l'utilisateur est en mode démo

  // Fonction pour vérifier le role_id dans localStorage
  const checkUserRole = () => {
    const user = JSON.parse(localStorage.getItem("user")); // Récupérer l'utilisateur depuis localStorage
    if (user && user.role_id === 4) {
      setIsDemoMode(true); // Activer le mode démo si role_id est 4
    }
  };

  // Afficher un message si en mode démo
  const handleDemoClick = () => {
    if (isDemoMode) {
      toast.info("Fonction désactivée en mode Démonstration");
      return true; // Indique que l'action est désactivée en mode démo
    }
    return false; // Permet l'action si pas en mode démo
  };

  // Fonction pour récupérer les actualités
  const fetchNews = async () => {
    try {
      const data = await getNews(); // Appel au service pour récupérer les actualités
      setNews(data);
    } catch (error) {
      console.error("Erreur lors de la récupération des actualités :", error);
      toast.error("Erreur lors de la récupération des actualités.");
    }
  };

  // Récupérer les actualités depuis l'API au montage du composant
  useEffect(() => {
    fetchNews();
    checkUserRole(); // Vérifier le rôle de l'utilisateur au chargement du composant
  }, []);

  // Fonction pour gérer l'édition d'une actualité
  const handleEdit = (article) => {
    if (handleDemoClick()) return; // Bloquer l'action en mode démo
    setCurrentNews(article); // Stocker l'article sélectionné
    setEditModalOpen(true); // Ouvrir la modale d'édition
  };

  // Fonction pour gérer la suppression d'une actualité
  const handleDelete = async (id) => {
    if (handleDemoClick()) return; // Bloquer l'action en mode démo
    if (window.confirm("Êtes-vous sûr de vouloir supprimer cette actualité ?")) {
      try {
        await deleteNews(id); // Appel à la fonction de suppression de l'API
        fetchNews(); // Rafraîchir les données après suppression
        toast.success("Actualité supprimée avec succès.");
      } catch (error) {
        console.error("Erreur lors de la suppression de l'actualité :", error);
        toast.error("Erreur lors de la suppression de l'actualité.");
      }
    }
  };

  const handleCreate = (newArticle) => {
    if (handleDemoClick()) return; // Bloquer l'action en mode démo
    setNews((prevNews) => [newArticle, ...prevNews]); // Ajouter la nouvelle actualité à la liste
    fetchNews(); // Rafraîchir les données après la création
  };

  const handleSave = (updatedArticle) => {
    setNews((prevNews) =>
      prevNews.map((article) =>
        article.id === updatedArticle.id ? updatedArticle : article
      )
    );
    fetchNews(); // Rafraîchir les données après la modification
  };

  return (
    <div className="p-6 bg-white dark:bg-gray-800 rounded-md shadow-md">
      <h2 className="text-2xl font-semibold mb-6 dark:text-gray-100">Gestion des Actualités</h2>

      {/* Bouton pour créer une nouvelle actualité avec FaPlus et texte */}
      <Button
        text={(
          <>
            <FaPlus className="mr-2" /> Créer une actualité
          </>
        )}
        onClick={() => {
          if (!handleDemoClick()) setCreateModalOpen(true);
        }} // Bloquer la création en mode démo
        className="mb-4"
      />

      {/* Tableau des actualités */}
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-600 rounded-md">
          <thead>
            <tr className="bg-gray-100 dark:bg-gray-900 dark:text-gray-200">
              <th className="px-4 py-2 border dark:border-gray-600">Image</th>
              <th className="px-4 py-2 border dark:border-gray-600">Titre</th>
              <th className="px-4 py-2 border dark:border-gray-600">Contenu</th>
              <th className="px-4 py-2 border dark:border-gray-600">Date</th>
              <th className="px-4 py-2 border dark:border-gray-600">Statut</th>
              <th className="px-4 py-2 border dark:border-gray-600">Actions</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 dark:divide-gray-700">
            {news.map((article) => (
              <tr
                key={article.id}
                className={`${
                  article.is_active ? "bg-white dark:bg-gray-800" : "bg-gray-200 dark:bg-gray-700"
                } hover:bg-gray-50 dark:hover:bg-gray-700`}
              >
                <td className="px-4 py-2 border dark:border-gray-600 text-center">
                  <img
                    src={article.image_url}
                    alt={article.title}
                    className="h-24 w-24 object-cover rounded-md"
                  />
                </td>
                <td className="px-4 py-2 border dark:border-gray-600 dark:text-gray-200">{article.title}</td>
                <td className="px-4 py-2 border dark:border-gray-600 dark:text-gray-200">
                  {/* Utiliser dangerouslySetInnerHTML pour afficher le contenu enrichi */}
                  <div
                    dangerouslySetInnerHTML={{ __html: article.content }}
                    className="text-sm text-gray-600 dark:text-gray-300"
                  />
                </td>

                <td className="px-4 py-2 border text-center dark:border-gray-600 dark:text-gray-200">
                  {new Date(article.created_at).toLocaleDateString("fr-FR", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  })}
                </td>
                <td className="px-4 py-2 border text-center dark:border-gray-600 dark:text-gray-200">
                  {article.is_active ? "Actif" : "Inactif"}
                </td>
                <td className="px-4 py-2 dark:border-gray-600 dark:text-gray-200">
                  <div className="flex justify-center items-center w-full h-full gap-2">
                    <ButtonEdit onClick={() => handleEdit(article)} /> {/* Bloquer l'édition en mode démo */}
                    <ButtonDelete onClick={() => handleDelete(article.id)} /> {/* Bloquer la suppression en mode démo */}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Modal d'édition */}
      {isEditModalOpen && currentNews && (
        <EditNewsModale
          article={currentNews}
          onClose={() => setEditModalOpen(false)}
          onSave={(updatedArticle) => {
            handleSave(updatedArticle);
            setEditModalOpen(false);
          }}
          refreshData={fetchNews} // Passer la fonction refreshData
        />
      )}

      {/* Modal de création */}
      {isCreateModalOpen && (
        <CreateNewsModale
          onCreate={(newArticle) => {
            handleCreate(newArticle);
            setCreateModalOpen(false);
          }}
          onClose={() => setCreateModalOpen(false)}
          refreshData={fetchNews} // Passer la fonction refreshData
        />
      )}

      <ToastContainer />
    </div>
  );
};

export default Actualites;
