import React, { useState, useEffect } from "react";
import useScrollToTop from "../../../hooks/useScrollToTop";
import Footer from "./../HomePage/Footer";
import Button from "../../../components/Button/Button";
import { addNewAdherent } from "../../../services/adherentServices";
import { getGroupsHome } from "../../../services/groupServices";
import { ToastContainer, toast } from "react-toastify";
import { sendNotificationToAdmin } from "../../../services/notificationServices";
import ComponentTitle from "./../../../components/ComponentTitle";
import "react-toastify/dist/ReactToastify.css";
import "./../HomePage/Home.css";

function InscriptionPage() {
  useScrollToTop();
  const [formData, setFormData] = useState({
    adherent_groupe: "",
    nom: "",
    prenom: "",
    email: "",
    email_secondaire: "",
    tel: "",
    tel_secondaire: "",
    adresse: "",
    cp: "",
    commune: "",
    genre: "M",
    date_naissance: "",
    poids: "",
    licence_number: "",
    autorisation_photo: true,
    notes_adherent: "",
  });
  const [groups, setGroups] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // Récupérer les groupes pour le champ adherent_groupe
    const fetchGroups = async () => {
      try {
        const groupsData = await getGroupsHome();
        setGroups(groupsData);
      } catch (error) {
        console.error("Erreur lors de la récupération des groupes", error);
      }
    };
    fetchGroups();
  }, []);

  // Gestion des changements dans le formulaire
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  // Fonction de validation des champs du formulaire
  const validateForm = () => {
    const {
      adherent_groupe,
      nom,
      prenom,
      email,
      tel,
      adresse,
      cp,
      commune,
      date_naissance,
      poids,
    } = formData;

    // Vérification des champs requis
    if (
      !adherent_groupe ||
      !nom ||
      !prenom ||
      !email ||
      !tel ||
      !adresse ||
      !cp ||
      !commune ||
      !date_naissance
    ) {
      toast.error("Veuillez remplir tous les champs obligatoires.");
      return false;
    }

    // Vérification de l'email
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(email)) {
      toast.error("Veuillez entrer un e-mail valide.");
      return false;
    }

    // Vérification de la date de naissance
    const dateOfBirth = new Date(date_naissance);
    const today = new Date();
    const minDate = new Date(1900, 0, 1); // Date minimale pour limiter les dates impossibles
    if (dateOfBirth >= today || dateOfBirth < minDate || isNaN(dateOfBirth)) {
      toast.error("Veuillez entrer une date de naissance valide (après 1900).");
      return false;
    }

    // Vérification du code postal (5 chiffres)
    const cpPattern = /^[0-9]{5}$/;
    if (!cpPattern.test(cp)) {
      toast.error("Veuillez entrer un code postal valide à 5 chiffres.");
      return false;
    }

    // Vérification du format du téléphone
    const telPattern = /^(\+?\d{1,3}[- ]?)?\d{10}$/;
    if (!telPattern.test(tel)) {
      toast.error("Veuillez entrer un numéro de téléphone valide.");
      return false;
    }

    // Vérification du poids
    if (poids && (isNaN(poids) || parseFloat(poids) <= 0)) {
      toast.error("Veuillez entrer un poids valide.");
      return false;
    }

    return true;
  };

  // Soumettre le formulaire
  const handleSubmit = async (e) => {
  e.preventDefault();

  // Validation des données avant soumission
  if (!validateForm()) {
    return;
  }

  setLoading(true);

  try {
    // Ajouter le nouvel adhérent
    await addNewAdherent(formData);

    // Créer le message de notification
    const notificationMessage = `Un nouvel adhérent vient de s'inscrire ${formData.nom} ${formData.prenom} sur le groupe : ${formData.adherent_groupe}`;

    // Envoyer la notification à l'administrateur
    await sendNotificationToAdmin(notificationMessage);

    // Afficher un message de succès
    toast.success("Adhérent ajouté avec succès !");

    // Réinitialiser le formulaire après soumission
    setFormData({
      adherent_groupe: "",
      nom: "",
      prenom: "",
      email: "",
      email_secondaire: "",
      tel: "",
      tel_secondaire: "",
      adresse: "",
      cp: "",
      commune: "",
      genre: "M",
      date_naissance: "",
      poids: "",
      licence_number: "",
      autorisation_photo: true,
      notes_adherent: "",
    });
  } catch (error) {
    console.error("Erreur lors de l'ajout de l'adhérent:", error);
    toast.error("Erreur lors de l'ajout de l'adhérent");
  } finally {
    setLoading(false);
  }
};

  return (
    <div className="flex flex-col min-h-screen bg-gray-200 dark:bg-gray-800 dark:text-gray-200">
      {/* Dégradé pour améliorer la visibilité de la Navbar */}
      <div className="bg-gradient-to-b from-neutral-800 to-neutral-500 h-[70px] w-full"></div>

      <div className="flex-grow relative z-10 px-4 py-6">
        {/* Utilisation de ComponentTitle avec un style adapté */}
        <div className="mx-auto max-w-screen-md text-center mb-6 lg:mb-8">
          <ComponentTitle title="Inscription" highlight=" des Adhérents" />
        </div>

        <form
          className="bg-gray-100 dark:bg-gray-800 p-6 rounded-md shadow-md max-w-xl mx-auto mb-6"
          onSubmit={handleSubmit}
        >
          {/* Groupe */}
          <div className="mb-4">
            <label className="block text-gray-700 dark:text-gray-300">
              Groupe
            </label>
            <select
              name="adherent_groupe"
              value={formData.adherent_groupe}
              onChange={handleChange}
              className="w-full p-2 border rounded dark:bg-gray-700 dark:border-gray-600 dark:text-white"
              required
            >
              <option value="">Sélectionnez un groupe</option>
              {groups.map((group) => (
                <option key={group.id} value={group.nom}>
                  {group.nom}
                </option>
              ))}
            </select>
          </div>

          {/* Nom */}
          <div className="mb-4">
            <label className="block text-gray-700 dark:text-gray-300">Nom</label>
            <input
              type="text"
              name="nom"
              value={formData.nom}
              onChange={handleChange}
              className="w-full p-2 border rounded dark:bg-gray-700 dark:border-gray-600 dark:text-white"
              required
            />
          </div>

          {/* Prénom */}
          <div className="mb-4">
            <label className="block text-gray-700 dark:text-gray-300">
              Prénom
            </label>
            <input
              type="text"
              name="prenom"
              value={formData.prenom}
              onChange={handleChange}
              className="w-full p-2 border rounded dark:bg-gray-700 dark:border-gray-600 dark:text-white"
              required
            />
          </div>

          {/* Email */}
          <div className="mb-4">
            <label className="block text-gray-700 dark:text-gray-300">
              Email
            </label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className="w-full p-2 border rounded dark:bg-gray-700 dark:border-gray-600 dark:text-white"
              required
            />
          </div>

          {/* Email secondaire */}
          <div className="mb-4">
            <label className="block text-gray-700 dark:text-gray-300">
              Email secondaire
            </label>
            <input
              type="email"
              name="email_secondaire"
              value={formData.email_secondaire}
              onChange={handleChange}
              className="w-full p-2 border rounded dark:bg-gray-700 dark:border-gray-600 dark:text-white"
            />
          </div>

          {/* Téléphone */}
          <div className="mb-4">
            <label className="block text-gray-700 dark:text-gray-300">
              Téléphone
            </label>
            <input
              type="tel"
              name="tel"
              value={formData.tel}
              onChange={handleChange}
              className="w-full p-2 border rounded dark:bg-gray-700 dark:border-gray-600 dark:text-white"
              required
            />
          </div>

          {/* Téléphone secondaire */}
          <div className="mb-4">
            <label className="block text-gray-700 dark:text-gray-300">
              Téléphone secondaire
            </label>
            <input
              type="tel"
              name="tel_secondaire"
              value={formData.tel_secondaire}
              onChange={handleChange}
              className="w-full p-2 border rounded dark:bg-gray-700 dark:border-gray-600 dark:text-white"
            />
          </div>

          {/* Adresse */}
          <div className="mb-4">
            <label className="block text-gray-700 dark:text-gray-300">
              Adresse
            </label>
            <input
              type="text"
              name="adresse"
              value={formData.adresse}
              onChange={handleChange}
              className="w-full p-2 border rounded dark:bg-gray-700 dark:border-gray-600 dark:text-white"
              required
            />
          </div>

          {/* Code postal */}
          <div className="mb-4">
            <label className="block text-gray-700 dark:text-gray-300">
              Code postal
            </label>
            <input
              type="text"
              name="cp"
              value={formData.cp}
              onChange={handleChange}
              className="w-full p-2 border rounded dark:bg-gray-700 dark:border-gray-600 dark:text-white"
              required
            />
          </div>

          {/* Commune */}
          <div className="mb-4">
            <label className="block text-gray-700 dark:text-gray-300">
              Commune
            </label>
            <input
              type="text"
              name="commune"
              value={formData.commune}
              onChange={handleChange}
              className="w-full p-2 border rounded dark:bg-gray-700 dark:border-gray-600 dark:text-white"
              required
            />
          </div>

          {/* Genre */}
          <div className="mb-4">
            <label className="block text-gray-700 dark:text-gray-300">
              Genre
            </label>
            <div className="flex gap-4">
              <label className="flex items-center">
                <input
                  type="radio"
                  name="genre"
                  value="M"
                  checked={formData.genre === "M"}
                  onChange={handleChange}
                  className="mr-2"
                />
                Masculin
              </label>
              <label className="flex items-center">
                <input
                  type="radio"
                  name="genre"
                  value="F"
                  checked={formData.genre === "F"}
                  onChange={handleChange}
                  className="mr-2"
                />
                Féminin
              </label>
            </div>
          </div>

          {/* Date de naissance */}
          <div className="mb-4">
            <label className="block text-gray-700 dark:text-gray-300">
              Date de naissance
            </label>
            <input
              type="date"
              name="date_naissance"
              value={formData.date_naissance}
              onChange={handleChange}
              className="w-full p-2 border rounded dark:bg-gray-700 dark:border-gray-600 dark:text-white"
              required
            />
          </div>

          {/* Poids */}
          <div className="mb-4">
            <label className="block text-gray-700 dark:text-gray-300">
              Poids (kg)
            </label>
            <input
              type="number"
              name="poids"
              value={formData.poids}
              onChange={handleChange}
              className="w-full p-2 border rounded dark:bg-gray-700 dark:border-gray-600 dark:text-white"
              step="0.1"
            />
          </div>

          {/* Numéro de Licence */}
          <div className="mb-4">
            <label className="block text-gray-700 dark:text-gray-300">
              Numéro de Licence (laissez vide si débutant)
            </label>
            <input
              type="text"
              name="licence_number"
              value={formData.licence_number}
              onChange={handleChange}
              className="w-full p-2 border rounded dark:bg-gray-700 dark:border-gray-600 dark:text-white"
            />
          </div>

          {/* Autorisation Photo */}
          <div className="mb-4">
            <label className="block text-gray-700 dark:text-gray-300">
              Autorisation prise de photo
            </label>
            <input
              type="checkbox"
              name="autorisation_photo"
              checked={formData.autorisation_photo}
              onChange={handleChange}
              className="mr-2"
            />
          </div>

          {/* Notes */}
          <div className="mb-4">
            <label className="block text-gray-700 dark:text-gray-300">
              Notes (allergies, etc.)
            </label>
            <textarea
              name="notes_adherent"
              value={formData.notes_adherent}
              onChange={handleChange}
              className="w-full p-2 border rounded dark:bg-gray-700 dark:border-gray-600 dark:text-white"
            />
          </div>

          {/* Bouton de soumission */}
          <Button
            text="Enregistrer"
            onClick={handleSubmit}
            className="w-full mt-4"
            disabled={loading}
          />
        </form>

        <Footer />
      </div>
      <ToastContainer />
    </div>
  );
}

export default InscriptionPage;
