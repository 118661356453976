import React, { useState, useEffect } from "react";
import useScrollToTop from "../../../hooks/useScrollToTop";
import Footer from "./../HomePage/Footer";
import GroupHorairesTarifs from "./GroupHorairesTarifs";
import WeeklySchedule from "./WeeklySchedule";
import "./../HomePage/Home.css";

function HorairesTarifsPage() {
  useScrollToTop();
  return (
    <div className="flex flex-col min-h-screen">
      {/* Dégradé pour améliorer la visibilité de la Navbar */}
      <div className="bg-gradient-to-b from-neutral-800 to-neutral-500 h-[70px] w-full"></div>

      <div className="flex-grow relative z-10">
        {/* Intégration du composant GroupHorairesTarifs */}
        <GroupHorairesTarifs />
        {/* Intégration du composant WeeklySchedule */}
        <WeeklySchedule />

        <div>
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default HorairesTarifsPage;