import React, { useState } from "react";
import Button from "../../../../components/Button/Button";
import { FaSave, FaTimes } from "react-icons/fa";

const CreateUserModal = ({ onCreate, onClose }) => {
  const [formData, setFormData] = useState({
    nom: "",
    prenom: "",
    email: "",
    password: "",
    role_id: "",
    photoURL: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onCreate(formData);
    onClose();
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg w-full max-w-lg">
        <div className="bg-primary text-white p-4 rounded-t-lg">
          <h3 className="text-xl font-semibold dark:text-gray-100">
            Ajouter un nouvel Utilisateur
          </h3>
        </div>
        <div className="p-6">
          <form onSubmit={handleSubmit} className="space-y-4">
            <div className="mb-4">
              <label className="block font-semibold mb-2 dark:text-gray-200">
                Nom
              </label>
              <input
                type="text"
                name="nom"
                value={formData.nom}
                onChange={handleChange}
                placeholder="Nom"
                className="w-full p-2 border border-gray-300 dark:border-gray-600 rounded-md dark:bg-gray-700 dark:text-gray-200"
              />
            </div>
            <div className="mb-4">
              <label className="block font-semibold mb-2 dark:text-gray-200">
                Prénom
              </label>
              <input
                type="text"
                name="prenom"
                value={formData.prenom}
                onChange={handleChange}
                placeholder="Prénom"
                className="w-full p-2 border border-gray-300 dark:border-gray-600 rounded-md dark:bg-gray-700 dark:text-gray-200"
              />
            </div>
            <div className="mb-4">
              <label className="block font-semibold mb-2 dark:text-gray-200">
                Email
              </label>
              <input
                type="text"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="Email"
                className="w-full p-2 border border-gray-300 dark:border-gray-600 rounded-md dark:bg-gray-700 dark:text-gray-200"
              />
            </div>
            <div className="mb-4">
              <label className="block font-semibold mb-2 dark:text-gray-200">
                Password
              </label>
              <input
                type="text"
                name="password"
                value={formData.password}
                onChange={handleChange}
                placeholder="Password"
                className="w-full p-2 border border-gray-300 dark:border-gray-600 rounded-md dark:bg-gray-700 dark:text-gray-200"
              />
            </div>
            <div className="mb-4">
              <label className="block font-semibold mb-2 dark:text-gray-200">
                Rôle
              </label>
              <select
                name="role_id"
                value={formData.role_id}
                onChange={handleChange}
                className="w-full p-2 border border-gray-300 dark:border-gray-600 rounded-md dark:bg-gray-700 dark:text-gray-200"
              >
                <option value="">Choisissez un rôle</option>
                <option value="2">Bureau</option>
                <option value="3">Enseignant</option>
              </select>
            </div>
            <div className="mb-4">
              <label className="block font-semibold mb-2 dark:text-gray-200">
                URL de la Photo
              </label>
              <input
                type="text"
                name="photoURL"
                value={formData.photoURL}
                onChange={handleChange}
                placeholder="Photo URL"
                className="w-full p-2 border border-gray-300 dark:border-gray-600 rounded-md dark:bg-gray-700 dark:text-gray-200"
              />
            </div>
            <div className="flex justify-center space-x-2">
              <Button
                text="Annuler"
                icon={FaTimes}
                onClick={onClose}
                className="gap-2"
              />
              <Button
                text="Enregistrer"
                icon={FaSave}
                type="submit"
                className="gap-2"
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CreateUserModal;
