import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { BsArrowLeftShort } from "react-icons/bs";
import { MdDashboard } from "react-icons/md";
import { FaNewspaper, FaImages, FaHandshake, FaUsers, FaAddressCard, FaCog, FaHome, FaSun, FaMoon } from "react-icons/fa";
import { FaBuildingUser } from "react-icons/fa6";
import { GiKimono } from "react-icons/gi";
import { useAsideMenu } from "../../contexts/AsideMenuContext";
import { getSettings } from "../../services/settingsServices"; // Import du service pour récupérer les infos du club
import "./AsideMenu.css";

const AsideMenu = () => {
  const { isOpen, toggleMenu } = useAsideMenu(); // Gestion de l'ouverture du menu
  const location = useLocation();
  const [submenuOpen, setSubmenuOpen] = useState(false); // État pour les sous-menus
  const [isDarkMode, setIsDarkMode] = useState(false); // État pour le Dark Mode
  const [clubInfo, setClubInfo] = useState({
    logo: '',
    nom: '',
    facebook: '',
    instagram: '',
  });
  const [userInfo, setUserInfo] = useState({
    id: 0,
    nom: '',
    prenom: '',
    email: '',
    photoURL: '',
    role_id: 0
  });

  // Liste des menus pour l'AsideMenu
  const menus = [
    { title: "Tableau de bord", path: "/appli/dashboard", icon: <MdDashboard /> },
    { title: "Inscriptions", path: "/appli/inscriptions", icon: <FaAddressCard /> },
    { title: "Disciplines", path: "/appli/disciplines", icon: <GiKimono /> },
    { title: "Actualités", path: "/appli/actualites", icon: <FaNewspaper /> },
    { title: "Albums Photos", path: "/appli/albums-photos", icon: <FaImages /> },
    { title: "Partenaires", path: "/appli/partenaires", icon: <FaHandshake /> },
    { title: "Groupes", path: "/appli/groupes", icon: <FaUsers /> },
    { title: "Le Club", path: "/appli/le-club", icon: <FaBuildingUser /> },
    { title: "Paramètres", path: "/appli/parametres", icon: <FaCog /> },
  ];

  // Fonction pour récupérer les paramètres du club
  useEffect(() => {
    const fetchClubSettings = async () => {
      try {
        const settings = await getSettings();
        const club = settings[0];
        setClubInfo({
          logo: club.logo_club,
          nom: club.nom_club,
        });
      } catch (error) {
        console.error('Erreur lors de la récupération des paramètres du club:', error);
      }
    };

    fetchClubSettings();

    // Initialiser le thème à partir de localStorage
    const theme = localStorage.getItem('theme');
    if (theme === 'dark') {
      document.documentElement.classList.add('dark');
      setIsDarkMode(true);
    }

    // Récupérer les informations utilisateur depuis localStorage
    const userData = JSON.parse(localStorage.getItem('user'));
    if (userData) {
      setUserInfo(userData);
    }
  }, []);

  // Fonction pour basculer entre les thèmes
  const toggleTheme = () => {
    const isDark = !isDarkMode;
    setIsDarkMode(isDark);
    document.documentElement.classList.toggle('dark', isDark);
    localStorage.setItem('theme', isDark ? 'dark' : 'light');
  };

  // Fonction pour obtenir le rôle utilisateur
  const getRoleName = (roleId) => {
    switch (roleId) {
      case 1:
        return "Administrateur";
      case 2:
        return "Bureau";
      case 3:
        return "Enseignant";
      default:
        return "Utilisateur";
    }
  };

  return (
    <aside className={`bg-gray-700 h-screen flex flex-col justify-between fixed z-10000 ${isOpen ? "w-72" : "w-20"} duration-300`}>
      <div className="flex-1 overflow-y-auto overflow-x-hidden hide-scrollbar p-5 pt-8">
        <div className="text-2xl font-bold mb-6 relative">
          <div className="text-4xl font-semibold font-passionOne flex items-center">
            <button className="text-white focus:outline-none flex items-center gap-2">
              <img src={clubInfo.logo} alt="Logo Club" className="h-10 w-10 object-cover" />
              {isOpen && (
                <span className="text-transparent bg-clip-text bg-gradient-to-r from-yellow-400 to-orange-600">
                  {clubInfo.nom}
                </span>
              )}
            </button>
          </div>
          <BsArrowLeftShort
            className={`bg-white text-gray-700 text-3xl rounded-full absolute -right-5 top-1 border border-gray-700 cursor-pointer ${!isOpen && "rotate-180"}`}
            style={{ zIndex: 999 }}
            onClick={toggleMenu}
          />
        </div>
        <nav>
          <ul className="pt-2">
            {menus.map((menu, index) => (
              <li
                key={index}
                className={`relative flex items-center py-2 text-gray-300 text-sm gap-x-4 cursor-pointer p-2 hover:bg-light-white rounded-md mt-2 ${
                  location.pathname === menu.path ? "bg-light-white" : ""
                }`}
                onClick={menu.action ? menu.action : null}
              >
                <Link to={menu.path} className="flex items-center">
                  <div className="relative">
                    <span className="text-2xl">{menu.icon}</span>
                  </div>
                  <span className={`ml-2 text-base font-thin flex-1 text-white duration-200 ${!isOpen && "hidden"}`}>
                    {menu.title}
                  </span>
                </Link>
              </li>
            ))}

            {/* Séparateur horizontal */}
            <li className="border-t border-gray-500 my-4"></li>

            {/* Lien vers l'accueil (site web) */}
            <li
              className={`relative flex items-center py-2 text-gray-300 text-sm gap-x-4 cursor-pointer p-2 hover:bg-light-white rounded-md mt-2 ${
                location.pathname === "/" ? "bg-light-white" : ""
              }`}
            >
              <Link to="/" className="flex items-center">
                <div className="relative">
                  <span className="text-2xl"><FaHome /></span>
                </div>
                <span className={`ml-2 text-base font-thin flex-1 text-white duration-200 ${!isOpen && "hidden"}`}>
                  Accueil
                </span>
              </Link>
            </li>

            {/* Switch Dark/Light Mode */}
            <li
              className="relative flex items-center py-2 text-gray-300 text-sm gap-x-4 cursor-pointer p-2 hover:bg-light-white rounded-md mt-2"
              onClick={toggleTheme}
            >
              <button className="flex items-center w-full">
                <div className="relative">
                  <span className="text-2xl">
                    {isDarkMode ? <FaSun className="text-yellow-400" /> : <FaMoon className="text-gray-300" />}
                  </span>
                </div>
                <span className={`ml-2 text-base text-left font-thin flex-1 text-white duration-200 ${!isOpen && "hidden"}`}>
                  {isDarkMode ? 'Mode Light' : 'Mode Dark'}
                </span>
              </button>
            </li>
          </ul>
        </nav>
      </div>

      {/* Section profil utilisateur */}
      <div className="p-4 bg-gray-800 border-t border-gray-500 flex items-center gap-4">
        <img
          src={userInfo.photoURL}
          alt={`${userInfo.prenom} ${userInfo.nom}`}
          className="w-10 h-10 rounded-full object-cover"
        />
        {isOpen && (
          <div className="text-white">
            <p className="text-sm font-bold">{`${userInfo.prenom} ${userInfo.nom}`}</p>
            <p className="text-xs text-gray-400">{getRoleName(userInfo.role_id)}</p>
          </div>
        )}
      </div>
    </aside>
  );
};

export default AsideMenu;
