import React, { useState, useEffect } from "react";
import Button from "../../../../components/Button/Button";
import { FaTimes, FaPlus, FaTrash } from "react-icons/fa"; // Icônes
import { addPhoto, deletePhoto } from "../../../../services/photoServices"; // Import des services pour gérer les photos
import { toast } from "react-toastify";

const ManagePhotoModal = ({ album, onClose, onSave }) => {
  const [photos, setPhotos] = useState(album.photos || []);
  const [newPhotoUrl, setNewPhotoUrl] = useState("");
  const [newPhotoDescription, setNewPhotoDescription] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isDemoMode, setIsDemoMode] = useState(false); // État pour vérifier si l'utilisateur est en mode démo

  // Fonction pour vérifier le role_id dans localStorage
  const checkUserRole = () => {
    const user = JSON.parse(localStorage.getItem("user")); // Récupérer l'utilisateur depuis localStorage
    if (user && user.role_id === 4) {
      setIsDemoMode(true); // Activer le mode démo si role_id est 4
    }
  };

  useEffect(() => {
    checkUserRole(); // Vérifier le rôle de l'utilisateur au chargement du composant
  }, []);

  // Afficher un message si en mode démo
  const handleDemoClick = () => {
    if (isDemoMode) {
      toast.info("Fonction désactivée en mode Démonstration");
      return true; // Indique que l'action est désactivée en mode démo
    }
    return false; // Permet l'action si pas en mode démo
  };

  // Gérer l'ajout d'une photo
  const handleAddPhoto = async () => {
    if (handleDemoClick()) return; // Bloquer l'action en mode démo

    if (!newPhotoUrl) {
      toast.error("Veuillez entrer une URL valide pour la photo.");
      return;
    }

    setIsLoading(true);

    const newPhoto = {
      gallerie_id: album.id,
      photo_url: newPhotoUrl,
      description: newPhotoDescription, // Utiliser la description entrée
      is_active: true,
    };

    try {
      const addedPhoto = await addPhoto(newPhoto);
      setPhotos((prev) => [...prev, addedPhoto]);
      setNewPhotoUrl(""); // Réinitialiser le champ
      setNewPhotoDescription(""); // Réinitialiser la description
      toast.success("Photo ajoutée avec succès.");
    } catch (error) {
      console.error("Erreur lors de l'ajout de la photo :", error);
      toast.error("Erreur lors de l'ajout de la photo.");
    } finally {
      setIsLoading(false);
    }
  };

  // Gérer la suppression d'une photo
  const handleDeletePhoto = async (photoId) => {
    if (handleDemoClick()) return; // Bloquer l'action en mode démo

    if (window.confirm("Êtes-vous sûr de vouloir supprimer cette photo ?")) {
      setIsLoading(true);

      try {
        await deletePhoto(photoId);
        setPhotos((prev) => prev.filter((photo) => photo.id !== photoId));
        toast.success("Photo supprimée avec succès.");
      } catch (error) {
        console.error("Erreur lors de la suppression de la photo :", error);
        toast.error("Erreur lors de la suppression de la photo.");
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-30 flex justify-center items-center z-50">
      <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg w-full max-w-2xl">
        <div className="bg-primary text-white p-4 rounded-t-lg">
          <h2 className="text-xl font-semibold">Gestion des Photos - {album.titre}</h2>
        </div>

        <div className="p-6 dark:text-gray-200">
          {/* Affichage des photos actuelles avec scroll */}
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 mb-4 max-h-96 overflow-y-auto">
            {photos.map((photo) => (
              <div key={photo.id} className="relative">
                <img
                  src={photo.photo_url}
                  alt={photo.description}
                  className="w-full h-40 object-cover rounded-md"
                />
                <button
                  className="absolute top-2 right-2 bg-primary text-white p-2 rounded-full hover:bg-black"
                  onClick={() => handleDeletePhoto(photo.id)}
                >
                  <FaTrash />
                </button>
              </div>
            ))}
          </div>

          {/* Ajouter une nouvelle photo */}
          <div className="mb-4">
            <input
              type="text"
              value={newPhotoUrl}
              onChange={(e) => setNewPhotoUrl(e.target.value)}
              className="w-full p-2 border border-gray-300 dark:border-gray-600 rounded-md mb-2 dark:bg-gray-700 dark:text-gray-200"
              placeholder="Entrez l'URL de la nouvelle photo"
            />
            <input
              type="text"
              value={newPhotoDescription}
              onChange={(e) => setNewPhotoDescription(e.target.value)}
              className="w-full p-2 border border-gray-300 dark:border-gray-600 rounded-md dark:bg-gray-700 dark:text-gray-200"
              placeholder="Entrez une description de la photo"
            />
          </div>

          <Button
            text="Ajouter"
            onClick={handleAddPhoto}
            icon={FaPlus}
            disabled={isLoading}
            className="ml-2 gap-2"
          />

          <div className="flex justify-end gap-2 mt-4">
            <Button text="Fermer" onClick={onClose} icon={FaTimes} className="gap-2"/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManagePhotoModal;
