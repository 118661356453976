import React, { useState, useEffect } from "react";
import { getGroupsHome } from "../../../services/groupServices";
import useScrollToTop from "../../../hooks/useScrollToTop";
import ComponentTitle from "../../../components/ComponentTitle";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa"; // Importer des icônes pour les flèches

const GroupHorairesTarifs = () => {
  useScrollToTop();
  const [groups, setGroups] = useState([]);
  const [currentSlide, setCurrentSlide] = useState({}); // Initialiser l'état des slides

  useEffect(() => {
    const fetchGroups = async () => {
      try {
        const groupsData = await getGroupsHome(); // Récupérer les groupes depuis l'API
        setGroups(groupsData);

        // Initialiser chaque groupe avec la première slide (slide 0)
        const initialSlides = {};
        groupsData.forEach((group) => {
          initialSlides[group.id] = 0; // Par défaut, la première slide est affichée
        });
        setCurrentSlide(initialSlides); // Mettre à jour l'état avec les slides initialisées
      } catch (error) {
        console.error("Erreur lors de la récupération des groupes", error);
      }
    };

    fetchGroups();
  }, []);

  // Mapping des couleurs possibles pour éviter les problèmes avec Tailwind
  const borderColorClasses = {
    "bg-green-400": "border-green-400 bg-green-400",
    "bg-red-400": "border-red-400 bg-red-400",
    "bg-blue-400": "border-blue-400 bg-blue-400",
    "bg-yellow-400": "border-yellow-400 bg-yellow-400",
    "bg-orange-400": "border-orange-400 bg-orange-400",
    "bg-purple-400": "border-purple-400 bg-purple-400",
    "bg-pink-400": "border-pink-400 bg-pink-400",
    "bg-indigo-400": "border-indigo-400 bg-indigo-400",
    "bg-gray-400": "border-gray-400 bg-gray-400",
    "bg-teal-400": "border-teal-400 bg-teal-400",
    "bg-black": "border-black bg-black",
    "bg-white": "border-white bg-white",
    "bg-transparent": "border-transparent bg-transparent",
  };

  // Fonction pour obtenir la classe de bordure et de fond en fonction de color_groupe
  const getGroupColor = (color_groupe) => {
    return `${borderColorClasses[color_groupe] || "border-orange-400 bg-orange-400"}`;
  };

  // Fonction pour changer de slide
  const handleNextSlide = (groupId) => {
    setCurrentSlide((prevState) => ({
      ...prevState,
      [groupId]: (prevState[groupId] === 2 ? 0 : (prevState[groupId] || 0) + 1),
    }));
  };

  const handlePrevSlide = (groupId) => {
    setCurrentSlide((prevState) => ({
      ...prevState,
      [groupId]: (prevState[groupId] === 0 ? 2 : (prevState[groupId] || 0) - 1),
    }));
  };

  return (
    <div className="container mx-auto px-4 mt-8">
      {/* Utilisation de ComponentTitle avec le mode Dark */}
      <div className="mx-auto max-w-screen-md text-center mb-6 lg:mb-8">
        <ComponentTitle title="Groupes, Horaires" highlight=" et Tarifs" />
      </div>

      {/* Section des Groupes */}
      <section className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {groups.map((group) => (
          <div
            key={group.id}
            className={`relative flex flex-col items-center shadow-md bg-white dark:bg-gray-800  hover:shadow-xl transition-shadow duration-500 transform hover:scale-102 rounded-md`}
            style={{ minHeight: "100px", maxHeight: "200px" }} // Limiter la hauteur des cards
          >
            {/* Titre du groupe fixé en haut */}
            <div className={`${getGroupColor(group.color_groupe)} text-white w-full text-center py-2`}>
              <h3 className="text-xl font-bold uppercase">{group.nom}</h3>
            </div>

            {/* Slides */}
            <div className="w-full relative p-4 pt-8"> {/* Ajouter du padding en haut pour le texte */}
              <div className="flex justify-center items-center h-16 mb-2">
                <button
                  onClick={() => handlePrevSlide(group.id)}
                  className="absolute left-0 top-1/2 transform -translate-y-1/2 p-2 bg-gray-200 dark:bg-gray-700 rounded-full hover:bg-gray-300 dark:hover:bg-gray-600 ml-2"
                >
                  <FaArrowLeft className="text-gray-800 dark:text-gray-300" />
                </button>

                {currentSlide[group.id] === 0 && (
                  <div className="text-center">
                    {/* Première slide : Années et enseignants */}
                    <p className="text-base text-gray-600 dark:text-gray-300">
                      <strong>Années :</strong> {group.annees_naissance}
                    </p>
                    <p className="text-base text-gray-600 dark:text-gray-300">
                      <strong>Enseignant(s) :</strong> {group.enseignant1}
                      {group.enseignant1 && group.enseignant2 && " & "}
                      {group.enseignant2}
                    </p>
                  </div>
                )}
                {currentSlide[group.id] === 1 && (
                  <div className="text-center">
                    {/* Deuxième slide : Horaires */}
                    <p className="text-base text-gray-600 dark:text-gray-300">
                      <strong>Horaire 1 :</strong> {group.horaire1}
                    </p>
                    {group.horaire2 && (
                      <p className="text-base text-gray-600 dark:text-gray-300">
                        <strong>Horaire 2 :</strong> {group.horaire2}
                      </p>
                    )}
                  </div>
                )}
                {currentSlide[group.id] === 2 && (
                  <div className="text-center">
                    {/* Troisième slide : Tarifs */}
                    <p className="text-base text-gray-600 dark:text-gray-300">
                      <strong>Tarif Licence :</strong> {group.tarif_licence}€
                    </p>
                    <p className="text-base text-gray-600 dark:text-gray-300">
                      <strong>Tarif Cotisation :</strong> {group.tarif_cotisation}€
                    </p>
                    <p className="text-base text-gray-600 dark:text-gray-300">
                      <strong>Tarif Total Annuel :</strong>{" "}
                      {parseFloat(group.tarif_licence) + parseFloat(group.tarif_cotisation)}€
                    </p>
                  </div>
                )}

                <button
                  onClick={() => handleNextSlide(group.id)}
                  className="absolute right-0 top-1/2 transform -translate-y-1/2 p-2 bg-gray-200 dark:bg-gray-700 rounded-full hover:bg-gray-300 dark:hover:bg-gray-600 mr-2"
                >
                  <FaArrowRight className="text-gray-800 dark:text-gray-300" />
                </button>
              </div>
            </div>
          </div>
        ))}
      </section>
    </div>
  );
};

export default GroupHorairesTarifs;
