import React, { useState, useEffect } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { getAllGalleries } from "../../../services/gallerieServices"; // Assurez-vous que le chemin est correct
import { getSettings } from "../../../services/settingsServices"; // Assurez-vous que le chemin est correct

const Diaporama = () => {
  const [photos, setPhotos] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchGalleryPhotos = async () => {
      try {
        const settings = await getSettings();
        const carrouselId = settings[0]?.carrousel_id;
        if (carrouselId) {
          const galleries = await getAllGalleries();
          const selectedGallery = galleries.find(
            (gallery) => gallery.id === carrouselId
          );
          if (selectedGallery && selectedGallery.photos) {
            setPhotos(
              selectedGallery.photos.filter((photo) => photo.is_active)
            );
          }
        }
      } catch (error) {
        console.error("Erreur lors de la récupération des photos:", error);
      }
      setLoading(false);
    };

    fetchGalleryPhotos();
  }, []);

  if (loading) return <div>Chargement...</div>;

  if (photos.length === 0)
    return <div>Aucune photo disponible pour le carrousel</div>;

  return (
    <Carousel
      autoPlay
      infiniteLoop
      useKeyboardArrows
      dynamicHeight
      showThumbs={false}
      interval={5000}
    >
      {photos.map((photo) => (
        <div key={photo.id}>
          <img src={photo.photo_url} alt={photo.description} />
          {/* Vous pouvez décommenter la ligne suivante pour afficher les légendes des photos */}
          {/* <p className="legend">{photo.description}</p> */}
        </div>
      ))}
    </Carousel>
  );
};

export default Diaporama;
