import React, { useState, useEffect } from 'react';
import { getSettings } from '../../../services/settingsServices'; // Assurez-vous que le chemin est correct
import ComponentTitle from '../../../components/ComponentTitle'; // Importation de ComponentTitle

const ClubHistory = () => {
  const [history, setHistory] = useState({
    photoUrl1: '',
    photoUrl2: '',
    photoUrl3: '',
    title: '',
    content: '',
  });
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const settings = await getSettings();
        const clubSettings = settings[0];
        setHistory({
          photoUrl1: clubSettings.historique_photo_url,
          photoUrl2: clubSettings.historique_photo2_url,
          photoUrl3: clubSettings.historique_photo3_url,
          title: clubSettings.historique_title,
          content: clubSettings.historique_content,
        });
      } catch (error) {
        console.error('Erreur lors de la récupération des données du club:', error);
      }
    };

    fetchSettings();
  }, []);

  // Lancer automatiquement le carrousel
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) =>
        prevIndex === 2 ? 0 : prevIndex + 1
      );
    }, 5000); // Changement toutes les 5 secondes
    return () => clearInterval(interval);
  }, []);

  const images = [history.photoUrl1, history.photoUrl2, history.photoUrl3];

  return (
    <div className="container mx-auto px-4 pt-8 mb-4">
      {/* Utilisation de ComponentTitle avec le mode Dark */}
      <div className="mx-auto max-w-screen-md text-center mb-6 lg:mb-8">
        <ComponentTitle title="Historique" highlight=" du Club" />
      </div>

      <div className="flex flex-col md:flex-row items-center mt-8 bg-white dark:bg-gray-800 shadow-md rounded-lg overflow-hidden border-l-[10px] border-red-500">
        {/* Diaporama des images */}
        <div className="w-full md:w-[60%] h-64 md:h-96 relative">
          <img
            src={images[currentImageIndex]}
            alt="Histoire du Club"
            className="w-full h-full object-cover transition-all duration-500 ease-in-out"
          />
        </div>

        {/* Informations sur l'historique du club */}
        <div className="p-6 flex flex-col justify-center md:w-[40%]">
          <h3 className="text-2xl md:text-3xl text-gray-800 dark:text-gray-100 mb-4 font-passionOne">
            {history.title}
          </h3>
          <div
            className="text-gray-600 dark:text-gray-300 text-sm leading-relaxed"
            dangerouslySetInnerHTML={{ __html: history.content }}
          />
        </div>
      </div>
    </div>
  );
};

export default ClubHistory;
