import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { verifyUser } from "../../services/userServices"; // Fonction pour vérifier l'utilisateur
import backgroundImage from "./HeaderSlider_03.jpg";
import Button from "./../../components/Button/Button";

const LoginPage = () => {
  const [formData, setFormData] = useState({ email: "", password: "" });
  const navigate = useNavigate();

  // Gestion des changements dans le formulaire
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Soumettre le formulaire de connexion
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const user = await verifyUser(formData.email, formData.password);
      console.log("Réponse du backend :", user); // Vérifier la réponse reçue

      if (user) {
        toast.success("Connexion réussie !");
        localStorage.setItem("user", JSON.stringify(user)); // Sauvegarder l'utilisateur
        navigate("/appli/dashboard"); // Redirection vers la page d'application
      } else {
        toast.error("Email ou mot de passe incorrect.");
      }
    } catch (error) {
      console.error("Erreur lors de la vérification de l'utilisateur :", error);
      toast.error("Erreur lors de la connexion. Veuillez réessayer.");
    }
  };

  // Connexion automatique au mode Démonstration
  const handleDemoLogin = async () => {
    const demoCredentials = {
      email: "demo@gmail.com",
      password: "demo",
    };
    try {
      const user = await verifyUser(demoCredentials.email, demoCredentials.password);
      if (user) {
        toast.success("Connexion au mode Démonstration réussie !");
        localStorage.setItem("user", JSON.stringify(user)); // Sauvegarder l'utilisateur démo
        navigate("/appli/dashboard"); // Redirection vers le tableau de bord
      } else {
        toast.error("Impossible de se connecter au mode Démonstration.");
      }
    } catch (error) {
      console.error("Erreur lors de la connexion en mode démo :", error);
      toast.error("Erreur lors de la connexion en mode démo.");
    }
  };

  return (
    <div
      className="min-h-screen flex items-center justify-center bg-cover dark:bg-gray-900"
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <div className="bg-white dark:bg-gray-800 p-6 rounded shadow-md w-full max-w-sm">
        <h2 className="text-2xl font-bold mb-4 text-center text-gray-800 dark:text-gray-100">
          Connexion
        </h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-gray-700 dark:text-gray-300">Email</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 dark:border-gray-600 rounded dark:bg-gray-700 dark:text-gray-200"
              required
            />
          </div>

          <div className="mb-4">
            <label className="block text-gray-700 dark:text-gray-300">Mot de passe</label>
            <input
              type="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 dark:border-gray-600 rounded dark:bg-gray-700 dark:text-gray-200"
              required
            />
          </div>

          <Button text="Se connecter" type="submit" className="w-full mb-4" />
        </form>

        {/* Bouton pour accéder au mode Démonstration */}
       <button
        onClick={handleDemoLogin}
        className="w-full bg-purple-500 hover:bg-purple-600 text-white font-semibold py-2 px-4 rounded-full"
      >
        Accéder au mode Démonstration
      </button>

        <ToastContainer />
      </div>
    </div>
  );
};

export default LoginPage;
