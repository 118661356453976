import React, { useState } from "react";
import { FaSave, FaTimes } from "react-icons/fa";
import Button from "../../../../components/Button/Button"; // Importation du composant Button
import { createGroup } from "../../../../services/groupServices"; // Service pour créer un groupe
import { toast } from "react-toastify";
import Select from "react-select"; // Pour la sélection des couleurs

// Options de couleurs disponibles pour les groupes
const colors = [
  { label: "Bleu", value: "bg-blue-400" },
  { label: "Vert", value: "bg-green-400" },
  { label: "Jaune", value: "bg-yellow-400" },
  { label: "Rouge", value: "bg-red-400" },
  { label: "Indigo", value: "bg-indigo-400" },
  { label: "Violet", value: "bg-purple-400" },
  { label: "Rose", value: "bg-pink-400" },
  { label: "Orange", value: "bg-orange-400" },
  { label: "Cyan", value: "bg-teal-400" },
  { label: "Gris", value: "bg-gray-400" },
  { label: "Noir", value: "bg-black" },
  { label: "Blanc", value: "bg-white" },
];

const CreateGroup = ({ onClose, refreshGroups }) => {
  const [formData, setFormData] = useState({
    nom: "",
    horaire1: "",
    horaire2: "",
    annees_naissance: "",
    tarif_licence: "",
    tarif_cotisation: "",
    ordre_groupe: "",
    quota_groupe: "",
    is_active: true,
    enseignant1: "",
    enseignant2: "",
    color_groupe: colors[0].value,
  });

  // Remise à zéro du formulaire
  const resetForm = () => {
    setFormData({
      nom: "",
      horaire1: "",
      horaire2: "",
      annees_naissance: "",
      tarif_licence: "",
      tarif_cotisation: "",
      ordre_groupe: "",
      quota_groupe: "",
      is_active: true,
      enseignant1: "",
      enseignant2: "",
      color_groupe: colors[0].value,
    });
  };

  // Gestion des changements dans les champs du formulaire
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  // Soumission du formulaire
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const newGroup = await createGroup(formData); // Création du groupe via le service
      toast.success("Groupe créé avec succès");
      resetForm();
      onClose(); // Fermer la modale après création
      refreshGroups(); // Rafraîchir la liste des groupes
    } catch (error) {
      console.error("Erreur lors de la création du groupe", error);
      toast.error("Erreur lors de la création du groupe");
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="modal-box bg-white dark:bg-gray-800 dark:text-gray-200 p-4 rounded-lg shadow-lg max-w-lg w-full">
        <div className="bg-primary text-white p-4 rounded-t-lg mb-6">
          <h2 className="text-xl font-semibold">Créer un nouveau Groupe</h2>
        </div>
        <form onSubmit={handleSubmit} className="space-y-4">
          <input
            type="text"
            className="input input-bordered w-full bg-gray-100 dark:bg-gray-700 dark:border-gray-600 dark:text-gray-200"
            name="nom"
            value={formData.nom}
            onChange={handleChange}
            required
            placeholder="Nom du groupe"
          />

          {/* Message de formatage pour les horaires */}
          <label className="block text-gray-600 dark:text-gray-300 mb-2 text-sm">
            Format attendu pour les horaires : "Jour 17h00-18h00" (ex. Mardi 17h00-18h00)
          </label>

          {/* Champ Horaire 1 */}
          <input
            type="text"
            className="input input-bordered w-full bg-gray-100 dark:bg-gray-700 dark:border-gray-600 dark:text-gray-200"
            name="horaire1"
            value={formData.horaire1}
            onChange={handleChange}
            placeholder="Horaire 1"
          />

          {/* Champ Horaire 2 */}
          <input
            type="text"
            className="input input-bordered w-full bg-gray-100 dark:bg-gray-700 dark:border-gray-600 dark:text-gray-200"
            name="horaire2"
            value={formData.horaire2}
            onChange={handleChange}
            placeholder="Horaire 2 (Optionnel)"
          />

          {/* Autres champs du formulaire */}
          <input
            type="text"
            className="input input-bordered w-full bg-gray-100 dark:bg-gray-700 dark:border-gray-600 dark:text-gray-200"
            name="annees_naissance"
            value={formData.annees_naissance}
            onChange={handleChange}
            placeholder="Années de naissance"
          />
          <input
            type="number"
            className="input input-bordered w-full bg-gray-100 dark:bg-gray-700 dark:border-gray-600 dark:text-gray-200"
            name="tarif_licence"
            value={formData.tarif_licence}
            onChange={handleChange}
            placeholder="Tarif licence (€)"
          />
          <input
            type="number"
            className="input input-bordered w-full bg-gray-100 dark:bg-gray-700 dark:border-gray-600 dark:text-gray-200"
            name="tarif_cotisation"
            value={formData.tarif_cotisation}
            onChange={handleChange}
            placeholder="Tarif cotisation (€)"
          />
          <input
            type="number"
            className="input input-bordered w-full bg-gray-100 dark:bg-gray-700 dark:border-gray-600 dark:text-gray-200"
            name="quota_groupe"
            value={formData.quota_groupe}
            onChange={handleChange}
            placeholder="Quota du groupe"
          />
          <input
            type="number"
            className="input input-bordered w-full bg-gray-100 dark:bg-gray-700 dark:border-gray-600 dark:text-gray-200"
            name="ordre_groupe"
            value={formData.ordre_groupe}
            onChange={handleChange}
            placeholder="Ordre du groupe"
          />
          <input
            type="text"
            className="input input-bordered w-full bg-gray-100 dark:bg-gray-700 dark:border-gray-600 dark:text-gray-200"
            name="enseignant1"
            value={formData.enseignant1}
            onChange={handleChange}
            required
            placeholder="Enseignant 1"
          />
          <input
            type="text"
            className="input input-bordered w-full bg-gray-100 dark:bg-gray-700 dark:border-gray-600 dark:text-gray-200"
            name="enseignant2"
            value={formData.enseignant2}
            onChange={handleChange}
            placeholder="Enseignant 2"
          />
          <label className="flex items-center space-x-2">
            <span className="label-text dark:text-gray-300">Groupe actif ?</span>
            <input
              type="checkbox"
              className="toggle toggle-primary"
              name="is_active"
              checked={formData.is_active}
              onChange={handleChange}
            />
          </label>
          <label className="flex items-center space-x-2">
            <span className="label-text dark:text-gray-300">Couleur du groupe</span>
            <Select
              name="color_groupe"
              value={colors.find(
                (color) => color.value === formData.color_groupe
              )}
              onChange={(selectedOption) =>
                setFormData((prev) => ({
                  ...prev,
                  color_groupe: selectedOption.value,
                }))
              }
              options={colors}
              getOptionLabel={(option) => (
                <div className="flex items-center">
                  <span
                    className={`w-4 h-4 inline-block rounded-full ${option.value} mr-2`}
                  ></span>
                  {option.label}
                </div>
              )}
              getOptionValue={(option) => option.value}
              styles={{
                control: (provided) => ({
                  ...provided,
                  backgroundColor: "white",
                }),
                singleValue: (provided) => ({
                  ...provided,
                  display: "flex",
                  alignItems: "center",
                }),
                option: (provided, state) => ({
                  ...provided,
                  backgroundColor: state.data.value,
                  color: state.isSelected ? "white" : "black",
                }),
              }}
            />
          </label>
          <div className="flex justify-center space-x-4 mt-4">
            <Button
              text="Annuler"
              icon={FaTimes}
              onClick={() => {
                onClose();
                resetForm();
              }}
              className="btn-ghost gap-2"
            />
            <Button
              text="Enregistrer"
              icon={FaSave}
              type="submit"
              className="btn-primary gap-2"
            />
            
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateGroup;
