import React, { useState } from "react";
import Button from "../../../../components/Button/Button";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { createNews } from "../../../../services/newsServices"; // Importer createNews
import { toast } from "react-toastify";
import "./customQuillStyles.css"; // Importer le fichier CSS personnalisé
import { FaSave, FaTimes } from "react-icons/fa"; // Icônes

const CreateNewsModale = ({ onClose, refreshData }) => {
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [isActive, setIsActive] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // État pour gérer le chargement

  const handleSave = async () => {
    setIsLoading(true);

    const newArticle = {
      title,
      content,
      image_url: imageUrl,
      is_active: isActive,
      author_id: 1, // ID de l'auteur à remplacer par l'ID de l'utilisateur connecté
    };

    console.log("Données envoyées :", newArticle); // Vérifiez les données dans la console

    try {
      const response = await createNews(newArticle);
      toast.success("Actualité créée avec succès.");
      refreshData();
      onClose();
    } catch (error) {
      console.error("Erreur lors de la création de l'actualité :", error);
      toast.error("Erreur lors de la création de l'actualité.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-30 flex justify-center items-center z-50">
      <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg w-full max-w-2xl">
        {/* Bandeau en haut */}
        <div className="bg-primary text-white p-4 rounded-t-lg">
          <h2 className="text-xl font-semibold">Créer une Actualité</h2>
        </div>

        {/* Contenu de la modale */}
        <div className="p-6 dark:text-gray-200">
          <div className="mb-4">
            <label className="block font-semibold mb-2">Titre de l'actualité</label>
            <input
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              className="w-full p-2 border border-gray-300 dark:border-gray-600 rounded-md dark:bg-gray-700 dark:text-gray-200"
              placeholder="Titre de l'actualité"
            />
          </div>

          <div className="mb-4">
            <label className="block font-semibold mb-2">Contenu de l'actualité</label>
            <ReactQuill value={content} onChange={setContent} className="h-40 mb-4 dark:bg-gray-700" />
          </div>

          <div className="mb-4">
            <label className="block font-semibold mb-2 mt-12">URL de l'image</label>
            <input
              type="text"
              value={imageUrl}
              onChange={(e) => setImageUrl(e.target.value)}
              className="w-full p-2 border border-gray-300 dark:border-gray-600 rounded-md dark:bg-gray-700 dark:text-gray-200"
              placeholder="URL de l'image"
            />
          </div>

          <div className="mb-4 flex items-center">
            <input
              type="checkbox"
              checked={isActive}
              onChange={(e) => setIsActive(e.target.checked)}
              className="mr-2"
            />
            <label className="text-gray-700 dark:text-gray-200">Activer l'actualité</label>
          </div>

          {/* Boutons */}
          <div className="flex justify-end gap-2">
            <Button text="Annuler" onClick={onClose} disabled={isLoading} icon={FaTimes} className="gap-2" />
            <Button text="Créer" onClick={handleSave} disabled={isLoading} icon={FaSave}  className="gap-2" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateNewsModale;
