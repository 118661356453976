import axios from "axios";

const apiUrl = `${process.env.REACT_APP_API_URL}/api/disciplines`;
const token = localStorage.getItem("token");

/// GET
// Fonction pour récupérer toutes les disciplines
export const getAllDisciplines = async () => {
  try {
    const response = await axios.get(`${apiUrl}/allDisciplines`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status !== 200) throw new Error("Failed to fetch data");
    return response.data; // Renvoie directement les données
  } catch (error) {
    console.error("Erreur lors du chargement des disciplines:", error);
    return []; // Renvoie un tableau vide en cas d'échec pour maintenir la cohérence du type de retour
  }
};

/// POST
// Fonction pour ajouter une discipline
export const addDiscipline = async (newDiscipline) => {
  try {
    const response = await axios.post(
      `${apiUrl}/addDiscipline`,
      newDiscipline,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response.status !== 201)
      throw new Error("Failed to add new discipline");
    return response.data; // Renvoie directement les données
  } catch (error) {
    console.error("Erreur lors de l'ajout d'une discipline:", error);
    return null; // Renvoie null en cas d'échec pour maintenir la cohérence du type de retour
  }
};

/// PUT
// Fonction pour modifier une discipline avec son id
export const updateDiscipline = async (discipline) => {
  try {
    const response = await axios.put(
      `${apiUrl}/updateDiscipline/${discipline.id}`,
      discipline,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response.status !== 200) throw new Error("Failed to update discipline");
    return response.data; // Renvoie directement les données
  } catch (error) {
    console.error("Erreur lors de la modification d'une discipline:", error);
    return null; // Renvoie null en cas d'échec pour maintenir la cohérence du type de retour
  }
};

// Modifier l'ordre des disciplines
export const updateOrderDiscipline = async (disciplines) => {
  try {
    const response = await axios.put(
      `${apiUrl}/updateOrderDiscipline`,
      { disciplines },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response.status !== 200)
      throw new Error("Failed to update discipline order");
    return response.data; // Renvoie directement les données
  } catch (error) {
    console.error(
      "Erreur lors de la modification de l'ordre des disciplines:",
      error
    );
    return null; // Renvoie null en cas d'échec pour maintenir la cohérence du type de retour
  }
};

/// PATCH
// Fonction pour update les photos de la discipline
export const updateDisciplinePhoto = async (disciplineId, file, token) => {
  const formData = new FormData();
  formData.append("image", file);

  try {
    const response = await axios.patch(
      `${apiUrl}/${disciplineId}/photo`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );
    if (response.status !== 200)
      throw new Error("Failed to update discipline photo");

    return response.data; // Renvoie directement les données
  } catch (error) {
    console.error(
      "Erreur lors de la modification de la photo de la discipline:",
      error
    );
    return "";
  }
};

/// DELETE
// Fonction pour supprimer une discipline avec son id
export const deleteDiscipline = async (id) => {
  try {
    const response = await axios.delete(`${apiUrl}/deleteDiscipline/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status !== 200) throw new Error("Failed to delete discipline");
    return response.data; // Renvoie directement les données
  } catch (error) {
    console.error("Erreur lors de la suppression d'une discipline:", error);
    return null; // Renvoie null en cas d'échec pour maintenir la cohérence du type de retour
  }
};
