import React, { useState, useEffect } from "react";
import {
  getAllNotifications,
  deleteNotificationById,
  markNotificationAsRead,
} from "../../../../services/notificationServices";
import { useNotification } from "../../../../contexts/NotificationContext";
import { FaBell } from "react-icons/fa";
import { toast } from "react-toastify";
import ButtonEyeNoRead from "./../../../../components/Button/ButtonEyeNoRead";
import ButtonEyeRead from "./../../../../components/Button/ButtonEyeRead";
import ButtonDelete from "./../../../../components/Button/ButtonDelete";
import "./styles.css";

const NotificationsDisplay = () => {
  const [notifications, setNotifications] = useState([]);
  const { unreadCount, setUnreadCount } = useNotification();
  const [isDemoMode, setIsDemoMode] = useState(false); // État pour vérifier si l'utilisateur est en mode démo

  // Fonction pour vérifier le role_id dans localStorage
  const checkUserRole = () => {
    const user = JSON.parse(localStorage.getItem('user')); // Récupérer l'utilisateur depuis localStorage
    if (user && user.role_id === 4) {
      setIsDemoMode(true); // Activer le mode démo si role_id est 4
    }
  };

  // Charger toutes les notifications
  const loadNotifications = async () => {
    try {
      const data = await getAllNotifications();
      setNotifications(data);

      // Mettre à jour le nombre de notifications non lues
      const unreadNotifications = data.filter(
        (notification) => !notification.read_notification
      );
      setUnreadCount(unreadNotifications.length);
    } catch (error) {
      console.error("Erreur lors du chargement des notifications:", error);
      toast.error("Erreur lors du chargement des notifications.");
    }
  };

  useEffect(() => {
    loadNotifications();
    checkUserRole(); // Vérifier le rôle de l'utilisateur au chargement du composant
  }, []);

  // Afficher un message si en mode démo
  const handleDemoClick = () => {
    if (isDemoMode) {
      toast.info('Fonction désactivée en mode Démonstration');
      return true; // Indique que l'action est désactivée en mode démo
    }
    return false; // Permet l'action si pas en mode démo
  };

  const handleDeleteNotification = async (notificationId) => {
    if (handleDemoClick()) return; // Bloquer l'action en mode démo
    try {
      await deleteNotificationById(notificationId);
      loadNotifications(); // Recharger les notifications après la suppression
      toast.success("Notification supprimée avec succès.");
    } catch (error) {
      console.error("Erreur lors de la suppression de la notification:", error);
      toast.error("Erreur lors de la suppression de la notification.");
    }
  };

  const toggleReadStatus = async (notificationId) => {
    if (handleDemoClick()) return; // Bloquer l'action en mode démo
    try {
      const notification = notifications.find((n) => n.id === notificationId);
      if (!notification) {
        console.error("Notification non trouvée");
        return;
      }

      const currentReadStatus = notification.read_notification;

      await markNotificationAsRead(notificationId);

      setNotifications((prevNotifications) =>
        prevNotifications.map((n) =>
          n.id === notificationId
            ? { ...n, read_notification: !n.read_notification }
            : n
        )
      );

      setUnreadCount((prevCount) =>
        currentReadStatus ? prevCount + 1 : prevCount - 1
      );
    } catch (error) {
      console.error("Erreur lors du basculement de l'état de lecture:", error);
    }
  };

  return (
    <div className="overflow-x-auto shadow-xl font-montserrat dark:bg-gray-800 dark:text-white">
      <div className="flex justify-between">
        <h3 className="card-title mb-2 ml-2 text-gray-900 dark:text-white mt-4">
          <div className="relative">
            <FaBell className="icon" title="Notifications" />
            {/* Toujours afficher le badge même si unreadCount est 0 */}
            <span className="absolute -top-2 -right-3 bg-primary text-white text-xs rounded-full px-2 py-0.5">
              {unreadCount}
            </span>
          </div>
          <span className="ml-2">Notifications</span>
        </h3>
      </div>
      {notifications.length > 0 ? (
        <div className="overflow-x-auto">
          <table className="min-w-full bg-gray-50 dark:bg-gray-800 divide-y divide-gray-400 dark:divide-gray-600">
            <thead className="bg-gray-50 dark:bg-gray-700">
              <tr className="bg-slate-400 dark:bg-gray-900">
                <th
                  scope="col"
                  className="px-2 py-2 text-left text-xs font-medium uppercase tracking-wider text-white"
                >
                  Date
                </th>
                <th
                  scope="col"
                  className="px-2 py-2 text-left text-xs font-medium uppercase tracking-wider text-white"
                >
                  Notification
                </th>
                <th
                  scope="col"
                  className="px-2 py-2 text-left text-xs font-medium uppercase tracking-wider text-white"
                >
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-300 dark:divide-gray-600">
              {notifications.map((notification) => (
                <tr
                  key={notification.id}
                  className={`hover:bg-gray-300 dark:hover:bg-gray-700 ${
                    notification.read_notification
                      ? "bg-gray-200 dark:bg-gray-600"
                      : "bg-gray-100 dark:bg-gray-800"
                  }`}
                >
                  <td className="px-2 py-2 whitespace-nowrap text-sm text-gray-900 dark:text-gray-100">
                    {new Date(notification.created_at).toLocaleDateString(
                      "fr-FR",
                      {
                        year: "2-digit",
                        month: "2-digit",
                        day: "2-digit",
                      }
                    )}
                  </td>
                  <td className="px-4 py-2 text-sm text-gray-500 dark:text-gray-300 max-w-xs overflow-hidden text-ellipsis">
                    {notification.message}
                  </td>
                  <td className="px-4 py-2 whitespace-nowrap text-right text-sm font-medium gap-2">
                    <div className="flex justify-center items-center w-full h-full gap-2">
                    {notification.read_notification ? (
                      <ButtonEyeRead
                        onClick={() => toggleReadStatus(notification.id)}
                        hoverLabel="Marquer comme non lu"
                      />
                    ) : (
                      <ButtonEyeNoRead
                        onClick={() => toggleReadStatus(notification.id)}
                        hoverLabel="Marquer comme lu"
                      />
                    )}
                    <ButtonDelete
                      onClick={() =>
                        handleDeleteNotification(notification.id)
                      }
                      hoverLabel="Supprimer"
                    />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <p className="text-center text-gray-900 dark:text-gray-100">
          Pas encore de notifications.
        </p>
      )}
    </div>
  );
};

export default NotificationsDisplay;
