import React, { useState, useEffect } from "react";
import { getSettings } from "../../../services/settingsServices"; 
import ComponentTitle from "./../../../components/ComponentTitle";

const About = () => {
  const [settings, setSettings] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const data = await getSettings();
        if (data.length > 0) {
          setSettings(data[0]);
        }
        setLoading(false);
      } catch (error) {
        console.error("Erreur lors de la récupération des paramètres:", error);
        setLoading(false);
      }
    };

    fetchSettings();
  }, []);

  if (loading) return <div>Chargement...</div>;

  // Fonction pour séparer le titre en deux parties avec les deux derniers mots comme highlight
  const splitTitle = (title) => {
    const words = title.split(' '); // Séparer le titre en mots
    if (words.length > 2) {
      const titlePart = words.slice(0, words.length - 2).join(' '); // Tous les mots sauf les deux derniers
      const highlightPart = ' ' + words.slice(-2).join(' '); // Ajouter un espace avant les deux derniers mots
      return { title: titlePart, highlight: highlightPart };
    }
    return { title: title, highlight: '' }; // Si moins de 3 mots, pas de highlight spécifique
  };

  const { title, highlight } = splitTitle(settings.about_title);

  return (
    <section id="about" className="py-16 bg-gray-200 dark:bg-gray-800 text-gray-800 dark:text-gray-200">
      <div className="container mx-auto px-4">
        <div className="mx-auto max-w-screen-md text-center mb-8 lg:mb-12">
          {/* Utilisation de ComponentTitle avec le titre et le highlight */}
          <ComponentTitle title={title} highlight={highlight} />
        </div>
        <div className="container mx-auto px-4">
          <div className="flex flex-wrap -mx-4 overflow-hidden">
            <div className="px-4 w-full lg:w-1/3 overflow-hidden my-4">
              <img
                src={settings.logo_club}
                alt="Logo Club"
                className="block mx-auto drop-shadow-md"
              />
            </div>
            <div className="font-montserrat px-8 w-full lg:w-2/3 overflow-hidden my-4">
              <div
                className="text-justify mb-4 leading-relaxed dark:text-gray-300"
                dangerouslySetInnerHTML={{ __html: settings.about_content }}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
