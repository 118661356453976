import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";

// Importation des pages correspondantes aux menus
import Dashboard from "./AppliAdmin/Dashboard/Dashboard"; // Tableau de bord
import Actualites from "./AppliAdmin/Actualites/Actualites"; // Actualités
import AlbumsPhotos from "./AppliAdmin/AlbumsPhotos/AlbumsPhotos"; // Albums Photos
import Partenaires from "./AppliAdmin/Partenaires/Partenaires"; // Partenaires
import Disciplines from "./AppliAdmin/Disciplines/Disciplines"; // Disciplines
import Groupes from "./AppliAdmin/Groupes/Groupes"; // Groupes
import LeClub from "./AppliAdmin/LeClub/LeClub"; // Le Club
import Inscriptions from "./AppliAdmin/Inscriptions/Inscriptions"; // Inscriptions
import Parametres from "./AppliAdmin/Parametres/Parametres"; // Paramètres
import ErrorPage from "../ErrorPage/ErrorPage"; // Page d'erreur

const MainContent = ({ isMenuOpen }) => {
  return (
    <div
      className={`${
        isMenuOpen ? "ml-72" : "ml-20"
      } flex-1 p-4 bg-gray-100 dark:bg-gray-800 overflow-y-auto h-screen transition-all duration-300`}
    >
      <Routes>
        {/* Routes correspondant aux menus */}
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/actualites" element={<Actualites />} />
        <Route path="/albums-photos" element={<AlbumsPhotos />} />
        <Route path="/partenaires" element={<Partenaires />} />
        <Route path="/disciplines" element={<Disciplines />} />
        <Route path="/groupes" element={<Groupes />} />
        <Route path="/le-club" element={<LeClub />} />
        <Route path="/inscriptions" element={<Inscriptions />} />
        <Route path="/parametres" element={<Parametres />} />

        {/* Route par défaut pour les pages non trouvées */}
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </div>
  );
};

export default MainContent;
