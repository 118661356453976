import React, { useState } from "react";
import Button from "../../../../components/Button/Button";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { updateDiscipline } from "../../../../services/disciplinesServices"; // Importer updateDiscipline
import { toast } from "react-toastify";
import { FaSave, FaTimes } from "react-icons/fa"; // Icônes

const EditDisciplineModale = ({ discipline, onClose, onSave }) => {
  const [nomDiscipline, setNomDiscipline] = useState(discipline.nom_discipline);
  const [descriptionDiscipline, setDescriptionDiscipline] = useState(discipline.description_discipline);
  const [contenuDiscipline, setContenuDiscipline] = useState(discipline.contenu_discipline); // Texte enrichi
  const [imageDiscipline, setImageDiscipline] = useState(discipline.image_discipline);
  const [ordreDiscipline, setOrdreDiscipline] = useState(discipline.ordre_discipline);
  const [isLoading, setIsLoading] = useState(false); // Gérer le chargement

  const handleSave = async () => {
    setIsLoading(true); // Activer l'état de chargement

    const updatedDiscipline = {
      ...discipline,
      nom_discipline: nomDiscipline,
      description_discipline: descriptionDiscipline,
      contenu_discipline: contenuDiscipline,
      image_discipline: imageDiscipline,
      ordre_discipline: ordreDiscipline,
    };

    try {
      await updateDiscipline(updatedDiscipline); // Appel pour mettre à jour la discipline
      onSave(updatedDiscipline); // Appeler la fonction de sauvegarde après succès
      toast.success("Discipline modifiée avec succès.");
    } catch (error) {
      console.error("Erreur lors de la mise à jour de la discipline :", error);
      toast.error("Erreur lors de la mise à jour de la discipline.");
    } finally {
      setIsLoading(false); // Désactiver l'état de chargement
      onClose(); // Fermer la modale
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-30 flex justify-center items-center z-50">
      <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg w-full max-w-2xl">
        {/* Bandeau en haut */}
        <div className="bg-primary dark:bg-gray-900 text-white p-4 rounded-t-lg">
          <h2 className="text-xl font-semibold">Modifier une Discipline</h2>
        </div>

        {/* Contenu de la modale */}
        <div className="p-6">
          <div className="mb-4">
            <label className="block font-semibold mb-2 dark:text-gray-200">Nom de la discipline</label>
            <input
              type="text"
              value={nomDiscipline}
              onChange={(e) => setNomDiscipline(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600 dark:text-gray-200"
            />
          </div>

          <div className="mb-4">
            <label className="block font-semibold mb-2 dark:text-gray-200">Description de la discipline</label>
            <input
              type="text"
              value={descriptionDiscipline}
              onChange={(e) => setDescriptionDiscipline(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600 dark:text-gray-200"
            />
          </div>

          <div className="mb-4">
            <label className="block font-semibold mb-2 dark:text-gray-200">Contenu de la discipline</label>
            <ReactQuill
              value={contenuDiscipline}
              onChange={setContenuDiscipline}
              className="h-40 mb-4 dark:bg-gray-700 dark:text-gray-200"
            />
          </div>

          <div className="mb-4">
            <label className="block font-semibold mb-2 dark:text-gray-200 mt-14">URL de l'image</label>
            <input
              type="text"
              value={imageDiscipline}
              onChange={(e) => setImageDiscipline(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600 dark:text-gray-200"
            />
          </div>

          <div className="mb-4">
            <label className="block font-semibold mb-2 dark:text-gray-200">Ordre</label>
            <input
              type="number"
              value={ordreDiscipline}
              onChange={(e) => setOrdreDiscipline(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600 dark:text-gray-200"
            />
          </div>

          {/* Boutons */}
          <div className="flex justify-end gap-2">
            <Button text="Annuler" onClick={onClose} disabled={isLoading} icon={FaTimes} className="gap-2" />
            <Button text="Modifier" onClick={handleSave} disabled={isLoading} icon={FaSave}  className="gap-2" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditDisciplineModale;
