import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
  useLocation,
  Navigate,
} from "react-router-dom";

import HomePage from "./pages/Home/HomePage/HomePage.jsx";
import LoginPage from "./pages/LoginPage/LoginPage.jsx";

import { useUser, UserProvider } from "./contexts/UserContext";
import PrimaryColorProvider from "./hooks/PrimaryColorProvider.jsx";

import Navbar from "./pages/Home/Navbar/Navbar.jsx";

// Home
import ClubPage from "./pages/Home/ClubPage/ClubPage";
import HorairesTarifsPage from "./pages/Home/HorairesTarifsPage/HorairesTarifsPage";
import InscriptionPage from "./pages/Home/InscriptionPage/InscriptionPage";

// Appli
import AsideMenu from "./pages/Appli/AsideMenu";
import MainContent from "./pages/Appli/MainContent";

import ErrorPage from "./pages/ErrorPage/ErrorPage";

// Documents
import ReglementInterieur from "./pages/Documents/ReglementInterieur";
import ConditionsUtilisationPage from "./pages/rgpd/ConditionsUtilisationPage.jsx";
import PolitiqueConfidentialitePage from "./pages/rgpd/PolitiqueConfidentialitePage.jsx";
import MentionsLegalesPage from "./pages/rgpd/MentionsLegalesPage.jsx";
import ReglesConfidentialitesTableauDeScoreJudo from "./pages/rgpd/ReglesConfidentialitesTableauDeScoreJudo.jsx";

// Context Providers
import { NotificationProvider } from "./contexts/NotificationContext";
import { ContactFormProvider } from "./contexts/ContactFormContext";
import { AsideMenuProvider } from "./contexts/AsideMenuContext";
import { useAsideMenu } from "./contexts/AsideMenuContext";

import "./App.css";

// Fonction pour vérifier si l'utilisateur est authentifié en vérifiant le localStorage
const isAuthenticated = () => {
  const storedUser = localStorage.getItem("user");
  return storedUser ? JSON.parse(storedUser) : null;
};

// Composant pour protéger les routes
const RequireAuth = ({ children }) => {
  const location = useLocation();
  const user = isAuthenticated(); // Récupérer l'utilisateur à partir du localStorage

  if (!user) {
    // Redirige vers la page de connexion si l'utilisateur n'est pas authentifié
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
};

const AppContent = () => {
  const { user } = useUser(); // Accéder aux informations utilisateur via UserContext
  const location = useLocation();
  const { isOpen } = useAsideMenu();

  return (
    <div className="flex flex-col min-h-screen">
      {!location.pathname.startsWith("/appli") && <Navbar user={user} />}
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/login" element={<LoginPage />} />

        {/* Routes de l'application protégées */}
        <Route
          path="/appli/*"
          element={
            <RequireAuth>
              <div className="flex">
                <AsideMenu />
                <MainContent isMenuOpen={isOpen} />
              </div>
            </RequireAuth>
          }
        />

        {/* Routes publiques */}
        <Route path="/club" element={<ClubPage />} />
        <Route path="/horaires-tarifs" element={<HorairesTarifsPage />} />
        <Route path="/inscription" element={<InscriptionPage />} />

        {/* Pages d'erreur et documents */}
        <Route path="/404" element={<ErrorPage />} />
        <Route path="*" element={<ErrorPage />} />
        <Route path="/reglement-interieur" element={<ReglementInterieur />} />
        <Route
          path="/conditions-utilisation"
          element={<ConditionsUtilisationPage />}
        />
        <Route
          path="/politique-confidentialite"
          element={<PolitiqueConfidentialitePage />}
        />
        <Route path="/mentions-legales" element={<MentionsLegalesPage />} />
        <Route
          path="/regles-confidentialites-tableau-de-score-judo"
          element={<ReglesConfidentialitesTableauDeScoreJudo />}
        />
      </Routes>
    </div>
  );
};

const App = () => {
  return (
    <UserProvider>
      <Router>
        <NotificationProvider>
          <ContactFormProvider>
            <AsideMenuProvider>
              <PrimaryColorProvider>
                <div className="flex flex-col min-h-screen bg-base-200 dark:bg-gray-600 dark:text-gray-200">
                  <AppContent />
                </div>
              </PrimaryColorProvider>
            </AsideMenuProvider>
          </ContactFormProvider>
        </NotificationProvider>
      </Router>
    </UserProvider>
  );
};

export default App;
