import React, { useEffect, useState } from "react";
import {
  getSettings,
  updateSettings,
} from "../../../../services/settingsServices"; // Import des services
import { getAllGalleries } from "../../../../services/gallerieServices"; // Import des services
import Button from "../../../../components/Button/Button"; // Import du composant Button
import { toast } from "react-toastify";
import ReactQuill from "react-quill"; // Import du composant React-Quill
import "react-quill/dist/quill.snow.css"; // Import du CSS de React-Quill

const ParametresClub = () => {
  const [settings, setSettings] = useState({
    nom_club: "",
    shortname_club: "",
    responsable_club: "",
    logo_club: "",
    adresse_club: "",
    commune_club: "",
    tel_club: "",
    mail_club: "",
    siteweb_club: "",
    siret_club: "",
    facebook_club: "",
    instagram_club: "",
    primary_color: "#000000", // Valeur par défaut pour la couleur
    about_title: "",
    about_content: "",
    carrousel_id: "",
    header1_photo_url: "",
    header2_photo_url: "",
    header_title: "",
    header_subtitle: "",
  });

  const [isLoading, setIsLoading] = useState(false);
  const [isDemoMode, setIsDemoMode] = useState(false);
  const [galleries, setGalleries] = useState([]);

  // Vérifier si l'utilisateur est en mode démo via localStorage
  const checkDemoMode = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user && user.role_id === 4) {
      setIsDemoMode(true); // Mode démo activé
    }
  };

  useEffect(() => {
    const fetchSettingsAndGalleries = async () => {
      try {
        const [settingsData, galleriesData] = await Promise.all([
          getSettings(),
          getAllGalleries(),
        ]);
        if (settingsData.length > 0) {
          setSettings(settingsData[0]);
        }
        setGalleries(galleriesData);
      } catch (error) {
        console.error("Erreur lors de la récupération des données:", error);
      }
      setIsLoading(false);
    };

    fetchSettingsAndGalleries();
    checkDemoMode(); // Vérifier si l'utilisateur est en mode démo
  }, []);

  // Gestion des changements dans les inputs
  const handleChange = (e) => {
    const { name, value } = e.target;
    setSettings((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // Gestion de l'édition du contenu avec React-Quill
  const handleQuillChange = (value) => {
    setSettings((prev) => ({
      ...prev,
      about_content: value,
    }));
  };

  const handleQuillChange2 = (value) => {
    setSettings((prev) => ({
      ...prev,
      header_title: value,
    }));
  };

  // Soumission du formulaire pour mettre à jour les paramètres
  const handleSubmit = async (e) => {
    e.preventDefault();
     if (isDemoMode) {
      toast.info("Fonction désactivée en mode Démonstration"); // Bloquer l'action en mode démo
      return;
    }
    setIsLoading(true);
    try {
      await updateSettings(settings); // Mise à jour des paramètres via l'API
      toast.success("Paramètres du club mis à jour avec succès !");
    } catch (error) {
      console.error(
        "Erreur lors de la mise à jour des paramètres du club :",
        error
      );
      toast.error("Erreur lors de la mise à jour des paramètres du club.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="p-6 bg-white dark:bg-gray-800 dark:text-gray-200 rounded-md shadow-md mt-8">
      <h2 className="text-2xl font-semibold mb-2">Paramètres du Club</h2>
      <p className="mb-4">Pensez à bien enregistrer après changement avec le bouton en bas de la page</p>

      <form onSubmit={handleSubmit} className="space-y-4">
        {/* Section 1: Désignation du club et responsables */}
        <div className="p-4 border bg-slate-300 dark:bg-gray-800 mb-8">
          <h3 className="text-xl font-semibold mb-4">
            Désignation du Club et Responsables
          </h3>
          <div className="grid gap-4 md:grid-cols-2">
            <div>
              <label className="block font-semibold mb-2">Nom du Club</label>
              <input
                type="text"
                name="nom_club"
                value={settings.nom_club}
                onChange={handleChange}
                className="w-full p-2 border border-gray-300 dark:border-gray-600 rounded-md dark:bg-gray-700 dark:text-gray-200"
                placeholder="Entrez le nom du club"
              />
            </div>

            <div>
              <label className="block font-semibold mb-2">
                Nom Court du Club
              </label>
              <input
                type="text"
                name="shortname_club"
                value={settings.shortname_club}
                onChange={handleChange}
                className="w-full p-2 border border-gray-300 dark:border-gray-600 rounded-md dark:bg-gray-700 dark:text-gray-200"
                placeholder="Entrez le nom court du club"
              />
            </div>

            <div>
              <label className="block font-semibold mb-2">
                Responsable du Club
              </label>
              <input
                type="text"
                name="responsable_club"
                value={settings.responsable_club}
                onChange={handleChange}
                className="w-full p-2 border border-gray-300 dark:border-gray-600 rounded-md dark:bg-gray-700 dark:text-gray-200"
                placeholder="Entrez le nom du responsable"
              />
            </div>

            <div>
              <label className="block font-semibold mb-2">SIRET du Club</label>
              <input
                type="text"
                name="siret_club"
                value={settings.siret_club}
                onChange={handleChange}
                className="w-full p-2 border border-gray-300 dark:border-gray-600 rounded-md dark:bg-gray-700 dark:text-gray-200"
                placeholder="Entrez le SIRET"
              />
            </div>

            <div>
              <label className="block font-semibold mb-2">
                Logo du Club (URL)
              </label>
              <input
                type="text"
                name="logo_club"
                value={settings.logo_club}
                onChange={handleChange}
                className="w-full p-2 border border-gray-300 dark:border-gray-600 rounded-md dark:bg-gray-700 dark:text-gray-200"
                placeholder="Entrez l'URL du logo"
              />
              {settings.logo_club && (
                <div className="mt-2">
                  <img
                    src={settings.logo_club}
                    alt="Logo du club"
                    className="max-w-xs h-auto rounded-md"
                  />
                </div>
              )}
            </div>

            
          </div>
        </div>

        {/* Section 2: Coordonnées et adresses du club */}
        <div className="p-4 border bg-slate-300 dark:bg-gray-800 mb-8">
          <h3 className="text-xl font-semibold mb-4 ">
            Coordonnées et Adresses du Club
          </h3>
          <div className="grid gap-4 md:grid-cols-2">
            <div>
              <label className="block font-semibold mb-2">
                Adresse du Club
              </label>
              <input
                type="text"
                name="adresse_club"
                value={settings.adresse_club}
                onChange={handleChange}
                className="w-full p-2 border border-gray-300 dark:border-gray-600 rounded-md dark:bg-gray-700 dark:text-gray-200"
                placeholder="Entrez l'adresse du club"
              />
            </div>

            <div>
              <label className="block font-semibold mb-2">
                Commune du Club
              </label>
              <input
                type="text"
                name="commune_club"
                value={settings.commune_club}
                onChange={handleChange}
                className="w-full p-2 border border-gray-300 dark:border-gray-600 rounded-md dark:bg-gray-700 dark:text-gray-200"
                placeholder="Entrez la commune"
              />
            </div>

            <div>
              <label className="block font-semibold mb-2">
                Téléphone du Club
              </label>
              <input
                type="text"
                name="tel_club"
                value={settings.tel_club}
                onChange={handleChange}
                className="w-full p-2 border border-gray-300 dark:border-gray-600 rounded-md dark:bg-gray-700 dark:text-gray-200"
                placeholder="Entrez le téléphone"
              />
            </div>

            <div>
              <label className="block font-semibold mb-2">Email du Club</label>
              <input
                type="text"
                name="mail_club"
                value={settings.mail_club}
                onChange={handleChange}
                className="w-full p-2 border border-gray-300 dark:border-gray-600 rounded-md dark:bg-gray-700 dark:text-gray-200"
                placeholder="Entrez l'email"
              />
            </div>

            <div>
              <label className="block font-semibold mb-2">
                Site Web du Club
              </label>
              <input
                type="text"
                name="siteweb_club"
                value={settings.siteweb_club}
                onChange={handleChange}
                className="w-full p-2 border border-gray-300 dark:border-gray-600 rounded-md dark:bg-gray-700 dark:text-gray-200"
                placeholder="Entrez le site web"
              />
            </div>

            <div>
              <label className="block font-semibold mb-2">Page Facebook</label>
              <input
                type="text"
                name="facebook_club"
                value={settings.facebook_club}
                onChange={handleChange}
                className="w-full p-2 border border-gray-300 dark:border-gray-600 rounded-md dark:bg-gray-700 dark:text-gray-200"
                placeholder="Entrez l'URL de la page Facebook"
              />
            </div>

            <div>
              <label className="block font-semibold mb-2">Page Instagram</label>
              <input
                type="text"
                name="instagram_club"
                value={settings.instagram_club}
                onChange={handleChange}
                className="w-full p-2 border border-gray-300 dark:border-gray-600 rounded-md dark:bg-gray-700 dark:text-gray-200"
                placeholder="Entrez l'URL de la page Instagram"
              />
            </div>

            <div className="flex flex-col">
              <label className="text-sm font-medium text-gray-700 dark:text-gray-200 mb-1">
                Couleur Primaire
              </label>
              <input
                type="color"
                name="primary_color"
                value={settings.primary_color}
                onChange={handleChange}
                className="input input-bordered w-full h-10 p-1 dark:bg-gray-700 dark:text-gray-200"
              />
            </div>
          </div>
        </div>

        {/* Section 3: Informations Page Accueil */}
        <div className="p-4 border bg-slate-300 dark:bg-gray-800 mb-8 mt-8">
          <h3 className="text-xl font-semibold mb-4">
            Informations Page Accueil
          </h3>
          <div className="grid gap-4">
            <div>
              <label className="block font-semibold mb-2">
                Lien Photo Header1
              </label>
              <input
                type="text"
                name="header1_photo_url"
                value={settings.header1_photo_url}
                onChange={handleChange}
                className="w-full p-2 border border-gray-300 dark:border-gray-600 rounded-md dark:bg-gray-700 dark:text-gray-200"
                placeholder="Entrez l'URL de la photo Header1"
              />
            </div>

            <div>
              <label className="block font-semibold mb-2">
                Lien Photo Header2
              </label>
              <input
                type="text"
                name="header2_photo_url"
                value={settings.header2_photo_url}
                onChange={handleChange}
                className="w-full p-2 border border-gray-300 dark:border-gray-600 rounded-md dark:bg-gray-700 dark:text-gray-200"
                placeholder="Entrez l'URL de la photo Header2"
              />
            </div>

            <div>
              <label className="block font-semibold mb-2">Titre Header</label>
              <ReactQuill
                value={settings.header_title}
                onChange={handleQuillChange2}
                className="bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-md dark:text-gray-200"
              />
            </div>

            <div>
              <label className="block font-semibold mb-2">
                Sous-titre Header
              </label>
              <input
                type="text"
                name="header_subtitle"
                value={settings.header_subtitle}
                onChange={handleChange}
                className="w-full p-2 border border-gray-300 dark:border-gray-600 rounded-md dark:bg-gray-700 dark:text-gray-200"
                placeholder="Entrez le sous-titre du header"
              />
            </div>

            <div>
              <label className="block font-semibold mb-2">
                Titre de la Section À propos
              </label>
              <input
                type="text"
                name="about_title"
                value={settings.about_title}
                onChange={handleChange}
                className="w-full p-2 border border-gray-300 dark:border-gray-600 rounded-md dark:bg-gray-700 dark:text-gray-200"
                placeholder="Entrez le titre de la section À propos"
              />
            </div>

            <div>
              <label className="block font-semibold mb-2">
                Contenu de la Section À propos
              </label>
              <ReactQuill
                value={settings.about_content}
                onChange={handleQuillChange}
                className="bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-md dark:text-gray-200"
              />
            </div>

            <div className="flex flex-col">
              <label className="text-sm font-medium text-gray-700 dark:text-gray-200 mb-1">
                Sélectionnez la galerie pour le carrousel
              </label>
              <select
                name="carrousel_id"
                value={settings.carrousel_id}
                onChange={handleChange}
                className="input input-bordered w-full dark:bg-gray-700 dark:text-gray-200"
              >
                {galleries.map((gallery) => (
                  <option key={gallery.id} value={gallery.id}>
                    {gallery.titre}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>

        {/* Bouton de soumission */}
        <div className="flex justify-end">
          <Button
            text="Enregistrer"
            type="submit"
            disabled={isLoading || isDemoMode}
            className="btn-primary"
          />
        </div>
      </form>
    </div>
  );
};

export default ParametresClub;
