import React, { useState, useEffect } from "react";
import useScrollToTop from "../../../hooks/useScrollToTop";
import About from "./About";
import Diaporama from "./Diaporama";
import LastNews from "./LastNews";
import LastGallerie from "./LastGallerie";
import Disciplines from "./Disciplines";
import PartenairesCarousel from "./PartenairesCarousel";
import FormContact from "./FormContact";
import Footer from "./Footer";
import { saveVisite } from "../../../services/visitesServices";
import { getSettings } from "../../../services/settingsServices";
import "./Home.css";

function HomePageVitrine() {
  useScrollToTop();

  const [settings, setSettings] = useState(null);
  const [currentImage, setCurrentImage] = useState("");
  const [fade, setFade] = useState(true);

  useEffect(() => {
    // Récupérer les paramètres dynamiques depuis la base de données
    async function fetchSettings() {
      const response = await getSettings();
      setSettings(response[0]); // On récupère la première entrée

      // Dès que les paramètres sont récupérés, changer l'image actuelle
      if (response[0]) {
        setCurrentImage(response[0].header1_photo_url); // Afficher la première image directement
      }
    }

    fetchSettings();
  }, []);

  useEffect(() => {
    if (settings) {
      const images = [settings.header1_photo_url, settings.header2_photo_url];
      let currentIndex = 0;

      const interval = setInterval(() => {
        setFade(false);
        setTimeout(() => {
          currentIndex = (currentIndex + 1) % images.length;
          setCurrentImage(images[currentIndex]);
          setFade(true);
        }, 500); // Transition de 500ms pour le changement d'image
      }, 7000); // Change l'image toutes les 7 secondes

      return () => clearInterval(interval);
    }
  }, [settings]);

  useEffect(() => {
    const today = new Date().toISOString().slice(0, 10); // Obtenir la date au format AAAA-MM-JJ
    const lastVisitDate = localStorage.getItem("lastVisitDate");

    if (lastVisitDate !== today) {
      // Si la dernière visite enregistrée est différente d'aujourd'hui, on enregistre une nouvelle visite
      saveVisite();
      localStorage.setItem("lastVisitDate", today);
    }
  }, []);

  const scrollToAbout = () => {
    const aboutSection = document.getElementById("about");
    if (aboutSection) {
      aboutSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  // Rediriger vers la page inscription /inscription
  const handleDemoClick = () => {
    window.location.href = "/inscription";
  };

  return (
    <div className="flex flex-col min-h-screen"> {/* Suppression de overflow-hidden ici */}
      {/* Dégradé pour améliorer la visibilité de la Navbar */}
      <div className="bg-gradient-to-b from-neutral-800 to-neutral-500 h-[70px] w-full"></div>
      <div className="flex-grow">
        <div className="relative h-screen overflow-hidden"> {/* overflow-hidden uniquement ici */}
          {settings && (
            <>
              <div
                className={`absolute inset-0 transition-opacity transform duration-1000 ease-in-out bg-cover bg-center ${
                  fade ? "opacity-100 scale-105" : "opacity-0 scale-100"
                }`}
                style={{
                  backgroundImage: `url('${currentImage}')`,
                  objectFit: "cover", // S'assure que l'image couvre tout le conteneur sans déborder
                  transition: "opacity 0.5s ease-in-out, transform 0.5s ease-in-out",
                  height: "100%",
                  width: "100%",
                }}
              ></div>
              <div className="absolute inset-0 bg-black opacity-50"></div>
              <div className="relative z-10 flex flex-col items-center justify-center h-full text-center text-white">
                {/* Titre dynamique */}
                <h1
                  className="text-4xl sm:text-5xl lg:text-6xl xl:text-7xl 2xl:text-8xl font-bold mb-4"
                  dangerouslySetInnerHTML={{ __html: settings.header_title }}
                ></h1>

                {/* Sous-titre dynamique */}
                <p className="text-lg mb-8">{settings.header_subtitle}</p>

                <div className="flex space-x-4">
                  <button
                    onClick={scrollToAbout}
                    className="px-8 py-3 bg-blue-600 hover:bg-blue-700 text-white rounded-full transition relative group"
                  >
                    <span className="group-hover:opacity-0 transition-opacity duration-300">
                      En savoir plus
                    </span>
                    <span className="absolute inset-0 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth="2"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M19 9l-7 7-7-7"
                        />
                      </svg>
                    </span>
                  </button>
                  <button
                    onClick={handleDemoClick}
                    className="px-8 py-3 bg-gradient-to-r from-yellow-400 to-orange-600 text-white rounded-full transition relative group"
                  >
                    <span className="group-hover:opacity-0 transition-opacity duration-300">
                      S'inscrire
                    </span>
                    <span className="absolute inset-0 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300 font-passionOne text-2xl font-light">
                      Inscription
                    </span>
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
        {/* Contenu défilant en dessous */}
        <div id="about">
          <About />
        </div>
        <div id="diaporama">
          <Diaporama />
        </div>
        <div id="cours">
          <Disciplines />
        </div>
        <div id="news">
          <LastNews />
        </div>
        <div id="galerie">
          <LastGallerie />
        </div>
        <div id="partenaires">
          <PartenairesCarousel />
        </div>

        <div id="contact">
          <FormContact />
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default HomePageVitrine;
