import React, { useState } from "react";
import Button from "../../../../components/Button/Button";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { toast } from "react-toastify"; // Notifications
import { FaSave, FaTimes } from "react-icons/fa"; // Icônes

const CreateDisciplineModale = ({ onClose, onCreate, refreshData }) => {
  const [nomDiscipline, setNomDiscipline] = useState("");
  const [descriptionDiscipline, setDescriptionDiscipline] = useState("");
  const [contenuDiscipline, setContenuDiscipline] = useState(""); // Texte enrichi
  const [imageDiscipline, setImageDiscipline] = useState("");
  const [ordreDiscipline, setOrdreDiscipline] = useState(0);
  const [isLoading, setIsLoading] = useState(false); // État pour gérer le chargement

  // Gérer la création de la discipline
  const handleCreate = async () => {
    if (isLoading) return; // Éviter les appels multiples si en cours de traitement
    setIsLoading(true); // Activer l'état de chargement

    const newDiscipline = {
      nom_discipline: nomDiscipline,
      description_discipline: descriptionDiscipline,
      contenu_discipline: contenuDiscipline,
      image_discipline: imageDiscipline,
      ordre_discipline: ordreDiscipline,
    };

    try {
      toast.success("Discipline créée avec succès.");
      onCreate(newDiscipline); // Notifier le parent après succès
      refreshData(); // Rafraîchir les données de la liste principale
    } catch (error) {
      console.error("Erreur lors de la création de la discipline :", error);
      toast.error("Erreur lors de la création de la discipline.");
    } finally {
      setIsLoading(false); // Désactiver l'état de chargement
      onClose(); // Fermer la modale après la création
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-30 flex justify-center items-center z-50">
      <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg w-full max-w-2xl">
        {/* Bandeau en haut */}
        <div className="bg-primary dark:bg-gray-900 text-white p-4 rounded-t-lg">
          <h2 className="text-xl font-semibold">Créer une Discipline</h2>
        </div>

        {/* Contenu de la modale */}
        <div className="p-6">
          <div className="mb-4">
            <label className="block font-semibold mb-2 dark:text-gray-200">Nom de la discipline</label>
            <input
              type="text"
              value={nomDiscipline}
              onChange={(e) => setNomDiscipline(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600 dark:text-gray-200"
              required
            />
          </div>

          <div className="mb-4">
            <label className="block font-semibold mb-2 dark:text-gray-200">Description de la discipline</label>
            <input
              type="text"
              value={descriptionDiscipline}
              onChange={(e) => setDescriptionDiscipline(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600 dark:text-gray-200"
              required
            />
          </div>

          <div className="mb-4">
            <label className="block font-semibold mb-2 dark:text-gray-200">Contenu de la discipline</label>
            <ReactQuill
              value={contenuDiscipline}
              onChange={setContenuDiscipline}
              className="h-40 mb-4 dark:bg-gray-700 dark:text-gray-200"
            />
          </div>

          <div className="mb-4">
            <label className="block font-semibold mb-2 mt-12 dark:text-gray-200">URL de l'image</label>
            <input
              type="text"
              value={imageDiscipline}
              onChange={(e) => setImageDiscipline(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600 dark:text-gray-200"
              required
            />
          </div>

          <div className="mb-4">
            <label className="block font-semibold mb-2 dark:text-gray-200">Ordre</label>
            <input
              type="number"
              value={ordreDiscipline}
              onChange={(e) => setOrdreDiscipline(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600 dark:text-gray-200"
              required
            />
          </div>

          {/* Boutons */}
          <div className="flex justify-end gap-2">
            <Button text="Annuler" onClick={onClose} disabled={isLoading} icon={FaTimes} className="gap-2" />
            <Button text="Créer" onClick={handleCreate} disabled={isLoading} icon={FaSave}  className="gap-2"/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateDisciplineModale;
