import axios from "axios";

const apiUrl = `${process.env.REACT_APP_API_URL}/api/visites`;
const token = localStorage.getItem("token");

/// GET
// Fonction pour compter le nombre de visites

export const countVisites = async () => {
  try {
    const response = await axios.get(`${apiUrl}/countVisites`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(
      "Erreur lors de la récupération du nombre de visites:",
      error
    );
  }
};

// Fonction pour compter le nombre de visites par date
export const countVisitesByDate = async () => {
  try {
    const response = await axios.get(`${apiUrl}/countVisitesByDate`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(
      "Erreur lors de la récupération du nombre de visites par date:",
      error
    );
  }
};

// Fonction pour enregistrer une visite
export const saveVisite = async (visite) => {
  try {
    const response = await axios.post(`${apiUrl}/addVisite`, visite, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Erreur lors de l'enregistrement de la visite:", error);
  }
};

// Fonction pour supprimer des visites sur une période donnée dateDebut et dateFin
export const deleteVisitesByDate = async (dateDebut, dateFin) => {
  try {
    const response = await axios.delete(`${apiUrl}/deleteVisites`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: { dateDebut, dateFin },
    });
    return response.data;
  } catch (error) {
    console.error(
      "Erreur lors de la suppression des visites par date:",
      error
    );
  }
};
