import React, { useState, useEffect } from 'react';
import { getAllTeamMembers } from '../../../services/teamServices'; // Assurez-vous que le chemin est correct
import ComponentTitle from '../../../components/ComponentTitle'; // Importez votre composant ComponentTitle

const Team = () => {
  const [bureauMembers, setBureauMembers] = useState([]);
  const [enseignants, setEnseignants] = useState([]);

  useEffect(() => {
    const fetchTeamMembers = async () => {
      try {
        const teamMembers = await getAllTeamMembers(); // Récupérer les membres de l'équipe depuis l'API
        // Séparer les membres du bureau et les enseignants
        const bureau = teamMembers.filter((member) => member.categorie === 'Bureau');
        const enseignants = teamMembers.filter((member) => member.categorie === 'Enseignant');

        setBureauMembers(bureau);
        setEnseignants(enseignants);
      } catch (error) {
        console.error('Failed to fetch team members', error);
      }
    };

    fetchTeamMembers();
  }, []);

  return (
    <div className="container mx-auto px-4 mt-8">
      {/* Utilisation de ComponentTitle avec le mode Dark */}
      <div className="mx-auto max-w-screen-md text-center mb-6 lg:mb-8">
        <ComponentTitle title="Notre" highlight=" Équipe" />
      </div>

      {/* Section du Bureau */}
      <section className="my-6">
        <h3 className="text-2xl md:text-3xl text-gray-800 dark:text-gray-100 mb-4 font-passionOne">
          Le Bureau
        </h3>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          {bureauMembers.map((member) => (
            <div
              key={member.id}
              className="relative flex items-center p-4 shadow-md bg-white dark:bg-gray-800 border-l-[10px] border-blue-500 hover:shadow-xl transition-shadow duration-500 transform hover:scale-102 rounded-md"
            >
              <img
                src={member.photoURL}
                alt={`${member.prenom} ${member.nom}`}
                className="h-32 w-32 object-cover mr-4 transition-transform duration-500 transform hover:scale-105"
              />
              <div className="flex flex-col">
                <h4 className="text-lg font-bold text-gray-900 dark:text-white">{`${member.prenom} ${member.nom}`}</h4>
                <p className="text-sm text-gray-600 dark:text-gray-300">{member.fonction}</p>
              </div>
            </div>
          ))}
        </div>
      </section>

      {/* Section des Enseignants */}
      <section className="my-8">
        <h3 className="text-2xl md:text-3xl text-gray-800 dark:text-gray-100 mb-4 font-passionOne">
          Les Enseignants
        </h3>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          {enseignants.map((member) => (
            <div
              key={member.id}
              className="relative flex items-center p-4 shadow-md bg-white dark:bg-gray-800 border-l-[10px] border-green-500 hover:shadow-xl transition-shadow duration-500 transform hover:scale-102 rounded-md"
            >
              <img
                src={member.photoURL}
                alt={`${member.prenom} ${member.nom}`}
                className="h-32 w-32 object-cover mr-4 transition-transform duration-500 transform hover:scale-105"
              />
              <div className="flex flex-col">
                <h4 className="text-lg font-bold text-gray-900 dark:text-white">{`${member.prenom} ${member.nom}`}</h4>
                <p className="text-sm text-gray-600 dark:text-gray-300">{member.fonction}</p>
              </div>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};

export default Team;
