import React, { useEffect, useState } from "react";
import { getAllTeamMembers, addTeamMember, updateTeamMember, deleteTeamMember } from "../../../../services/teamServices"; // Import des services pour gérer la team
import Button from "../../../../components/Button/Button"; // Composant pour créer un nouveau membre
import ButtonEdit from "../../../../components/Button/ButtonEdit"; // Composant pour éditer
import ButtonDelete from "../../../../components/Button/ButtonDelete"; // Composant pour supprimer
import CreateTeamMemberModal from "./CreateTeamMemberModal"; // Modale de création
import EditTeamMemberModal from "./EditTeamMemberModal"; // Modale d'édition
import { toast } from "react-toastify";
import { FaPlus } from "react-icons/fa";

const TeamClub = () => {
  const [teamMembers, setTeamMembers] = useState([]);
  const [isCreateModalOpen, setCreateModalOpen] = useState(false);
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [currentMember, setCurrentMember] = useState(null);
  const [isDemoMode, setIsDemoMode] = useState(false); // État pour vérifier si l'utilisateur est en mode démo

  // Fonction pour vérifier le role_id dans localStorage
  const checkUserRole = () => {
    const user = JSON.parse(localStorage.getItem("user")); // Récupérer l'utilisateur depuis localStorage
    if (user && user.role_id === 4) {
      setIsDemoMode(true); // Activer le mode démo si role_id est 4
    }
  };

  useEffect(() => {
    fetchTeamMembers();
    checkUserRole(); // Vérifier le rôle de l'utilisateur au chargement du composant
  }, []);

  // Afficher un message si en mode démo
  const handleDemoClick = () => {
    if (isDemoMode) {
      toast.info("Fonction désactivée en mode Démonstration");
      return true; // Indique que l'action est désactivée en mode démo
    }
    return false; // Permet l'action si pas en mode démo
  };

  // Récupérer les membres de la Team depuis l'API
  const fetchTeamMembers = async () => {
    try {
      const data = await getAllTeamMembers();
      setTeamMembers(data);
    } catch (error) {
      console.error("Erreur lors de la récupération des membres de la team :", error);
      toast.error("Erreur lors de la récupération des membres de la team.");
    }
  };

  // Gérer la création d'un membre
  const handleCreate = async (newMember) => {
    if (handleDemoClick()) return; // Bloquer l'action en mode démo
    try {
      await addTeamMember(newMember);
      toast.success("Membre ajouté avec succès !");
      fetchTeamMembers(); // Rafraîchir la liste des membres après création
    } catch (error) {
      console.error("Erreur lors de l'ajout du membre :", error);
      toast.error("Erreur lors de l'ajout du membre.");
    }
  };

  // Gérer l'édition d'un membre
  const handleEdit = (member) => {
    if (handleDemoClick()) return; // Bloquer l'action en mode démo
    setCurrentMember(member);
    setEditModalOpen(true);
  };

  const handleSaveEdit = async (updatedMember) => {
    try {
      await updateTeamMember(updatedMember.id, updatedMember);
      toast.success("Membre mis à jour avec succès !");
      fetchTeamMembers(); // Rafraîchir la liste après modification
    } catch (error) {
      console.error("Erreur lors de la modification du membre :", error);
      toast.error("Erreur lors de la modification du membre.");
    }
  };

  // Fonction pour gérer la suppression d'un membre
  const handleDelete = async (id) => {
    if (handleDemoClick()) return; // Bloquer l'action en mode démo
    if (window.confirm("Êtes-vous sûr de vouloir supprimer ce membre ?")) {
      try {
        await deleteTeamMember(id);
        toast.success("Membre supprimé avec succès.");
        fetchTeamMembers(); // Rafraîchir la liste après suppression
      } catch (error) {
        console.error("Erreur lors de la suppression du membre :", error);
        toast.error("Erreur lors de la suppression du membre.");
      }
    }
  };

  const getCategorieBadgeClass = (categorie) => {
    switch (categorie) {
      case "Bureau":
        return "bg-blue-500 text-white px-2 py-2 rounded-full text-sm"; // Badge bleu pour Bureau
      case "Enseignant":
        return "bg-green-500 text-white px-2 py-2 rounded-full text-sm"; // Badge vert pour Enseignant
      case "Demo":
        return "bg-yellow-500 text-white px-2 py-2 rounded-full text-sm"; // Badge jaune pour Demo
      default:
        return "bg-gray-500 text-white px-2 py-2 rounded-full text-sm"; // Badge gris pour les catégories inconnues ou autres
    }
  };

  return (
    <div className="p-6 bg-white dark:bg-gray-800 dark:text-gray-200 rounded-md shadow-md">
      <h2 className="text-2xl font-semibold mb-6">Gestion de l'Equipe du Club</h2>

      {/* Bouton pour créer un nouveau membre */}
      <Button
        text={(
          <>
            <FaPlus className="mr-2" /> Créer un nouveau membre
          </>
        )}
        onClick={() => {
          if (!handleDemoClick()) setCreateModalOpen(true);
        }} // Bloquer la création en mode démo
        className="mb-4"
      />

      {/* Tableau des membres de la team */}
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-md">
          <thead>
            <tr className="bg-gray-100 dark:bg-gray-800 dark:text-gray-200">
              <th className="px-4 py-2 border dark:border-gray-600">Photo</th>
              <th className="px-4 py-2 border dark:border-gray-600">Nom</th>
              <th className="px-4 py-2 border dark:border-gray-600">Prénom</th>
              <th className="px-4 py-2 border dark:border-gray-600">Fonction</th>
              <th className="px-4 py-2 border dark:border-gray-600">Catégorie</th>
              <th className="px-4 py-2 border dark:border-gray-600">Position</th>
              <th className="px-4 py-2 border dark:border-gray-600">Actions</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 dark:divide-gray-600">
            {teamMembers.map((member) => (
              <tr key={member.id} className="hover:bg-gray-50 dark:hover:bg-gray-600">
                <td className="px-4 py-2 border text-center dark:border-gray-600">
                  <img
                    src={member.photoURL}
                    alt={member.nom}
                    className="h-16 w-16 object-cover rounded"
                  />
                </td>
                <td className="px-4 py-2 border dark:border-gray-600">{member.nom}</td>
                <td className="px-4 py-2 border dark:border-gray-600">{member.prenom}</td>
                <td className="px-4 py-2 border dark:border-gray-600">{member.fonction}</td>
                {/* Affichage du badge pour la catégorie */}
                <td className="px-4 py-2 border text-center dark:border-gray-600">
                  <span className={getCategorieBadgeClass(member.categorie)}>
                    {member.categorie}
                  </span>
                </td>
                <td className="px-4 py-2 border text-center dark:border-gray-600">{member.position}</td>
                <td className="px-4 py-2 border-none dark:text-gray-200">
                  <div className="flex justify-center items-center w-full h-full gap-2">
                    <ButtonEdit onClick={() => handleEdit(member)} /> {/* Bloquer l'édition en mode démo */}
                    <ButtonDelete onClick={() => handleDelete(member.id)} /> {/* Bloquer la suppression en mode démo */}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Modale de création */}
      {isCreateModalOpen && (
        <CreateTeamMemberModal
          onClose={() => setCreateModalOpen(false)}
          onCreate={handleCreate}
        />
      )}

      {/* Modale d'édition */}
      {isEditModalOpen && currentMember && (
        <EditTeamMemberModal
          member={currentMember}
          onClose={() => setEditModalOpen(false)}
          onSave={handleSaveEdit}
        />
      )}
    </div>
  );
};

export default TeamClub;
