import React, { useEffect, useState } from "react";
import { getAllGalleries, deleteGallery, addGallery } from "../../../../services/gallerieServices"; // Import du service pour les albums
import Button from "../../../../components/Button/Button";
import ButtonEdit from "../../../../components/Button/ButtonEdit";
import ButtonDelete from "../../../../components/Button/ButtonDelete";
import CreateAlbumModal from "./CreateAlbumModal"; // Modale de création
import EditAlbumModal from "./EditAlbumModal"; // Modale d'édition
import ManagePhotoModal from "./ManagePhotoModal"; // Modale pour gérer les photos
import { FaPlus, FaImages } from "react-icons/fa"; // Import de l'icône FaPlus pour le bouton
import { ToastContainer, toast } from "react-toastify";

const AlbumsPhotos = () => {
  const [albums, setAlbums] = useState([]);
  const [isCreateModalOpen, setCreateModalOpen] = useState(false);
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [isManagePhotoModalOpen, setManagePhotoModalOpen] = useState(false);
  const [currentAlbum, setCurrentAlbum] = useState(null);
  const [isDemoMode, setIsDemoMode] = useState(false); // État pour vérifier si l'utilisateur est en mode démo

  // Fonction pour vérifier le role_id dans localStorage
  const checkUserRole = () => {
    const user = JSON.parse(localStorage.getItem("user")); // Récupérer l'utilisateur depuis localStorage
    if (user && user.role_id === 4) {
      setIsDemoMode(true); // Activer le mode démo si role_id est 4
    }
  };

  // Afficher un message si en mode démo
  const handleDemoClick = () => {
    if (isDemoMode) {
      toast.info("Fonction désactivée en mode Démonstration");
      return true; // Indique que l'action est désactivée en mode démo
    }
    return false; // Permet l'action si pas en mode démo
  };

  // Récupérer les albums depuis l'API
  const fetchGalleries = async () => {
    try {
      const data = await getAllGalleries();
      setAlbums(data);
    } catch (error) {
      console.error("Erreur lors de la récupération des albums :", error);
      toast.error("Erreur lors de la récupération des albums.");
    }
  };

  useEffect(() => {
    fetchGalleries();
    checkUserRole(); // Vérifier le rôle de l'utilisateur au chargement du composant
  }, []);

  // Fonction pour gérer la création d'un nouvel album
  const handleCreate = async (newAlbum) => {
    if (handleDemoClick()) return; // Bloquer l'action en mode démo
    try {
      await addGallery(newAlbum);
      toast.success("Album créé avec succès.");
      fetchGalleries();
    } catch (error) {
      console.error("Erreur lors de la création de l'album :", error);
      toast.error("Erreur lors de la création de l'album.");
    }
  };

  // Fonction pour gérer l'édition d'un album
  const handleEdit = (album) => {
    if (handleDemoClick()) return; // Bloquer l'action en mode démo
    setCurrentAlbum(album);
    setEditModalOpen(true);
  };

  // Fonction pour gérer la suppression d'un album
  const handleDelete = async (id) => {
    if (handleDemoClick()) return; // Bloquer l'action en mode démo
    if (window.confirm("Êtes-vous sûr de vouloir supprimer cet album ?")) {
      try {
        await deleteGallery(id);
        toast.success("Album supprimé avec succès.");
        fetchGalleries();
      } catch (error) {
        console.error("Erreur lors de la suppression de l'album :", error);
        toast.error("Erreur lors de la suppression de l'album.");
      }
    }
  };

  // Fonction pour gérer la visualisation et l'ajout/suppression des photos
  const handleManagePhotos = (album) => {
    setCurrentAlbum(album);
    setManagePhotoModalOpen(true);
  };

  return (
    <div className="p-6 bg-white dark:bg-gray-800 rounded-md shadow-md dark:text-gray-200">
      <h2 className="text-2xl font-semibold mb-6">Gestion des Albums Photos</h2>

      {/* Bouton pour créer un nouvel album */}
      <Button
        text={(
          <>
            <FaPlus className="mr-2" /> Créer un Album Photo
          </>
        )}
        onClick={() => {
          if (!handleDemoClick()) setCreateModalOpen(true);
        }} // Bloquer la création en mode démo
        className="mb-4"
      />

      {/* Tableau des albums */}
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-md">
          <thead>
            <tr className="bg-gray-100 dark:bg-gray-900">
              <th className="px-4 py-2 border dark:border-gray-600 dark:text-gray-200">Titre</th>
              <th className="px-4 py-2 border dark:border-gray-600 dark:text-gray-200">Description</th>
              <th className="px-4 py-2 border dark:border-gray-600 dark:text-gray-200">Nombre de Photos</th>
              <th className="px-4 py-2 border dark:border-gray-600 dark:text-gray-200">Date de Création</th>
              <th className="px-4 py-2 border dark:border-gray-600 dark:text-gray-200">Statut</th>
              <th className="px-4 py-2 border dark:border-gray-600 dark:text-gray-200">Actions</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 dark:divide-gray-600">
            {albums.map((album) => (
              <tr
                key={album.id}
                className={`${
                  album.is_active ? "bg-white dark:bg-gray-700" : "bg-gray-200 dark:bg-gray-600"
                } hover:bg-gray-50 dark:hover:bg-gray-600`}
              >
                <td className="px-4 py-2 border dark:border-gray-600 dark:text-gray-200">{album.titre}</td>
                <td className="px-4 py-2 border dark:border-gray-600 dark:text-gray-200">{album.description}</td>
                <td className="px-4 py-2 border text-center dark:border-gray-600 dark:text-gray-200">
                  {album.photos.length}
                </td>
                <td className="px-4 py-2 border text-center dark:border-gray-600 dark:text-gray-200">
                  {new Date(album.createdAt).toLocaleDateString()}
                </td>
                <td className="px-4 py-2 border text-center dark:border-gray-600 dark:text-gray-200">
                  {album.is_active ? "Actif" : "Inactif"}
                </td>
                <td className="px-4 py-2 border-none dark:text-gray-200">
                  <div className="flex justify-center items-center w-full h-full gap-2">
                    <ButtonEdit onClick={() => handleEdit(album)} /> {/* Bloquer l'édition en mode démo */}
                    {/* Nouveau bouton pour gérer les photos */}
                    <button
                      className="w-8 h-8 bg-orange-500 text-white px-2 py-1 rounded hover:bg-orange-600"
                      onClick={() => handleManagePhotos(album)}
                    >
                      <FaImages />
                    </button>
                    <ButtonDelete onClick={() => handleDelete(album.id)} /> {/* Bloquer la suppression en mode démo */}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Modale de création d'album */}
      {isCreateModalOpen && (
        <CreateAlbumModal
          onClose={() => setCreateModalOpen(false)}
          onCreate={handleCreate}
        />
      )}

      {/* Modale d'édition d'album */}
      {isEditModalOpen && currentAlbum && (
        <EditAlbumModal
          album={currentAlbum}
          onClose={() => setEditModalOpen(false)}
          onSave={() => fetchGalleries()}
        />
      )}

      {/* Modale de gestion des photos */}
      {isManagePhotoModalOpen && (
        <ManagePhotoModal
          album={currentAlbum}
          onClose={() => {
            setManagePhotoModalOpen(false);
            fetchGalleries(); // Rafraîchir les données après fermeture de la modale
          }}
        />
      )}

      <ToastContainer />
    </div>
  );
};

export default AlbumsPhotos;
