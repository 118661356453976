import React, { useState } from "react";
import Button from "../../../../components/Button/Button";
import { FaTimes, FaSave } from "react-icons/fa"; // Icônes pour annuler et sauvegarder
import { toast } from "react-toastify";

const CreateAlbumModal = ({ onClose, onCreate }) => {
  const [titre, setTitre] = useState("");
  const [description, setDescription] = useState("");
  const [isActive, setIsActive] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  // Gérer la création de l'album
  const handleCreate = async () => {
    setIsLoading(true);
    const newAlbum = {
      titre,
      description,
      is_active: isActive,
    };

    try {
      await onCreate(newAlbum);
      toast.success("Album créé avec succès.");
    } catch (error) {
      console.error("Erreur lors de la création de l'album :", error);
      toast.error("Erreur lors de la création de l'album.");
    } finally {
      setIsLoading(false);
      onClose();
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-30 flex justify-center items-center z-50">
      <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg w-full max-w-2xl">
        {/* Bandeau en haut */}
        <div className="bg-primary text-white p-4 rounded-t-lg">
          <h2 className="text-xl font-semibold">Créer un Album</h2>
        </div>

        <div className="p-6">
          <div className="mb-4">
            <label className="block font-semibold mb-2 dark:text-gray-200">Titre de l'album</label>
            <input
              type="text"
              value={titre}
              onChange={(e) => setTitre(e.target.value)}
              className="w-full p-2 border border-gray-300 dark:border-gray-600 rounded-md dark:bg-gray-700 dark:text-gray-200"
              placeholder="Entrez le titre de l'album"
            />
          </div>

          <div className="mb-4">
            <label className="block font-semibold mb-2 dark:text-gray-200">Description</label>
            <textarea
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              className="w-full p-2 border border-gray-300 dark:border-gray-600 rounded-md dark:bg-gray-700 dark:text-gray-200"
              placeholder="Entrez une description pour l'album"
            />
          </div>

          <div className="mb-4 flex items-center">
            <input
              type="checkbox"
              checked={isActive}
              onChange={(e) => setIsActive(e.target.checked)}
              className="mr-2 dark:bg-gray-700 dark:border-gray-600"
            />
            <label className="dark:text-gray-200">Activer l'album</label>
          </div>

          <div className="flex justify-end gap-2">
            <Button text="Annuler" onClick={onClose} icon={FaTimes} className="gap-2"/>
            <Button text="Créer" onClick={handleCreate} icon={FaSave}  className="gap-2" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateAlbumModal;
