import React, { useState, useEffect } from "react";
import { FaPhone, FaEnvelope } from "react-icons/fa"; // Import des icônes pour les boutons
import { getSettings } from "../../../services/settingsServices"; // Assurez-vous que le chemin est correct
import ComponentTitle from "../../../components/ComponentTitle"; // Importation du composant ComponentTitle

const DojoContacts = () => {
  const [settings, setSettings] = useState({
    dojoPhotoUrl: "",
    adresse: "",
    responsable: "",
    tel: "",
    mail: "",
    nom_club: "",
  });
  const [mapUrl, setMapUrl] = useState("");

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const data = await getSettings();
        if (data.length > 0) {
          const clubSettings = data[0];
          setSettings({
            dojoPhotoUrl: clubSettings.dojo_photo_url,
            adresse: clubSettings.adresse_club,
            responsable: clubSettings.responsable_club,
            tel: clubSettings.tel_club,
            mail: clubSettings.mail_club,
            nom_club: clubSettings.nom_club,
          });
        }
      } catch (error) {
        console.error("Erreur lors de la récupération des paramètres du club:", error);
      }
    };

    fetchSettings();
  }, []);

  useEffect(() => {
    const encodedAddress = encodeURIComponent(settings.adresse);
    const newMapUrl = `https://www.google.com/maps?q=${encodedAddress}&output=embed`;
    setMapUrl(newMapUrl);
  }, [settings.adresse]);

  return (
    <div className="container mx-auto px-4 pt-8">
      {/* Utilisation de ComponentTitle avec le mode Dark */}
      <div className="mx-auto max-w-screen-md text-center mb-6 lg:mb-8">
        <ComponentTitle title="Dojo et" highlight=" Contacts" />
      </div>

      <div className="flex flex-col md:flex-row items-center mt-8 bg-white dark:bg-gray-800 shadow-md rounded-lg overflow-hidden border-l-[10px] border-violet-500 mb-8">
        <iframe
          title="Location Map"
          src={mapUrl}
          className="w-full md:w-1/2 h-64 md:h-96"
          style={{ border: 0 }}
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
        <div className="p-6 flex flex-col justify-center">
          <h3 className="text-2xl md:text-3xl text-gray-800 dark:text-gray-100 mb-4 font-passionOne">
            {settings.nom_club}
          </h3>
          <p className="text-gray-600 dark:text-gray-300 mb-2">
            <strong>Adresse :</strong> {settings.adresse}
          </p>
          <p className="text-gray-600 dark:text-gray-300 mb-2">
            <strong>Responsable :</strong> {settings.responsable}
          </p>
          <p className="text-gray-600 dark:text-gray-300 mb-2">
            <strong>Téléphone :</strong> {settings.tel}
          </p>
          <p className="text-gray-600 dark:text-gray-300 mb-4">
            <strong>Mail :</strong>{" "}
            <a href={`mailto:${settings.mail}`} className="text-blue-500 hover:underline">
              {settings.mail}
            </a>
          </p>
          {/* Boutons d'action pour appeler et envoyer un e-mail */}
          <div className="flex space-x-4">
            <a
              href={`tel:${settings.tel}`}
              className="flex items-center bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition duration-300"
            >
              <FaPhone className="mr-2" />
              Appeler
            </a>
            <a
              href={`mailto:${settings.mail}`}
              className="flex items-center bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 transition duration-300"
            >
              <FaEnvelope className="mr-2" />
              Envoyer un Mail
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DojoContacts;
