import React, { useState, useEffect } from "react";
import { getGroupsHome } from "../../../services/groupServices";
import useScrollToTop from "../../../hooks/useScrollToTop";
import ComponentTitle from "../../../components/ComponentTitle"; // Importation de ComponentTitle


const WeeklySchedule = () => {
  useScrollToTop();
  const [groups, setGroups] = useState([]);
  const [schedule, setSchedule] = useState({});

  useEffect(() => {
    const fetchGroups = async () => {
      try {
        const groupsData = await getGroupsHome(); // Récupérer les groupes depuis l'API
        console.log(groupsData);

        setGroups(groupsData);
        groupSchedules(groupsData);
      } catch (error) {
        console.error("Erreur lors de la récupération des groupes", error);
      }
    };

    fetchGroups();
  }, []);

  // Fonction pour extraire les jours et heures des horaires et les regrouper par jour
  const groupSchedules = (groupsData) => {
    const weekDays = [
      "Lundi",
      "Mardi",
      "Mercredi",
      "Jeudi",
      "Vendredi",
      "Samedi",
      "Dimanche",
    ];
    const scheduleData = {};

    // Fonction pour ajouter les horaires à un jour spécifique
    const addSchedule = (day, time, group) => {
      if (!scheduleData[day]) {
        scheduleData[day] = [];
      }
      scheduleData[day].push({
        time,
        groupName: group.nom,
        color: `${group.color_groupe || "bg-orange-400"}`, // Ajout du fond de couleur en fonction de color_groupe
        borderColor: `${group.color_groupe?.replace("bg-", "border-") || "border-orange-400"}`, // Ajout de la classe de bordure
      });
    };

    groupsData.forEach((group) => {
      // Analyse horaire1
      if (group.horaire1) {
        const [day, time] = group.horaire1.split(" ");
        if (weekDays.includes(day)) {
          addSchedule(day, time, group);
        }
      }

      // Analyse horaire2
      if (group.horaire2) {
        const [day, time] = group.horaire2.split(" ");
        if (weekDays.includes(day)) {
          addSchedule(day, time, group);
        }
      }
    });

    setSchedule(scheduleData);
  };

  // Ordre des jours de la semaine pour afficher correctement du lundi au dimanche
  const orderedDays = [
    "Lundi",
    "Mardi",
    "Mercredi",
    "Jeudi",
    "Vendredi",
    "Samedi",
    "Dimanche",
  ];

  return (
    <div className="container mx-auto px-4 mt-8 mb-4">
      {/* Utilisation de ComponentTitle avec le mode Dark */}
      <div className="mx-auto max-w-screen-md text-center mb-6 lg:mb-8">
        <ComponentTitle title="Planning" highlight=" des Cours" />
      </div>

      {/* Affichage du planning des cours */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {orderedDays
          .filter((day) => schedule[day] && schedule[day].length > 0) // Filtrer les jours sans cours
          .map((day) => (
            <div
              key={day}
              className="bg-white dark:bg-gray-800 p-4 rounded-md shadow-md"
            >
              <h3 className="text-xl font-bold text-blue-600 dark:text-blue-300">
                {day}
              </h3>
              <ul className="mt-2">
                {schedule[day].map((course, index) => (
                  <li
                    key={index}
                    className={`p-2 mb-2 rounded text-white ${course.color} ${course.borderColor} border-l-[10px]`}
                  >
                    <p className="font-semibold">{course.groupName}</p>
                    <p className="text-xs">{course.time}</p>
                  </li>
                ))}
              </ul>
            </div>
          ))}
      </div>
    </div>
  );
};

export default WeeklySchedule;
