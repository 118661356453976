import axios from "axios";

const apiUrl = `${process.env.REACT_APP_API_URL}/api/partenaires`;
const token = localStorage.getItem("token");

/// GET
// Fonction pour récupérer tous les partenaires
export const getAllPartenaires = async () => {
  try {
    const response = await axios.get(`${apiUrl}/allPartenaires`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status !== 200) throw new Error("Failed to fetch data");
    return response.data; // Renvoie directement les données
  } catch (error) {
    console.error("Erreur lors du chargement des partenaires:", error);
    return []; // Renvoie un tableau vide en cas d'échec pour maintenir la cohérence du type de retour
  }
};

/// POST
// Fonction pour ajouter un partenaire
export const addPartenaire = async (newPartenaire) => {
  try {
    const response = await axios.post(
      `${apiUrl}/addPartenaire`,
      newPartenaire,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response.status !== 201)
      throw new Error("Failed to add new partenaire");
    return response.data; // Renvoie directement les données
  } catch (error) {
    console.error("Erreur lors de l'ajout d'un partenaire:", error);
    return null; // Renvoie null en cas d'échec pour maintenir la cohérence du type de retour
  }
};

/// PUT
// Fonction pour modifier un partenaire avec son id
export const updatePartenaire = async (partenaire) => {
  try {
    const response = await axios.put(
      `${apiUrl}/updatePartenaire/${partenaire.id}`,
      partenaire,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response.status !== 200) throw new Error("Failed to update partenaire");
    return response.data; // Renvoie directement les données
  } catch (error) {
    console.error("Erreur lors de la modification d'un partenaire:", error);
    return null; // Renvoie null en cas d'échec pour maintenir la cohérence du type de retour
  }
};

// Fonction pour modifier l'ordre des partenaires
export const updatePartenaireOrder = async (partenaires) => {
  // Notez le paramètre modifié
  try {
    const response = await axios.put(
      `${apiUrl}/updatePartenaireOrder`,
      { partenaires }, // Envoyer comme un objet
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response.status !== 200)
      throw new Error("Failed to update partenaires order");
    return response.data; // Renvoie directement les données
  } catch (error) {
    console.error(
      "Erreur lors de la modification de l'ordre des partenaires:",
      error
    );
    return null; // Renvoie null en cas d'échec pour maintenir la cohérence du type de retour
  }
};

// Fonction pour modifier l'acivation d'un partenaire
export const updatePartenaireActivation = async (id, updatePartenaire) => {
  try {
    const response = await axios.put(
      `${apiUrl}/updatePartenaireActive/${id}`,
      updatePartenaire,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response.status !== 200)
      throw new Error("Failed to update partenaires activation");
    return response.data; // Renvoie directement les données
  } catch (error) {
    console.error(
      "Erreur lors de la modification de l'activation des partenaires:",
      error
    );
    return null; // Renvoie null en cas d'échec pour maintenir la cohérence du type de retour
  }
};

/// PATCH
// Fonction pour mettre à jour le logo d'un partenaire
export const updatePartenairePhoto = async (partenaireId, file, token) => {
  const formData = new FormData();
  formData.append("image", file);

  try {
    const response = await axios.patch(
      `${apiUrl}/${partenaireId}/photo`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response.status !== 200) throw new Error("Failed to update data");
    return response.data;
  } catch (error) {
    console.error(
      "Erreur lors de la mise à jour du logo du partenaire:",
      error
    );
    return "";
  }
};

/// DELETE
// Fonction pour supprimer un partenaire avec son id
export const deletePartenaire = async (id) => {
  try {
    const response = await axios.delete(`${apiUrl}/deletePartenaire/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status !== 200) throw new Error("Failed to delete partenaire");
    return response.data; // Renvoie directement les données
  } catch (error) {
    console.error("Erreur lors de la suppression d'un partenaire:", error);
    return null; // Renvoie null en cas d'échec pour maintenir la cohérence du type de retour
  }
};
