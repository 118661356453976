import React, { useState, useEffect } from "react";
import useScrollToTop from "../../../hooks/useScrollToTop";
import Footer from "./../HomePage/Footer";
import Team from "./Team"; // Importer le composant Team
import ClubHistory from "./ClubHistory"; // Importer le composant ClubHistory
import DojoContacts from "./DojoContacts"; // Importer le composant DojoContacts
import "./../HomePage/Home.css";

function ClubPage() {
  useScrollToTop();
  return (
    <div className="flex flex-col min-h-screen">
      {/* Dégradé pour améliorer la visibilité de la Navbar */}
      <div className="bg-gradient-to-b from-neutral-800 to-neutral-500 h-[70px] w-full"></div>

      <div className="flex-grow relative z-10">
        {/* Intégration du composant Team */}
        <Team />

        {/* Intégration du composant Histoire du Club */}
        <ClubHistory />

        {/* Intégration du composant Dojo et Contacts */}
        <DojoContacts />

        <div>
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default ClubPage;
