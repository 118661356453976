import React, { useState } from "react";
import Button from "../../../../components/Button/Button";
import { updatePartenaire } from "../../../../services/partenairesServices"; // Importer updatePartenaire
import { toast } from "react-toastify";
import { FaSave, FaTimes } from "react-icons/fa"; // Icônes

const EditPartenairesModale = ({ partenaire, onClose, onSave }) => {
  const [nom, setNom] = useState(partenaire.nom);
  const [photoUrl, setPhotoUrl] = useState(partenaire.photo_url);
  const [linkUrl, setLinkUrl] = useState(partenaire.link_url);
  const [ordre, setOrdre] = useState(partenaire.ordre);
  const [isActive, setIsActive] = useState(partenaire.is_active);
  const [isLoading, setIsLoading] = useState(false); // État pour gérer le chargement

  // Fonction pour sauvegarder les modifications
  const handleSave = async () => {
    setIsLoading(true); // Activer l'état de chargement

    const updatedPartenaire = {
      id: partenaire.id, // Assurez-vous que l'ID est bien inclus pour identifier le partenaire
      nom,
      photo_url: photoUrl,
      link_url: linkUrl,
      ordre: parseInt(ordre, 10), // S'assurer que l'ordre est bien un nombre
      is_active: isActive,
    };

    try {
      console.log("Partenaire à mettre à jour :", updatedPartenaire); // Debugging log
      // Appel à la fonction pour mettre à jour le partenaire
      await updatePartenaire(updatedPartenaire);
      onSave(updatedPartenaire); // Appeler la fonction de sauvegarde après succès
      toast.success("Partenaire modifié avec succès.");
    } catch (error) {
      console.error("Erreur lors de la mise à jour du partenaire :", error);
      toast.error("Erreur lors de la mise à jour du partenaire.");
    } finally {
      setIsLoading(false); // Désactiver l'état de chargement
      onClose(); // Fermer la modale
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg w-full max-w-2xl">
        {/* Bandeau en haut */}
        <div className="bg-primary text-white p-4 rounded-t-lg">
          <h2 className="text-xl font-semibold dark:text-gray-100">Modifier un Partenaire</h2>
        </div>

        {/* Contenu de la modale */}
        <div className="p-6">
          <div className="mb-4">
            <label className="block font-semibold mb-2 dark:text-gray-200">Nom</label>
            <input
              type="text"
              value={nom}
              onChange={(e) => setNom(e.target.value)}
              className="w-full p-2 border border-gray-300 dark:border-gray-600 rounded-md dark:bg-gray-700 dark:text-gray-200"
            />
          </div>
          <div className="mb-4">
            <label className="block font-semibold mb-2 dark:text-gray-200">URL de l'image</label>
            <input
              type="text"
              value={photoUrl}
              onChange={(e) => setPhotoUrl(e.target.value)}
              className="w-full p-2 border border-gray-300 dark:border-gray-600 rounded-md dark:bg-gray-700 dark:text-gray-200"
            />
          </div>
          <div className="mb-4">
            <label className="block font-semibold mb-2 dark:text-gray-200">Lien</label>
            <input
              type="text"
              value={linkUrl}
              onChange={(e) => setLinkUrl(e.target.value)}
              className="w-full p-2 border border-gray-300 dark:border-gray-600 rounded-md dark:bg-gray-700 dark:text-gray-200"
            />
          </div>
          <div className="mb-4">
            <label className="block font-semibold mb-2 dark:text-gray-200">Ordre</label>
            <input
              type="number"
              value={ordre}
              onChange={(e) => setOrdre(e.target.value)}
              className="w-full p-2 border border-gray-300 dark:border-gray-600 rounded-md dark:bg-gray-700 dark:text-gray-200"
            />
          </div>
          <div className="mb-4 flex items-center dark:text-gray-200">
            <input
              type="checkbox"
              checked={isActive}
              onChange={(e) => setIsActive(e.target.checked)}
              className="mr-2"
            />
            <label>Activer le partenaire</label>
          </div>
          <div className="flex justify-end gap-2">
            <Button text="Annuler" onClick={onClose} disabled={isLoading} icon={FaTimes} className="gap-2" />
            <Button text="Modifier" onClick={handleSave} disabled={isLoading} icon={FaSave}  className="gap-2" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditPartenairesModale;
