import React, { useState, useEffect } from "react";
import Button from "../../../../components/Button/Button";
import { FaTimes, FaSave } from "react-icons/fa"; // Icônes pour annuler et sauvegarder
import { updateGallery } from "../../../../services/gallerieServices"; // Importer updateGallery
import { toast } from "react-toastify";

const EditAlbumModal = ({ album, onClose, onSave }) => {
  const [titre, setTitre] = useState(album.titre || "");
  const [description, setDescription] = useState(album.description || "");
  const [isActive, setIsActive] = useState(album.is_active || false);
  const [isLoading, setIsLoading] = useState(false);

  // Utiliser useEffect pour s'assurer que l'état isActive est mis à jour avec les bonnes données lors de l'ouverture de la modale
  useEffect(() => {
    setIsActive(album.is_active || false);
  }, [album]);

  // Gérer la sauvegarde des modifications
  const handleSave = async () => {
    setIsLoading(true);
    const updatedAlbum = {
      ...album,
      titre,
      description,
      is_active: isActive,
    };

    try {
      await updateGallery(updatedAlbum); // Appel à updateGallery pour mettre à jour l'album
      toast.success("Album modifié avec succès.");
      onSave(); // Rafraîchir la liste des albums après la sauvegarde
    } catch (error) {
      console.error("Erreur lors de la modification de l'album :", error);
      toast.error("Erreur lors de la modification de l'album.");
    } finally {
      setIsLoading(false);
      onClose(); // Fermer la modale après la sauvegarde
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-30 flex justify-center items-center z-50">
      <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg w-full max-w-2xl">
        {/* Bandeau en haut */}
        <div className="bg-primary text-white p-4 rounded-t-lg">
          <h2 className="text-xl font-semibold">Modifier l'Album</h2>
        </div>

        <div className="p-6">
          <div className="mb-4">
            <label className="block font-semibold mb-2 dark:text-gray-200">Titre de l'album</label>
            <input
              type="text"
              value={titre}
              onChange={(e) => setTitre(e.target.value)}
              className="w-full p-2 border border-gray-300 dark:border-gray-600 rounded-md dark:bg-gray-700 dark:text-gray-200"
              placeholder="Entrez le titre de l'album"
            />
          </div>

          <div className="mb-4">
            <label className="block font-semibold mb-2 dark:text-gray-200">Description</label>
            <textarea
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              className="w-full p-2 border border-gray-300 dark:border-gray-600 rounded-md dark:bg-gray-700 dark:text-gray-200"
              placeholder="Entrez une description pour l'album"
            />
          </div>

          <div className="mb-4 flex items-center">
            <input
              type="checkbox"
              checked={isActive}
              onChange={(e) => setIsActive(e.target.checked)} // Mettre à jour l'état lorsque l'utilisateur interagit avec la case à cocher
              className="mr-2 dark:bg-gray-700 dark:border-gray-600"
            />
            <label className="dark:text-gray-200">Activer l'album</label>
          </div>

          <div className="flex justify-end gap-2">
            <Button text="Annuler" onClick={onClose} icon={FaTimes} className="gap-2"/>
            <Button text="Modifier" onClick={handleSave} disabled={isLoading} icon={FaSave} className="gap-2"/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditAlbumModal;
